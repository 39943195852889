:root {
  --color-NO: #ffffff00;
  --color-red: #ff0000;
  --color-red1: #ff6666;
  --color-red2: #b14848;
  --color-red3: #ff4f38;
  --color-red4: #ca4343;
  --color-red5: #e8414217;
  --color-red6: #ce4a4a;
  --color-red7: #f26672;
  --color-green: #22c58b;
  --color-green1: #1a9469;
  --color-green2: #0fb67b;
  --color-green3: #58bd7d;
  --color-green4: #22c58b14;
  --color-green5: #88ff001a;
  --color-green6: #10dd95;
  --color-green7: #179468;
  --color-green8: #22c58b17;
  --color-green9: #1ca072;
  --color-green10: #1a976b;
  --color-green11: #22c58b2b;
  --color-green12: #08a56d;
  --color-green13: #22c58b12;
  --color-green14: #22c58b26;
  --color-white: #fff;
  --color-white1: #ffeeea;
  --color-white2: #fffcfb;
  --color-white3: #e7ecf4;
  --color-white4: #f0f5ff;
  --color-white5: #f7f9fb;
  --color-white6: #d7dde3;
  --color-white7: #eee;
  --color-white8: #e9ecef;
  --color-white9: #edfbf6;
  --color-white10: #d8fff1;
  --color-white11: #f9fbfd;
  --color-white12: #f4f7fc;
  --color-white13: #f5f7fa;
  --color-white14: #c8d0dd;
  --color-white15: #ebebeb;
  --color-white16: #f5f8fa;
  --color-white17: #f9f9f9;
  --color-white18: #dedede;
  --color-white19: #e7e7e7;
  --color-white20: #f7f7f7;
  --color-white21: #d9d9d9;

  --color-white23: #e6e8ec;
  --color-white24: #f1f2f4;
  --color-white25: #e9f1f5;
  --color-white26: #f1f1f1;
  --color-white27: #ffffffc2;
  --color-white28: #f4f4fd;
  --color-white29: #e8f9f3;
  --color-white30: #f1f3f6;
  --color-white31: #d6d6d6;
  --color-white32: #ffffff33;
  --color-white33: #dadada;
  --color-white34: #e5e5e5;
  --color-white35: #d3d3d3;
  --color-white36: #dddddd;
  --color-white37: #d8d8d8;
  --color-white38: #fff3dc;
  --color-white39: #e1e1e1;
  --color-white40: #ececec;
  --color-white41: #f4f4f4;
  --color-white42: #ffffff4d;
  --color-black: #000;
  --color-black1: #212529;
  --color-black2: #292d3280;
  --color-black3: #333;
  --color-black4: #0000001a;
  --color-black5: #292d32;
  --color-black6: #0000000a;
  --color-black7: #252129;
  --color-black8: #0000003f;
  --color-black9: #00000024;
  --color-black10: #25212980;
  --color-black11: #00000027;
  --color-black12: #23262f;
  --color-black13: #0000001f;
  --color-black14: #00000059;
  --color-black15: #141416;
  --color-black15: #0f0f0f33;
  --color-gray: #7c8c9f;
  --color-gray1: #e7ecf496;
  --color-gray2: #f7ece983;
  --color-gray3: #3c4a5b;
  --color-gray4: #7b8ea7;
  --color-gray5: #697d97;
  --color-gray7: #6c757d;

  --color-gray10: #c7c7c7;
  --color-gray11: #adb6c8;
  --color-gray12: #777e90;
  --color-gray13: #888;
  --color-gray14: #555;
  --color-gray15: #aab1cb;
  --color-gray16: #5b5b5e;
  --color-gray17: #b5b5b5;
  --color-gray18: #696969;
  --color-gray19: #959ca3;
  --color-gray20: #7c7c7c;
  --color-gray21: #3c4a5b2e;
  --color-gray22: #6b6b6b;
  --color-gray23: #51535659;
  --color-gray24: #51535612;
  --color-blue: #2424da;
  --color-blue1: #1515a5;
  --color-blue2: #07142c;
  --color-blue3: #1b1ba7;
  --color-blue4: #0d0d7d2e;
  --color-blue5: #2424da54;
  --color-blue6: #0d0d7d0f;
  --color-blue7: #2424da14;
  --color-blue8: #5c5cdd;
  --color-blue9: #17178f;
  --color-blue10: #0d0d7d11;
  --color-blue11: #0d0d7d27;
  --color-blue12: #5151ff;
  --color-blue13: #2424da0d;
  --color-blue14: #0000a5;
  --color-blue15: #2727fc;
  --color-blue16: #2424da2f;
  --color-blue17: #2424da17;
  --color-blue18: #3159e8;
  --color-yellow: #dbdf00;
  --color-yellow1: #ffb533;
  --color-yellow2: #916a00;
  --color-yellow3: #d0ff001a;
  --color-yellow4: #ffd166;
  --color-yellow5: #ffb13b;
  --color-yellow6: #e5ff001a;
  --color-yellow7: #e8bc27;
  --color-orange: #ff0000;
  --color-orange1: #ff5e33;
  --color-orange2: #ee9700;
  --color-orange3: #ffa60023;
  --color-orange4: #ffcfc2;
  --color-orange5: #fff8ea;
  --color-orange6: #fe3905b3;
  --color-orange7: #ff5e3325;
  --color-orange8: #ff91001a;
  --color-orange9: #ee97001a;
  --color-orange10: #ff6838;
  --color-orange11: #704f00;
  /* --tx1-color: #ff0000;
  --tx2-color: #ff0000;
  --tx3-color: #ff0000;
  --tx4-color: #ff0000;
  --tx5-color: #ff0000;
  --bg1-color: #ff0000;
  --bg2-color: #ff0000;
  --bg3-color: #ff0000;
  --bg4-color: #ff0000;
  --border-color: #ff0000;
  --border1-color: #ff0000;
  --border2-color: #ff0000;
  --border3-color: #ff0000;
  --border4-color: #ff0000; */
  --bgBody-color: #f8faff;
  --txBody-color: #212529;
  --color-white: #fff;
  --color-whiteOld: #fff;
  --color-black5: #292d32;
  --color-black2: #292d3280;
  --cBox-coin: #f9f9f9;
  --cBoxActive-coin: #2424da;
  --color-newBlue: #2424da;
  --color-textCoin: #adb6c8;
  --color-textOnlyWhite: #fff;
  --color-divider: #e7ecf4;
  --color-title: #2424da;
  --color-title2: #2424da;
  --color-bgNo: #ffffff00;
  --color-text: #252129;
  --color-text1: #25212980;
  --color-text2: #696969;
  --color-text6: #616173;
  --color-text7: #808A97;
  --color-br1: #dedede;
  --color-br4: #D6DCE7;
  --color-bgDone: #22c58b17;
  --color-textDone: #22c58b;
  --color-bg1: #f7f7f7;
  --color-bg2: #e9e9e9;
  --color-actvieNav: #2424da0d;
  --color-black: #000;
  --color-br2: #e7ecf4;
  --color-br3: #dedede;
  --color-br5: #e1e1e1;
  --color-divider2: #e5e5e5;
  --color-hoverNav: #e7ecf4;
  --color-bghoverNav: #e7ecf496;
  --color-divider3: #d6d6d6;
  --color-activeNotif: #f7ece983;
  --color-hoverSubMenu: #f4f4fd;
  --color-Boxblue1: #2424da14;
  --color-txtBlue1: #5c5cdd;
  --color-txtblue2: #07142c;
  --color-boxPending: #f1f3f6;
  --color-sliderBr: #cecece;
  --color-text3: #6d6d6d;
  --color-textSlider: #555;
  --color-txt4: #777e90;
  --color-bg3: #f5f8fa;
  --color-bg4: #e7e7e7c5;
  --color-grayLine: #e7e7e7;
  --color-grayLine2: #e6e6e6;
  --color-graylogo: #b5b5b5;
  --color-bg5: #2424da0a;
  --color-text4: #6b6b6bda;
  --color-shadow404: #ff9b9b;
  --color-bg6: #eee;
  --color-bg7: #98A3BB;
  --color-brInput: #a9a9a9;
  --color-bgPlus: #eaeaea;
  --color-bgQuestion: #f6f6f6;
  --color-bgQuestion2: #3159e80d;
  --color-txtwarning: #af5400;
  --color-brwarning: #af540052;
  --color-bgwarning: #fff8ea;
  --color-bgReadAll: #ffe5e5;
  --color-bgReadAllHover: #ffdcdc;
  --color-text5: #21252980;
  --color-divider4: #e6e8ec;
  --color-bghoverOrderBook: #3e454d;
  --color-divider5: #555e69;
  --color-Skeleton: #00000015;
  --color-shadow: #0000000a;
  --color-blackLight: #292d32ad;
  --color-arrowOrderBook: #8c8c8c;
  --color-bgOffline: #f44336;
  --color-offline: #fff;
  --color-shadowOffline: #0000001a;
  --color-shadow2: #333639;
  --shadow16: 0 4px 8px #00000033;
  --color-blueDark: #03045E;
  --color-txt5: #5C5C65;
  --color-bgCir1: #eff2fa80;
  --color-bgCir2: #EFF2FA;
  --color-div1: #DEE7F3;
  --color-hoverNav2: #e7ecf4;
  --color-gray8: #314351;
  --color-white22: #e8e8fb;
  --color-br6: #00000021;
  --color-greenLight: #22c58b29;
  --color-redLight: #ff767629;

  /* /////////dialog Updat */
  --dialog-bg: #ffffff;
  --dialog-text: #000000;
  --dialog-close: #888888;
  --button-bg: #007BFF;
  --button-text: #ffffff;

  /* ////////////////Notif */
  --bg: #ffffff;
  --text: #333;
  --box-bg: #fafafa;
  --shadow: #00000030;
  --btn-bg: #007bff;
  --btn-text: #ffffff;
  --btn-hover: #0056b3;
}

[data-theme="dark"] {
  --bgBody-color: #0D1117;
  --txBody-color: #fff;
  --color-white: #161B27;
  --color-whiteOld: #1e2025;
  --color-black5: #fff;
  --color-black2: #a3a5a8;
  --color-shadow: #ffffff0a;
  --cBox-coin: #202736;
  --cBoxActive-coin: #3159e8;
  --color-newBlue: #007bff;
  --color-textCoin: #a3a5a8;
  --color-textOnlyWhite: #fff;
  --color-divider: #000;
  --color-title: #fff;
  --color-title2: #4c5bff;
  --color-bgNo: #1e2025;
  --color-text: #e4e6eb;
  --color-text1: #a3a5a8;
  --color-text2: #fff;
  --color-text6: #c0c0d1;
  --color-text7: #b7b7c1;
  --color-br1: #dedede;
  --color-br4: #9a9da3;
  --color-br5: #323232;
  --color-bgDone: #08a56d;
  --color-textDone: #fff;
  --color-bg1: #242b38;
  --color-bg2: #4d5057;
  --color-actvieNav: #3159e8;
  --color-black: #fff;
  --color-br2: #303030;
  --color-br3: #808080;
  --color-divider2: #292b2e;
  --color-hoverNav: #202736;
  --color-bghoverNav: #3d3d3d96;
  --color-divider3: #292b2e;
  --color-activeNotif: #5c5c5c83;
  --color-hoverSubMenu: #464646;
  --color-Boxblue1: #2424da;
  --color-txtBlue1: #e4e4e4;
  --color-txtblue2: #dadada;
  --color-boxPending: #383838;
  --color-sliderBr: #fff;
  --color-text3: #b6b6b6;
  --color-textSlider: #d8d8d8;
  --color-txt4: #a0a7b9;
  --color-bg3: #202736;
  --color-bg4: #202736c5;
  --color-grayLine: #3d3d3d;
  --color-grayLine2: #2d2d2d;
  --color-graylogo: #b5b5b5;
  --color-bg5: #ffffff0a;
  --color-text4: #c4c4c4da;
  --color-shadow404: #c9c9c9;
  --color-bg6: #23262b;
  --color-bg7: #5f6573;
  --color-brInput: #7c7c7c;
  --color-bgPlus: #eaeaea;
  --color-bgQuestion: #21242d;
  --color-bgQuestion2: #e7e7e70d;
  --color-txtwarning: #ffe1be;
  --color-brwarning: #ffe1be52;
  --color-bgwarning: #1e2025;
  --color-bgReadAll: #9f9f9f;
  --color-bgReadAllHover: #858585;
  --color-text5: #ffffffb7;
  --color-divider4: #2c323e;
  --color-bghoverOrderBook: #dbe1e7;
  --color-divider5: #c0c5ca;
  --color-Skeleton: #ffffff15;
  --color-blackLight: #ffffffd6;
  --color-arrowOrderBook: #b6b6b6;
  --color-bgOffline: #333;
  --color-offline: #f44336;
  --color-shadowOffline: #00000066;
  --color-orange11: #ffb400;
  --color-shadow2: #5b5b5b;
  --shadow16: 0 4px 8px #ffffff33;
  --color-blue: #0064ff;
  --color-blueDark: #ffffff;
  --color-txt5: #d8d8d8;
  --color-bgCir1: #21274280;
  --color-bgCir2: #212742;
  --color-div1: #303234;
  --color-hoverNav2: #2c2f36;
  --color-gray8: #c2d2e3;
  --color-white22: #252a37;
  --color-br6: #ffffff21;

  /* /////////dialog Updat */
  --dialog-bg: #333333;
  --dialog-text: #ffffff;
  --dialog-close: #bbbbbb;
  --button-bg: #0056b3;
  --button-text: #ffffff;

  /* ////////////////Notif */
  --bg: #1e1e1e;
  --text: #f5f5f5;
  --box-bg: #393939;
  --shadow: #ffffff5c;
  /* --btn-bg: #ff9800; */
  --btn-text: #1e1e1e;
  /* --btn-hover: #e68900; */
}