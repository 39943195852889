

.notification-box {
  width: 90%;
  max-width: 400px;
  background: var(--box-bg);
  border-radius: 12px;
  box-shadow: 0 8px 16px var(--shadow);
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 10031;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  animation: cinemaEffect 0.6s ease-out forwards;
}

/* موقعیت در موبایل و دسکتاپ */
@media (max-width: 768px) {
  .notification-box {
    bottom: 2.5%;
    left: 2.5%;
    width: 95%;
    max-width: none;
  }
}

@media (min-width: 769px) {
  .notification-box {
    top: 15px;
    left: 15px;
  }
}

/* افکت پرده سینما */
@keyframes cinemaEffect {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  50% {
    opacity: 1;
    transform: scaleY(1.1);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* آیکون متحرک */
.icon-video {
  width: 50px;
  height: 50px;
  margin-bottom: 12px;
  border-radius: 50%;
}

.title{
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 6px;
}

.message{
  font-size: 14px;
  font-weight: 400;
  color: var(--text);
}

/* استایل دکمه‌ها */
.buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.btn {
  flex: 1;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: 0.3s;
  margin: 0 5px;
}

.btn-allow {
  background: var(--btn-bg);
  /* color: var(--btn-text); */
  color: #fff;
}

.btn-allow:hover {
  background: var(--btn-hover);
}

.btn-deny {
  background: #e0e0e0;
  color: #333;
}

.btn-deny:hover {
  background: #d6d6d6;
}

/* دکمه بستن */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: var(--text);
}

.close-btn:hover {
  color: #444;
}
