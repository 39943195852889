// @media (min-width: 2001px) {
//   main{
//     max-width: 98vw !important;
//   }
// }

@media (min-width: 1301px) {

  .container {
    max-width: 1320px !important;
  }

}

@media (max-width: 1300px) {

  .container {
    max-width: 1220px !important;
  }

  .sendNew .bodyBoxNew .chooseBox .generateCode {
    padding: 15px 10px;
  }
}

@media (max-width: 1250px) {

  .container {
    max-width: 1220px !important;
  }

  .footer .bodyBox {
    .blueBox {
      .tel {
        margin-left: 50px;
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }

  .sendNew .bodyBoxNew .chooseBox .generateCode {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {

  .container {
    max-width: 960px !important;
  }
  .newContainer:has(.OrderPro){
    padding: 0px;
  }

  .chatTicker {
    .right {
      max-width: 70%;
      width: 70%;
    }

    .left {
      max-width: 30%;
      width: 30%;
    }
  }

  .receive {
    max-width: 85%;
  }

  .depositCash,
  .withdrawCash {
    max-width: 100%;
  }


  .dialogContainer {
    .otherCoin {
      max-width: 650px;
    }
  }

  .notifPopup.large {
    max-width: 960px !important;
  }

  .pishkhan {
    .buyBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .resView {
        display: flex;
      }

      .divideTicket {
        width: 100%;
        height: 30px;

        >div {
          width: 90%;
          height: unset;
          border: unset;
          border-bottom: 1px dashed var(--color-white6);
        }

        .top {
          right: -16px;
          top: unset;
        }

        .bottom {
          left: -16px;
          bottom: unset;
        }
      }

      .rightBox,
      .leftBox {
        max-width: 100%;
        width: 100%;
        flex-direction: column;
      }

      .rightBox {
        .right {
          width: 100%;
          flex: 0 0 100%;
          border: none;

          .text {
            flex-direction: row;
            align-items: baseline;
            gap: 10px;
          }

          .cards {
            display: flex;
            direction: rtl;
            flex-direction: row-reverse;
            margin: auto;
            gap: 40px;
            margin: 25px auto;
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        .left {
          width: 100%;

          .buySellChangeBtn {
            display: none;
          }

          .btnChangeOrder {
            display: flex !important;
            flex: 1 1 auto;
            margin: 0 20px;
          }
        }
      }

      .leftBox {
        .walletAdress {
          p {
            margin-left: auto;
            margin-right: 30px;
          }


          h5 {
            width: 60%;
          }
        }

        .selectFee .css-qfz70r-MuiFormGroup-root {
          gap: 60px !important;
        }
      }

    }

    .historyBox {
      flex-direction: column;
    }

    .historyBox {
      flex-direction: column;

      .leftBox {
        width: 100%;
        max-width: 100%;

        .buySellChangeBtn {
          flex: 1 1 auto;
          max-width: 70%;

          >div {
            width: 100% !important;
          }
        }

        .text {
          flex: 0 1 auto;
          margin-left: 20px;
        }
      }

      .rightBox {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .OrderPro .historyOrderPro .tb_OrderPro table tr th:nth-child(3),
  .OrderPro .historyOrderPro .tb_OrderPro table tr td:nth-child(3) {
    display: none;
  }

}

@media (max-width: 1130px) {

  .container {
    max-width: 1220px !important;
  }

  .footer .bodyBox .aboutSite {
    flex-wrap: wrap;

    .des {
      width: 60%;
      max-width: 60%;
    }
  }

}

@media (max-width: 1050px) {
  .OrderPro .head .headerPriceShow .decPrice:nth-child(5) {
    display: none;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px !important;
  }

  .footer .bodyBox {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 25px;
    padding-top: 90px;

    .aboutSite>div {
      width: 100% !important;
      max-width: 100% !important;
    }

    .blueBox {
      padding: 17px 20px;

      .tel {
        margin-left: 0;
        text-wrap: nowrap;
        white-space: nowrap;
      }

      .des .circle1 {
        height: 75px;
      }
    }
  }

  .OrderPro .bodyBox .centerBox .CreateOrderBox {
    .changePrice .learningOrderEx p {
      &.p1 {
        display: none;
      }

      &.p2 {
        display: inline;
      }
    }

    .buyAndSellBox .boxBS .customInput {
      padding: 10px;
      gap: 5px;
    }
  }

  .chatTicker {
    flex-direction: column-reverse;
    gap: 20px;
    height: unset;

    .right {
      height: unset;
    }

    .right,
    .left {
      max-width: 100%;
      width: 100%;
    }
  }

  .OrderPro .buySell {
    .body {
      flex-direction: column;

      .userOrder {
        width: 100%;
      }

      .buySellBox {
        width: 100%;
        max-width: 100%;
      }

      .imgLeft {
        display: none;
      }




    }

    .top {
      .spotRate {
        display: none;
      }
    }
  }

  .send {
    flex-direction: column-reverse;

    .right,
    .left {
      width: 100%;
      max-width: 100%;
    }
  }

  .receive {
    max-width: 100%;

    .top {
      .walletAddress {
        flex-direction: column;

        .address,
        .qr {
          max-width: 100%;
          width: 100%;

          .alertText {
            p {
              font-weight: 400;
            }
          }
        }

        .qr img {
          width: 180px;
          height: 180px;
        }
      }
    }
  }

  .depositCash,
  .withdrawCash {
    max-width: 100%;
  }

  .profile {
    flex-direction: column-reverse;

    .navProfile,
    .accountBank,
    .changePass,
    .accountUser {
      flex: 1 1 100%;
      max-width: 100%;
      width: 100%;

    }

  }

  .notVerified {
    flex-direction: column;
  }

  .sliderCardsBank {
    min-width: 580px;
    height: 200px;

    .cardBankStyle {
      width: 300px;
      height: 184px;

      #number {
        margin: 3px 0 !important;
        font-size: 14px !important;
      }

      #name {
        margin: 8px 16px 8px 0;
        font-size: 13px;
      }

      #shaba {
        font-size: 12px;
        margin: 0;
      }
    }
  }

  .authPage .auth .authBody .rowCenter {
    flex-direction: column !important;
    align-items: normal;

    .textsItem,
    .inputsItem {
      max-width: 100%;
      border: none;
    }
  }

  .authPage .auth .authBody .uploadProfile {
    flex-direction: column !important;
    align-items: center;

    .leftItem,
    .rightItem {
      max-width: 100%;
      width: 100%;
    }
  }

  .notifPopup.large {
    max-width: 720px !important;
  }

  .stepByStep {
    display: none !important;
  }

  .mobileSteps {
    display: block !important;
  }

  .rv1 {
    display: none !important;
  }

  .rv2 {
    display: flex !important;
  }

  .copyrightContent {
    flex-wrap: wrap;

    p {
      width: 100%;
      text-align: center;
      margin-top: 12px;
    }
  }

  .radial-progress {
    left: 31% !important;
    top: 36% !important;
  }

  .OrderPro .bodyBox .priceLastOrder {
    display: none;
  }
}

@media (max-width: 840px) {
  .changePass {
    .description {
      flex-direction: column;
      gap: 0;
      margin-bottom: 24px;

      .item {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .sendNew .bodyBoxNew {
    flex-direction: column;
    gap: 10px;

    .chooseBox,
    .detailBox {
      width: 100% !important;
      max-width: 100% !important;
    }

    .detailBox {
      padding: 0;

      .imgPage {
        display: none;
      }
    }

  }

  .OrderPro .head .headerPriceShow .decPrice:nth-child(3),
  .OrderPro .head .headerPriceShow .decPrice:nth-child(4) {
    display: none;
  }

  .OrderPro .head .headerPriceShow .decPrice:nth-child(5) {
    display: flex;
  }

}

@media (max-width: 800px) {
  .newHeader .topHeader .menuControl {
    .wallet {
      display: none;
    }

    .dividerhorizontal {
      &.respons750 {
        display: none;
      }
    }

  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px !important;
  }

  .footer .bodyBox {
    .blueBox {
      padding: 17px 20px;
      flex-direction: column;
      gap: 10px;

      .tel {
        margin-left: 0;
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }

    .socialICons {
      flex-direction: column;
      gap: 30px;

      .icons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .createTicket .body .typeTicket {
    flex-direction: column;
    gap: 30px;

    >div {
      width: 100%;
      max-width: 100% !important;
    }

  }

  .authPage .auth .authBody .imgComfirm {
    width: 100%;
  }

  .send .right .textWallet {
    flex-direction: column-reverse;
    gap: 15px;
  }

  .receive {
    max-width: 100%;

    .top {
      .walletAddress {
        flex-direction: column;

        .address {
          max-width: 100%;
          width: 100%;

          .text {
            flex-wrap: wrap;

            h4 {
              width: 100%;
              order: 1;
              margin: 0 auto;
              margin-top: 5px;
            }
          }
        }


      }
    }

    .bottom .des {
      flex-direction: column;
      gap: 16px;

      .item {
        width: 100%;
        max-width: 100%;
        text-align: center;
        align-items: center;
      }
    }
  }

  .depositCash,
  .withdrawCash {
    max-width: 100%;
  }

  .profile {
    .changePass {
      .body {
        flex-direction: column-reverse;

        .left,
        .right {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .accountUser {

    .body,
    .boxButtonImg {
      flex-direction: column;

      .box,
      .left,
      .right {
        width: 100%;
        max-width: 100% !important;
        gap: 15px;
      }
    }
  }

  .dialogContainer {
    .otherCoin {
      max-width: 460px;
    }
  }

  .notVerified .textBtn {
    flex-direction: column;
  }

  .sliderCardsBank {
    min-width: 480px;
    height: 180px;

    .cardBankStyle {
      width: 250px;
      height: 180px;

      #number {
        margin: 3px 0 !important;
        font-size: 14px !important;
      }

      #name {
        margin: 8px 16px 8px 0;
        font-size: 13px;
      }

      #shaba {
        font-size: 12px;
        margin: 0;
      }
    }
  }

  .authPage .auth .authBody .alertText {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .authPage .auth .authBody .exampleTextUpload {
    flex-direction: column !important;
    gap: 25px;

    .textRight,
    .textLeft {
      max-width: 100%;
    }
  }

  .notifPopup.large {
    max-width: 540px !important;
  }

  .rvColumnRev {
    flex-direction: column-reverse;

    .social {
      width: 100% !important;
    }
  }

  footer .footercontent {
    flex-direction: column;

    .item {
      width: 100% !important;

      &:last-child {
        display: none;
      }
    }
  }
}

@media (max-width: 750px) {

  .walletUser .rightBox {
    flex-direction: column-reverse;

    .cardBox {
      flex-direction: column;

      .card {
        width: 100%;
        max-width: 100%;
      }
    }

    .walletAmount {
      justify-content: center;

      .wAmount {
        display: none;
      }
    }

  }



  .sendNew .bodyBox .rightBox .boxReceive {
    flex-direction: column;

    .qrCode {
      margin-left: auto;
      margin-right: auto;
    }
  }

  // .newHeader .topHeader .menuControl {
  //   .wallet {
  //     display: none;
  //   }

  //   .dividerhorizontal {
  //     &.respons750 {
  //       display: none;
  //     }
  //   }

  // }

  .changePass .body {
    .left {
      display: none;
    }

    .right {
      width: 100%;
      max-width: 100%;
      flex: unset;
    }
  }
}

@media (max-width: 700px) {
  .historyTicket .filterTable {
    flex-direction: column;

    .item {
      width: 100%;
    }

    .addTicket {
      order: 0;
      margin: auto;
    }
  }
}

@media (max-width: 620px) {
  .newHeader .alertBoxAuth .item {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 610px) {
  .notifPopup.large {
    max-width: 400px !important;
  }

  .pishkhan .buySellBox .balanceBox {
    flex-wrap: wrap;
  }

  .rightBox {

    .left {
      .btnChangeOrder {
        width: 100%;
        order: 2;
        margin-top: 10px !important;
      }
    }
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 500px !important;
  }

  .historyTicket .pagination {
    flex-direction: column;
  }

  .dialogContainer {
    .addCard {
      max-width: 350px;
    }

    // .otherCoin {
    //   max-width: 500px;
    // }
    .SrarchBox {
      flex-direction: column;
    }
  }
}

@media (max-width: 550px) {
  .newHeader .topHeader .menuControl {

    .dividerhorizontal,
    .ringdrop,
    >img {
      display: none;
    }
  }

  .newHeader .alertBoxAuth .item .textAut {
    >span {
      padding: 10px;
    }

    p {
      text-align: right;
    }
  }

  .detailWithdraw {
    width: 380px;
  }
}