.offline-notification {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 540px;
  background-color: var(--color-bgOffline);
  text-align: center;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--color-shadowOffline);
  z-index: 1000;
  animation: slideDown 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .t1 {
    font-size: 14px;
    color: var(--color-offline);
    direction: rtl;
  }

  .prog {
    margin-bottom: 3px;
  }
}

@keyframes slideDown {
  0% {
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}