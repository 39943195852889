@import './colorTheme.css';
@import './utils';
@import './icon';
@import './newFile';



.container {
  flex: 1;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--color-white);

  .user {
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;
    align-items: center;
    text-align: right;
    cursor: pointer;
    margin-right: 32px;
    margin-left: -10px;
    gap: 3px;

    .userText {
      margin: 7px 7px 0px 0;

      h6 {
        margin: 0;
        color: var(--color-gray3);
        font-weight: 600;
        font-size: $fontsize2;
        display: block;
        max-width: 140px;
        text-wrap: wrap;
        overflow: hidden;
        max-height: 42px;
      }

    }

  }

  .maxWidth120 {
    max-width: 120px;
  }

  .userLine {
    border-left: 1px solid var(--color-white3);
    color: var(--color-white);
    height: 40px;
  }

  .navbarText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .wallet {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;

      .balanc {
        display: flex;
        flex-direction: row;
        margin: 0 23px 0 25px;
        cursor: pointer;

        div {
          direction: rtl;
        }

        div span:last-child {
          display: block;
          font-weight: 600;
        }

        div span:first-child {
          font-size: $fontsize3;
          display: block;
          color: var(--color-txt4);

        }

        img {
          margin: auto;
          margin-left: 23px;
          width: 28px;
          height: 28px;
        }
      }

      .ringdrop {
        margin-left: 15px;
        margin-top: 10px;
        cursor: pointer;
      }

    }
  }

}

.menuNav {
  background: var(--color-white);
  border-top: 1px solid var(--color-white12);
  padding: 9px;
  height: 55px;
  font-size: $fontsize2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .item {
      color: var(--color-gray3);
      opacity: 0.7;
      padding-bottom: 13px;
      padding-top: 17px;
      transition: 0.3s ease;
      text-decoration: none;
      cursor: pointer;
      border-bottom: 2px solid var(--color-NO);

      &:hover {
        opacity: 1;
      }

      .textNumber {
        background: var(--color-orange1);
        display: inline-block;
        width: 15px;
        position: absolute;
        font-weight: 600;
        padding: 1px;
        font-size: $fontsize4;
        margin-right: -10px;
        margin-top: -5px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        color: var(--color-white);
        border-radius: 50%;
      }

      &.active {
        opacity: 1;
        color: var(--color-blue);
        border-bottom: 2px solid var(--color-blue);
      }
    }


  }

  .tradebutton {
    background: var(--color-green);
    color: var(--color-white);
    border: none;
    border-radius: 7px;
    font-size: $fontsize3;
    padding: 9px 35px;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray3);
    }
  }

  .btnMenu {
    padding-right: 5px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

}

//menu Popup Header
.MuiMenu-paper {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border-radius: 10px !important;
}

.MuiMenu-list {
  padding: 0 !important;
}

.menuPopup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  // width: 200px;
  // max-width: 100%;

  .item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    background-color: var(--color-white);
    border: none;
    border-top: 1px solid var(--color-NO);
    border-bottom: 1px solid var(--color-NO);
    padding: 10px 20px;
    opacity: 0.7;
    transition: 0.3s ease;

    cursor: pointer;

    &:hover {
      opacity: 1;
      border-top: 1px solid var(--color-white3);
      border-bottom: 1px solid var(--color-white3);
      background: var(--color-white3);
      background: linear-gradient(90deg, var(--color-gray1) 0%, var(--color-NO) 100%);
    }

    &.active {
      opacity: 1;
      border-bottom: 2px solid var(--color-NO) !important;
    }

    span {
      text-wrap: nowrap;
      white-space: nowrap;
    }

    span:first-child {
      font-weight: 600;

    }

  }

  .exit {
    border-top: 1px solid var(--color-white3) !important;

    &:hover {
      color: var(--color-blue)
    }


  }
}

.menuProfile {
  align-items: flex-end;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  .item {
    @extend %flexStart;
    align-items: center !important;
    flex-direction: row !important;
    direction: rtl;
    gap: 10px;

    >div {
      @extend %flexStart;
      flex-direction: column;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  span {
    color: var(--color-gray3);
  }
}


//notif Popup Header
.MuiMenu-paper:has(.notifPopup) {
  margin-top: 20px;
}

// .MuiMenu-paper:has(.notifPopup) {
//   margin-top: auto;
// }

.notifPopup {
  // background: var(--color-white);
  color: var(--txBody-color);

  .changeSound {
    width: 100%;
    padding: 5px 10px;
    margin: 5px 0 10px 0;

    .box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
      padding: 10px;
      border: 1px solid var(--color-br2);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

    .t1 {
      font-size: 13px;
      font-weight: 400;
      color: var(--txBody-color);
    }
  }

  &.small {
    width: 350px;
    max-height: 450px;
  }

  &.large {
    width: 960px;

  }

  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid var(--color-divider2);

    svg {
      cursor: pointer;
    }

    .ringdrop {
      width: unset;
      margin-left: 3px;
    }

    div {
      display: flex;
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;

      // span {
      //   background: var(--color-white1);
      //   width: 25px;
      //   height: 25px;
      //   border-radius: 50%;
      //   color: var(--color-orange1);
      //   font-weight: bold;
      //   padding: 2px;
      //   text-align: center;
      //   display: inline-block !important;
      // }

      .loadingCircular {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 82.5px;
      }

      button {
        border: none;
        color: var(--txBody-color);
        font-size: 12px;
        font-weight: 400;
        padding: 5px 10px;
        background: var(--color-bgReadAll);
        cursor: pointer;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        &:hover {
          background: var(--color-bgReadAllHover);
        }

        &.disable {
          cursor: default !important;

          &:hover {
            background: var(--color-bgReadAll);
          }
        }
      }

    }
  }


  .item {
    background: var(--color-whiteOld);
    padding: 13px;
    border-bottom: 1px solid var(--color-divider2);
    border-right: 5px solid var(--color-white);
    font-size: $fontsize3;
    cursor: pointer;

    p {
      text-align: right;
      direction: rtl;
    }

    .text {
      font-weight: 600;
    }

    &.active {
      border-right: 5px solid var(--color-orange1);
      background: var(--color-activeNotif);

      .text {
        color: var(--color-orange1);
      }
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .btnAll {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    direction: rtl;
    gap: 5px;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      opacity: 1;
    }

    img {
      position: relative;
      top: -2px;
    }

    p {
      font-weight: 600;
    }
  }
}

.drawerNav {
  direction: rtl;
  background-color: var(--color-white);
  padding: 16px;

  .balance {
    margin: 10px;
    text-align: center;
    @extend %flexCenter;
    justify-content: flex-start;
    gap: 20px;

    .text {
      @extend %flexCenter;
      flex-direction: column;

      div {
        @extend %flexCenter;
        flex-direction: row;
        justify-content: flex-start;
        gap: 3px;
        cursor: pointer;

        span {
          color: var(--color-blue);
          font-weight: 600;
        }
      }
    }
  }

  .AccordionSummary {
    background-color: var(--color-white);
  }

  .AccordionDetails {
    border: none
  }

  hr {
    @extend %divider;
  }

  .navcol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    padding-right: 16px;
    color: var(--color-txtblue2);
    border-radius: 10px;
    padding: 15px;
    margin: 11px 0;

    &.active {
      background: var(--color-blue);
      color: var(--color-white);

      span {
        background-color: var(--color-white);
      }
    }
  }

  .active {
    .AccordionSummary {
      background: var(--color-blue) !important;
      color: var(--color-white) !important;
      border-radius: 10px;
      color: var(--color-white);

      .navAccordion {
        color: var(--color-white);
      }

      span {
        background-color: var(--color-white) !important;
      }
    }
  }


  .navAccordion {
    @extend .navcol;
    padding: 0;
    margin: 0;
  }

  .subNavMenu {
    @extend %flexStart;
    flex-direction: column;
    color: var(--color-gray3);
    gap: 10px;
    margin-top: 5px;

    a {
      padding: 10px 50px;
      width: 100%;
      border: 1px solid var(--color-NO);

      &::before {
        content: "•";
        color: var(--color-gray3);
        font-weight: bold;
        display: inline-block;
        width: 16px;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
        border-top: 1px solid var(--color-white3);
        border-bottom: 1px solid var(--color-white3);
        background: var(--color-white3);
        background: linear-gradient(90deg, var(--color-gray1) 0%, var(--color-NO) 100%);
      }

      &:active {}
    }
  }

}

footer {
  background: var(--color-white);
  font-size: $fontsize2;
  display: block;
  padding-bottom: 60px;
  margin-top: 32px;

  hr {
    @extend %divider;
    box-sizing: content-box;
    overflow: visible;
  }


  .footerbar {
    margin-top: 32px;
    padding: 10px 15px;
    background: var(--color-white3);

    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: 25px;
      width: 60%;
    }

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      // width: 186px;
      padding-left: 15px;
      padding-right: 15px;
      margin: 14px 0;

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        span:first-child {
          font-size: $fontsize4 !important;
          font-weight: 600;
          display: block;
          text-align: right
        }

        span:last-child {
          font-size: $fontsize4 !important;
          font-weight: 600;
          display: block;
          text-align: right;
          color: var(--color-gray3);
        }
      }
    }
  }

  .footercontent {
    padding-top: 30px;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;

    .item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      direction: rtl;
      text-align: right;
      padding: 7px;

      .socialIcon {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }

      .title span {
        font-size: $fontsize2;
        color: var(--color-gray3);
        font-weight: 600;
        display: inline;
        text-align: right;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        //padding: 12px;
        border: 1px solid var(--color-white6);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background: var(--color-txt4);
        }
      }

      img {
        margin: 5px;
      }

      li {
        margin: 5px 0;
      }
    }


    .item:last-child {
      text-align: center;
      display: block;
      width: 20%;

      img {
        margin-top: 40px;
      }
    }

  }

  .copyrightContent {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &:first-child {
        gap: 15px
      }
    }
  }

}

.loginPage {
  padding: 40px 0;


  .login {
    max-width: 450px;
    margin: auto;
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--color-white);
    box-shadow: 0px 0px 52px 0px var(--color-blue4);

    .loginBody {
      position: relative;

      .loginBack {
        position: absolute;
        right: 5%;
        top: 40px;
        transition: 0.3s ease;
        border-radius: 50%;
        padding: 14px;
        width: 50px;
        height: 50px;
        border: 1px solid var(--color-white3);
        cursor: pointer;

        &:hover {
          background: var(--color-blue);
          color: var(--color-white);
        }
      }

      .upTitle {
        text-align: center;
        padding: 40px 0 24px 0;

        h5 {
          font-weight: 600;
          font-size: 20px
        }

        p {
          font-size: 13px;
          color: var(--color-txt4);
          margin-bottom: 16px
        }
      }

      .textCenter {
        text-align: center;
        margin-bottom: 8px;

        .sign {
          text-align: center;
          font-size: $fontsize2;
          color: var(--color-txt4);
          font-weight: 400;

          &.flex {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }

          span {
            font-size: $fontsize2;
            color: var(--color-txt4);
            font-weight: 400;
            color: var(--color-blue);
            margin-right: 4px;
            cursor: pointer;
          }

          .btn {
            width: 100%;
            border: none;
            color: var(--color-textOnlyWhite);
            transition: 0.3s ease;
            border-radius: 8px;
            padding: 7px 10px;
            background: var(--color-blue);
            box-shadow: 0px 0px 52px -12px var(--color-blue5);

            &:hover {
              background: var(--color-green);
            }
          }
        }
      }

      .inputs {
        padding: 30px;

        .textForget {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .forget {
            font-size: $fontsize2;
            color: var(--color-white3);
            font-weight: 400;
            color: var(--color-blue);
            cursor: pointer;
            transition: 0.3s ease;

            img {
              margin-left: 5px;
            }

            &:hover {
              font-weight: 600
            }
          }

          .formCheck {
            @extend %flexCenter;

            &.role {
              direction: rtl;
              gap: 7px;
              margin-left: auto;

              a {
                color: var(--color-blue);

                &:hover {
                  text-decoration: underline !important;
                }
              }

              input {
                cursor: pointer;
              }
            }

            label {
              @extend %flexCenter;
              margin-top: 0px;
              font-size: $fontsize2;
              color: var(--color-txt4);
              font-weight: 400;
              gap: 7px;
              cursor: pointer;
            }
          }
        }

      }

      .textvr {
        font-size: $fontsize2;
        color: var(--color-txt4);
        font-weight: 400;
        margin-bottom: 20px;
        display: block;
        text-align: right;
      }

      .loadingCircular {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 61px;
        background: var(--color-blue);
        border-radius: 13px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        -ms-border-radius: 13px;
        -o-border-radius: 13px;
        margin-top: 27px;
      }
    }

  }
}

.authPage {

  .auth {
    // max-width: 895px;
    width: 895px;
    margin: 90px auto;
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--color-white);
    overflow: hidden;
    box-shadow: 0px 0px 52px 0px var(--color-blue4);

    .authBody {
      padding: 0 15px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        gap: 20px;
        direction: rtl;

        .titleBack {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          direction: rtl;
          gap: 20px;
          padding: 24px 0;
          margin-left: auto;

          div {
            transition: 0.3s ease;
            border-radius: 50%;
            padding: 14px;
            width: 50px;
            height: 50px;
            border: 1px solid var(--color-white3);
            cursor: pointer;

            &:hover {
              background: var(--color-blue);
              color: var(--color-white);
            }
          }

          h5 {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }

      .inputs {
        .errorText {
          opacity: 0;
        }

        img {
          width: 26px;
          height: 26px;
        }
      }

      .formControlLogin {
        input {
          border: none !important;
          border-right: 1px solid var(--color-white3) !important;
          direction: rtl !important;
        }

        &.ltr {
          input {
            direction: ltr !important;
            border-left: 1px solid var(--color-white3) !important;
          }
        }

        >label {
          right: 70px !important;
          left: unset !important;
          padding: 0 10px !important;
          background-color: var(--color-white) !important;
          transform-origin: top right !important;
        }
      }

      .rowCenter {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        direction: rtl;
        margin-top: 48px;

        .textsItem {
          flex: 1;
          max-width: 50%;
          padding-left: 3%;
          padding-right: 5%;
          padding-top: 30px;

          .textWarning {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
            color: var(--color-orange1);
            margin-bottom: 20px;

            h6 {
              font-size: 16px;
              font-weight: 400;
            }
          }

          .text {
            direction: rtl;
            font-size: $fontsize2;
            color: var(--color-txt4);
            text-align: right;
            margin-bottom: 16px;
          }

          .videoPlay {
            >div {
              width: unset !important;
              height: unset !important;
            }
          }
        }

        .inputsItem {
          direction: rtl;
          flex: 1;
          max-width: 50%;
          border-left: 2px solid var(--color-white3);
          padding-left: 5%;
          padding-right: 3%;
          padding-top: 30px;

          .birthDate {
            @extend %flexStart;
            flex-direction: column;
            gap: 6px;

            .MuiSvgIcon-root {
              color: var(--txBody-color);
            }

            span {
              font-size: $fontsize2;
              color: var(--color-txt4);
              font-weight: 400;
            }

            div {
              @extend %flexStart;
              gap: 12px;
              width: 100%;
              color: var(--txBody-color) !important;

              input {
                flex: 1;
                font-size: $fontsize2;
                text-align: center;
                border-radius: 10px;
                padding: 10px 12px;
                border: 2px solid var(--color-white3);

                &::placeholder {
                  color: red
                }
              }
            }
          }
        }
      }

      .finalCheckBtn {
        @extend %flexCenter;
        gap: 2px;
        margin: 32px 0;


        img {
          position: relative;
          top: -1px;
        }
      }

      .loadingCircular {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        background: var(--color-blue);
        width: 100%;
        height: 61px;
        margin: 32px 0;
      }

      .btn {
        padding: 0 26px;
      }

      .exampleTextUpload {
        @extend %flexStart;
        text-align: right;
        width: 100%;
        margin-top: 48px;
        padding: 0 15px 0 0;
        margin-bottom: 10px;

        .textLeft {
          max-width: 50%;
          flex: 1;
          padding: 0 15px;

          h6 {
            color: var(--color-orange1);
            font-size: $fontsize1;
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 1.2;
          }

          .lists,
          p {
            direction: rtl;
            font-size: 14px;
            color: var(--color-txt4);

            li {
              list-style: disc;
            }
          }
        }

        .textRight {
          @extend .textLeft;
          padding-left: 5px;
        }

        .textLeft h6 {
          margin-right: -10px;
        }

      }

      .alertText {
        @extend %flexStart;
        gap: 25px;
        direction: rtl;
        color: var(--color-orange1);
        border: 1px solid transparent;
        margin: 20px 0 16px 0;
        background: var(--color-bg1);
        text-align: right;
        padding: 20px 40px;
        border-radius: 20px;

        p {
          max-width: 87%;
          margin-bottom: 0;
          font-size: 15px;
          font-weight: 500;
          color: var(--color-orange1);
        }

      }

      .uploadProfile {
        @extend %flexStart;
        padding: 0 15px;
        margin-top: 32px;
        flex-direction: row-reverse;

        .leftItem,
        .rightItem {
          max-width: 50%;
          flex: 1;
          margin: 16px 0;
          padding: 0 15px;
          text-align: center;
        }

        .leftItem {
          span {
            color: var(--txBody-color);
            margin-bottom: 24px;
            font-size: $fontsize2;
            text-align: right;
            padding: 0 15px;
            display: block;
          }
        }

        .rightItem {
          .btmtitle {
            color: var(--txBody-color);
            margin-bottom: 24px;
            font-size: $fontsize2;
            text-align: right;
            direction: rtl;
          }

          .lstchd {
            color: var(--color-txt4);
          }
        }

        .drag {
          color: var(--color-text4);
          border-radius: 15px;
          overflow: hidden;
          width: 100%;
          height: 310px;
          background: var(--color-bg1);
          text-align: center;
          position: relative;
          border: 2px dashed var(--color-txt4);
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px;
          @extend %flexCenter;

          .fileUploader {
            width: 100%;
            height: 100%;

            label {
              width: 100% !important;
              height: 100% !important;
              display: flex !important;
              justify-content: center;
              align-items: center;
            }
          }

          .captions {
            margin: 15px 0;
            display: block;
          }

          button {
            background: var(--color-txt4);
            box-shadow: 0px 6px 37px 0px var(--color-gray21);
            color: var(--color-white);
            border: none;
            padding: 15px 10px;
            min-width: 300px;
            border-radius: 10px;
            transition: 0.3s ease;
            cursor: pointer;

            &:hover {
              background: var(--color-green);
            }
          }
        }

        .errorIamge {
          .erroring {
            border-radius: 50%;
            z-index: 2;
            left: 42%;
            font-size: 30px;
            display: block;
            padding: 24px;
            position: absolute;
            transition: 0.3s ease;
            color: var(--color-white);
            cursor: pointer;
            top: 34%;
            background: var(--color-white42);
            width: 80px;
            height: 80px;

            &:hover {
              background: var(--color-white) !important;
              color: var(--color-orange1) !important;
            }
          }
        }

        .erroring {
          display: none;
        }

        .deleteimg {
          display: none;

          &:hover {
            background: var(--color-white) !important;
            color: var(--color-orange1) !important;
          }
        }

        .imguploaded {
          display: none;
          opacity: 0.5;
        }

        .img-fluid {
          max-width: 100%;
          height: auto;
        }

        img {
          vertical-align: middle;
          border-style: none;
        }

        .uploaded .deleteimg {
          border-radius: 50%;
          z-index: 2;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 30px;
          display: block;
          padding: 24px;
          transition: 0.3s ease;
          color: var(--color-white);
          cursor: pointer;
          background: var(--color-white42);
          width: 80px;
          height: 80px;
        }

        .uploading .showProgress {
          opacity: 1 !important;
          display: flex !important;
        }

        .showProgress {
          display: block;
          position: absolute;
          opacity: 0;
          display: none;
        }

        .uploading,
        .uploaded,
        .errorIamge {
          transition: 0.3s ease;
          padding: 0 !important;
          border: none;
          background: var(--color-black);

          .imguploaded {
            display: block;
            opacity: 0.5;
          }

          .fileUploader {
            display: none;
          }
        }

      }

      .imgComfirm {
        width: 600px;
        @extend %flexCenter;
        padding: 40px 20px;
        flex-direction: column;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
        }

        h4 {
          font-size: 22px;
          margin-top: 25px;
          font-weight: 400;

        }

        p {
          font-size: 13px;
          margin-top: 15px;
          color: var(--color-gray5);
          max-width: 60%;
          text-wrap: wrap;
          margin-bottom: 40px;
        }
      }


    }

  }
}

.notVerified {
  width: 100%;
  @extend%flexCenter;
  justify-content: space-between;
  margin: 16px 0;
  padding: 10px;
  font-size: $fontsize2;
  border-radius: 14px;
  color: var(--color-white);
  background: var(--color-blue1);
  background: linear-gradient(90deg, var(--color-blue1) 0%, var(--color-blue) 100%);
  direction: rtl;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);

  >div {
    @extend%flexCenter;
    gap: 15px;
  }

  .textImg {
    img {
      margin: 0 20px 0 5px;
      width: 50px;
      height: 100%;
    }
  }

  .textBtn {
    button {
      @extend%flexCenter;
      justify-content: space-between;
      margin-left: 10px;
      background: var(--color-orange2);
      font-size: $fontsize2;
      text-align: center;
      padding: 12px 15px;
      max-width: 200px;
      color: var(--color-white);
      border: none;
      border-radius: 7px;
      transition: 0.3s ease;
      cursor: pointer;
      gap: 20px;

      &:hover {
        background-color: var(--color-green);
      }
    }
  }
}

.pishkhan {
  width: 100%;

  .buyBox {
    @extend %flexStart;
    direction: rtl;
    text-align: center;
    align-items: stretch;
    overflow: hidden;

    .textStepsBuy {
      @extend %flexCenter;
      justify-content: flex-start;
      gap: 10px;
      // width: 50%;

      >div {
        @extend %flexStart;
        flex-direction: column;

        >span {
          font-weight: 600;
          font-size: 14px;
        }

        .step2 {
          @extend %flexStart;
          gap: 10px;

          span {
            color: var(--color-gray3);
            font-weight: 500;
            font-size: 11px;

            &:nth-child(1) {
              color: var(--color-orange2);
              font-weight: 600;
            }
          }
        }
      }
    }

    .rightBox {
      width: 60%;
      max-width: 60%;
      background: var(--color-white);
      padding: 15px;
      -webkit-border-radius: 0 13px 13px 0;
      -moz-border-radius: 0 13px 13px 0;
      -ms-border-radius: 0 13px 13px 0;
      -o-border-radius: 0 13px 13px 0;
      border-radius: 0 13px 13px 0;
      @extend %flexStart;

      .right {
        width: 25%;
        flex: 0 0 25%;
        padding: 0 15px;
        font-size: 14px;
        color: var(--color-txtblue2);
        border-left: 1px solid var(--color-white3);
        @extend %flexStart;
        flex-direction: column;

        .text {
          @extend %flexStart;
          width: 100%;
          text-align: right;
          flex-direction: column;

          >div {
            @extend %flexCenter;
            text-align: center;
            gap: 10px;

            span {
              display: block;
            }

            span:nth-child(2) {
              color: var(--color-orange2);
              font-weight: 600;
            }
          }

          p {
            color: var(--color-gray3);
            font-weight: 500;
            font-size: 11px;
            margin: 8px 0 16px 0;
            opacity: 0.6;
          }
        }

        .card {
          cursor: pointer;
          transition: 0.3s ease;
          margin: 8px;
          width: 100px;
          border-radius: 15px;
          height: 100px;
          border: 1px solid var(--color-white3);
          position: relative;
          @extend %flexCenter;
          flex-direction: column;
          padding: 12px;
          gap: 5px;
          cursor: pointer;

          &:hover {
            border: 1px solid var(--color-blue);
          }

          &.active {
            border: 1px solid var(--color-blue);

            .checkCoin {
              opacity: 1 !important;
            }
          }

          .checkCoin {
            opacity: 0;
            width: 20px;
            position: absolute;
            top: -6px;
            right: -6px;
          }

          .coin {
            width: 23px;
            height: 23px;
          }

          span {
            color: var(--color-txtblue2);
            font-weight: 500;
            display: block;
          }

          p {
            color: var(--color-txt4);
          }
        }
      }

      .left {
        width: 75%;
        flex: 0 0 75%;
        padding: 0 20px 0 15px;

        .titleAmount {
          @extend %flexCenter;
          justify-content: space-between;
          margin: 15px 0;

          .warning {
            @extend %flexCenter;
            justify-content: flex-start;
            gap: 10px;
            width: 50%;
            font-size: $fontsize3;
            color: var(--color-orange1);
            direction: ltr;
            border-radius: 8px;
            padding: 10px;
            background: var(--color-orange1);
            background: linear-gradient(90deg, var(--color-orange7) 0%, var(--color-NO) 100%);
          }
        }

        .exchange {
          @extend %flexCenter;
          justify-content: space-between;
          gap: 5px;

          .divideImg {
            width: 0.1px;
            background-color: var(--color-white);
            position: relative;

            div {
              background: var(--color-blue);
              position: absolute;
              z-index: 1;
              width: 38px;
              height: 38px;
              text-align: center;
              padding: 7px;
              border-radius: 50%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

          }

          .box {
            @extend %flexCenter;
            flex: 0 0 48%;
            max-width: 48%;
            padding: 8px;
            border: 2px solid var(--color-white3);
            border-radius: 8px;
            transition: 0.5s ease;

            &.read:has(input:focus) {
              border: 2px solid var(--color-blue);

            }

            .read:focus {
              color: var(--color-blue)
            }

            input {
              border: none;
              padding: 7px 10px;
              color: var(--color-txtblue2);
              font-size: 16px;
              flex: 0 0 58.333333%;
              max-width: 58.333333%;


            }

            >div {
              @extend %flexCenter;
            }
          }
        }

        .networkCoin {
          flex: 0 0 100%;
          max-width: 100%;
          @extend %flexCenter;
          justify-content: flex-end;
          gap: 14px;

          .item {
            position: relative;
            @extend %flexCenter;
            color: var(--color-txt4);
            border: 2px solid var(--color-white3);
            padding: 6px 30px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.5s ease;

            img {
              position: absolute;
              top: -8px;
              right: -8px;
              opacity: 0;
              width: 17px;
              height: 17px;
            }

            &.active {
              color: var(--color-blue);
              border: 2px solid var(--color-blue);

              img {
                opacity: 1;
              }
            }
          }
        }

        .wallet {
          @extend %flexCenter;
          direction: ltr;
          margin: 10px;
          background: var(--color-white5);
          padding: 11px;
          border: 2px solid var(--color-white5);
          transition: 0.5s ease;
          border-radius: 14px;
          margin-left: 0;

          &.tagWallet {
            background: transparent !important;

            .iconLeft {
              opacity: 0 !important;
            }
          }

          &:hover {
            border: 2px solid var(--color-blue);
          }

          input {
            width: 100%;
            direction: rtl;
            padding-right: 15px;
            text-align: right;
            font-size: 14px;
            background: transparent;
            border: none;
            color: var(--color-txtblue2);
          }

          .iconRight {
            padding: 5px;
            width: 25%;
            max-width: 25%;
            border-left: 1px solid var(--color-white6);

            img {
              width: 22px;
            }
          }

          .iconLeft {
            padding: 5px;
            @extend %flexCenter;
            width: 25%;
            max-width: 25%;
            gap: 5px;

            img {
              cursor: pointer;
            }
          }
        }

        .limitText {
          @extend %flexCenter;
          margin: 15px 0;
          text-align: center;
          justify-content: flex-start;
          gap: 8px;

          img {
            width: 24px;
            height: 24px;
            margin-top: -2px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            display: block;
          }
        }

        .textLimitBuy {
          @extend %flexCenter;
          justify-content: space-between;
          direction: rtl;
          margin-top: 10px;


          h4 {

            font-weight: 400;
          }

          p {
            color: var(--color-txt4);
            font-weight: 400;
          }
        }

      }

    }

    .leftBox {
      width: 40%;
      max-width: 40%;
      overflow: hidden;
      background: var(--color-white);
      padding: 15px;
      flex: 0 0 38.7%;
      -webkit-border-radius: 13px 0 0 13px;
      -moz-border-radius: 13px 0 0 13px;
      -ms-border-radius: 13px 0 0 13px;
      -o-border-radius: 13px 0 0 13px;
      border-radius: 13px 0 0 13px;


      .locked {
        display: none;
      }

      &.lockItem {
        position: relative;

        .body {
          -webkit-filter: blur(3px);
          filter: blur(3px);
        }

        .locked {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 101;
          display: block
        }
      }



      .titleTimer {
        @extend %flexCenter;
        justify-content: space-between;


        .progress-btn {
          height: 28px;
          border-radius: 5px;
          background: var(--color-gray4);
          max-width: 150px;
          padding: 0;
          margin: 0;


          &.active .progress {
            opacity: 1;
            -webkit-animation: progress-anim 10s ease 0s !important;
            animation: progress-anim 10s ease 0s !important;
          }

          &.active .btn {
            -webkit-animation: neno !important;
            animation: neno !important;
          }

          &.active {
            box-shadow: none;
            -webkit-animation: neno !important;
            animation: neno !important;
          }

          .btn {
            line-height: 30px;

            .row {
              @extend %flexCenter;
              justify-content: space-between;
              padding: 0 15px;
            }
          }

          span {
            font-size: 12px;
          }

          .progress {
            border-radius: 5px 0 0 5px;
            background: var(--color-gray5);
          }
        }

        >h4 {
          font-size: 14px;
          padding: 0;
          color: var(--color-blue);
          font-weight: 400;
          margin-top: 3px;
        }
      }

      .detailCoin {
        @extend %flexCenter;
        gap: 10px;
        padding: 0 30px;
        margin: 25px 0 35px 0;
        font-size: 14px;
        direction: rtl;

        .item {
          width: 33.33%;
          @extend %flexCenter;
          flex-direction: column;

          img {
            width: 23px;
          }

          p {
            margin: 5px 0;
            font-size: 12px;
            color: var(--color-txt4);
          }

          span {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      .walletAdress {
        @extend %flexCenter;
        direction: rtl;
        // gap: 5px;
        background: var(--color-white5);
        padding: 11px;
        border: 2px solid var(--color-white5);
        transition: 0.5s ease;
        border-radius: 14px;
        justify-content: flex-start;
        justify-content: space-between;

        img,
        h5,
        p {
          padding: 5px;
        }

        p {
          color: var(--color-blue);
          font-weight: 600;
          text-align: right;
          direction: rtl;
          font-size: 14px;
        }

        h5 {
          font-size: 12px;
          font-family: "Arial", sans-serif !important;
          padding-left: 10px;
          text-align: left;
          font-weight: 400;
        }

      }

      .finaldes {
        @extend %flexCenter;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0 36px 0;

        .item {
          @extend %flexCenter;
          width: 100%;
          justify-content: space-between;
          gap: 5px;

          &.mark {

            h5,
            p {
              color: var(--color-blue);
              font-weight: 500;
            }
          }

          h5 {
            flex: 0 1 auto;
            color: var(--color-txt4);
            font-weight: 400;
            font-size: 12px;
          }

          p {
            flex: 0 1 auto;
            direction: rtl;
            font-size: 12px;
          }

          .dividedDash {
            flex: 1 1 auto;
            height: 0;
            border-bottom: 1px dashed var(--color-black4);
          }


        }

      }

      .selectFee {
        @extend %flexCenter;
        gap: 50px;
        width: 100%;

        .css-qfz70r-MuiFormGroup-root {
          gap: 30px !important;
        }


      }

    }

    .resView {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      display: none
    }
  }

  .historyBox {
    @extend %flexStart;
    text-align: center;
    align-items: stretch;
    overflow: hidden;
    margin-top: 20px;
    gap: 20px;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);

    .rightBox {
      width: 60.2%;
      max-width: 60.2%;
      background: var(--color-white);
      padding: 15px;
      border-radius: 13px;
      box-shadow: 0px 0px 52px 0px var(--color-blue10);

      .tableHistory {
        overflow: auto;
      }

      .titleUp {
        @extend %flexCenter;
        justify-content: space-between;
        width: 100%;
        padding: 15px 0;

        >div {
          @extend %flexCenter;
          gap: 3px;
        }

        img {
          margin-left: 5px;
          width: 20px;
          margin-top: -2px;
        }

        p {
          font-weight: 600;
          font-size: 14px;
          text-align: right;
        }

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-black);
        }
      }

      .head {
        font-size: 14px;
        background: var(--color-white16);
        border-radius: 10px;
        padding: 16px 32px;
        min-width: 675px;
        @extend %flexStart;
        justify-content: flex-end;
        gap: 4px;
        text-align: right;


        >div {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
      }

      .body {
        background: var(--color-white);
        padding-top: 4px;
        height: 280px;
        min-width: 675px;
        overflow-y: auto !important;
        color: var(--color-txt4);

        .item {
          padding: 20px 0;
          border-bottom: 1px solid var(--color-white3);
          @extend %flexStart;
          justify-content: flex-end;
          text-align: right;
          font-size: 14px;

          >div {
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
          }

          #namecoin,
          #recieveUnit {
            @extend %flexCenter;
            direction: rtl;
            gap: 10px;
          }

          #joziat {
            text-align: center !important;
            cursor: pointer;

            &:hover {
              color: var(--color-blue);
            }
          }
        }
      }
    }

    .leftBox {
      width: 38.8%;
      max-width: 38.8%;
      background: var(--color-white);
      padding: 15px;
      border-radius: 13px;
      box-shadow: 0px 0px 52px 0px var(--color-blue10);

      .titleUp {
        @extend %flexCenter;
        justify-content: space-between;
        direction: rtl;
        width: 100%;

        .text {
          @extend %flexCenter;

          span {
            font-size: 14px;
            font-size: 500;
          }

          img {
            width: 20px;
          }
        }

        .buySellChangeBtn {
          >div {
            width: unset;
            flex: unset;
            max-width: unset;
          }

          .active {
            font-weight: 600;
          }

          button {
            font-size: 12px;
          }



        }


      }

      .tableRatePrice {
        .head {
          font-size: 14px;
          background: var(--color-white16);
          border-radius: 10px;
          padding: 16px 30px;
          display: flex;
          justify-content: space-between;
        }

        .body {
          @extend %flexStart;
          gap: 15px;
          flex-direction: column;
          height: 280px;
          overflow-y: auto;
          padding: 0px 15px;

          .item {
            width: 100%;
            font-size: 14px;
            border-radius: 10px;
            padding: 16px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--color-white3);
            padding: 0 15px;
            padding-bottom: 15px;
            margin-top: 15px;


            h5 {
              direction: rtl;
              font-size: 14px;
              font-weight: 400;

            }

            img {
              width: 22px;
              height: 22px;
            }

            .row {
              @extend %flexStart;
              align-items: center;
              gap: 5px;
              direction: rtl;

              .name {
                @extend %flexCenter;
                flex-direction: column;
              }

              span {
                font-weight: 600;
                font-size: 14px;
                display: block;
                direction: rtl;
              }

              p {
                font-size: 12px;
                color: var(--color-txt4);
              }
            }
          }

        }
      }

    }
  }


  .buySellBox {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;

    .balanceBox {
      justify-content: flex-start;

      .balance {
        margin-right: auto
      }
    }

  }

}

.depositCash,
.withdrawCash {
  @extend %flexStart;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  flex: 0 0 100%;
  margin: 16px auto;
  margin-bottom: 0;

  .body {
    @extend %flexStart;
    direction: rtl;
    width: 100%;
    gap: 20px;
    align-items: stretch;

    &.highDeposit {
      .right {
        .titleCopy {
          font-size: 17px;

        }

        .textCopy {
          padding-right: 20px;
          @extend %flexStart;
          gap: 7px;

          p {
            font-size: 15px;
            font-weight: 400;

            font-family: "Arial", sans-serif !important;
          }
        }

        .nameCompany {
          font-size: 15px;
          font-weight: 400;
          color: var(--color-black);
          margin-right: 20px;
          background-color: var(--color-white3);
          padding: 5px 10px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }

        .warningText {
          @extend%flexStart;
          align-items: center;
          gap: 5px;
          padding: 10px 15px;
          background-color: var(--color-orange3);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          margin: 24px 0;

          p {
            font-size: 13px;
            font-weight: 500;
            color: var(--color-orange4)
          }
        }
      }

      .left {
        .stepText {
          border-right: 1px dashed var(--color-white14);
          direction: rtl;
          padding: 0 20px;
          margin: 20px;
          margin-left: 0px;

          >div {
            direction: rtl;
            @extend %flexStart;
            align-items: center;
            gap: 10px;
            position: relative;
            margin-bottom: 30px;

            span {
              position: absolute;
              right: -30px;
              top: 0;
              @extend %flexCenter;
              width: 20px;
              height: 20px;
              font-size: 12px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              color: var(--color-black);
              background-color: var(--color-orange2);
              z-index: 5;
            }

            h5 {
              font-size: 14px;
              font-weight: 400;

            }
          }

        }
      }
    }


    .right {
      @extend %flexStart;
      flex-direction: column;
      background: var(--color-white);
      text-align: center;
      border-radius: 10px;
      padding: 25px;
      width: 50%;
      max-width: 50%;
      direction: rtl;
      box-shadow: 0px 0px 52px 0px var(--color-blue10);

      .changeDeposit {
        @extend %flexCenter;
        justify-content: space-between;
        direction: rtl;
        padding: 10px 0;
        width: 100%;

        >h3 {
          font-size: 18px;

          font-weight: 600;
          text-align: right;
        }

        .navControl {
          @extend %flexCenter;
          gap: 10px;
          justify-content: flex-end;
          direction: rtl;

          .item {
            padding: 5px 10px;
            background-color: var(--color-white14);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            color: var(--color-black);
            font-size: 13px;

            &.active {
              background-color: var(--color-blue);
              color: var(--color-white);
            }
          }
        }
      }

      >h4 {
        font-size: 14px;
        color: var(--color-gray4);
        font-weight: 400;
        margin: 15px 0 16px 0;
        text-align: right;
      }

      .btnsPercentage {
        display: flex;
        justify-content: center;
        @extend %flexCenter;
        width: 100%;
        gap: 10px;


        div {
          flex: 0 0 33.33%;
          width: 33.33%;

          p {
            width: 100%;
            padding: 12px;
            border: none;
            background: var(--color-white5);
            transition: 0.3s ease;
            border-radius: 7px;
            cursor: pointer;

            &:hover {
              background: var(--color-blue);
              color: var(--color-white);
            }
          }
        }
      }

      .chooseAllAmount {
        @extend %flexCenter;
        gap: 10px;

        p {
          font-size: 16px;
          color: var(--color-gray7);
          border-right: 1px solid var(--color-white3);
          width: 60px;
          height: 30px;
          text-align: center;
          line-height: 30px
        }

        >div {
          @extend %flexCenter;
          width: 105px;
          font-weight: 600;
          // padding: 5px 12px 5px 24px;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;
          background: var(--color-white5);
          border: 1px solid var(--color-NO);
          gap: 3px;

          &:hover {
            border: 1px solid var(--color-white3);
          }
        }
      }

      .alertText {
        @extend%flexStart ;
        gap: 10px;
        direction: rtl;
        color: var(--color-orange1);
        border: 1px solid transparent;
        background: var(--color-white2);
        text-align: right;
        padding: 25px;
        border-radius: 13px;
        margin-top: 40px;

        img {
          width: 39px;
          height: 35px;
        }

        p {
          max-width: 87%;
          margin-bottom: 0;
          font-size: 13px;
          font-weight: 500;
          color: var(--color-orange1);
        }

      }

      .finalAmount {
        @extend %flexCenter;
        justify-content: space-between;
        padding: 20px;
        margin-top: 32px;
        border-radius: 13px;
        color: var(--color-green);
        background: var(--color-white9);
        width: 100%;
        direction: rtl;
        margin-bottom: 22px;
      }

      .allAmount {
        @extend %flexCenter;
        flex-direction: column;
        margin: auto;
        margin-top: 24px;

        p {
          font-size: 14px;
          color: var(--color-gray4);
          margin-top: 16px;
        }

        h3 {
          font-size: $fontsize1;

          font-weight: 500;
          direction: rtl;
        }
      }

      .maxMinAmount {
        >div {
          @extend %flexStart;
          align-items: center;
          gap: 10px;
          padding: 10px 5px;

          p {
            font-size: 13px;
            color: var(--color-txt4);
            font-weight: 400;
          }

          h5 {
            font-size: 13px;

            font-weight: 500;
          }
        }
      }
    }

    .left {
      @extend %flexStart;
      flex-direction: column;
      background: var(--color-white);
      text-align: right;
      border-radius: 10px;
      padding: 25px;
      padding-top: 50px;
      width: 50%;
      max-width: 50%;
      direction: rtl;
      gap: 15px;
      align-items: flex-start;
      box-shadow: 0px 0px 52px 0px var(--color-blue10);

      >h3 {
        font-size: 16px;
        font-weight: 600;
        color: $fontsize3;
        text-align: right;
      }

      .desText {
        @extend %flexCenter;
        justify-content: flex-start;
        gap: 10px;
        padding: 15px 10px;
        background-color: var(--color-yellow6);
        width: 100%;

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .warningText {
        @extend %flexStart;
        flex-direction: column;
        gap: 10px;
        background-color: var(--color-orange8);
        padding: 15px 10px;
        width: 100%;

        h5 {
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          position: relative;
          padding-right: 20px;

          &::before {
            right: 0px;
            top: 0;
            content: "•";
            color: var(--color-yellow1);
            font-weight: bold;
            display: inline-block;
            width: 16px;
            opacity: 1;
            position: absolute
          }
        }

      }

    }
  }

  .historyBox {
    @extend %flexCenter;
    flex-direction: column;
    background: var(--color-white);
    text-align: center;
    border-radius: 10px;
    direction: rtl;
    padding: 25px;
    width: 100%;
    align-items: flex-start;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);

    h4 {
      font-size: 16px;
      font-weight: 500;
      padding: 10px;
      margin-bottom: 10px;

    }

    .tb_DepoCash {
      max-height: 400px;
      min-height: 300px;

      table {
        min-width: 820px;
        direction: ltr;

        tr {

          th,
          td {
            text-align: center;

            &:nth-child(1) {
              min-width: 60px;
              padding-left: 0;
            }

            &:nth-child(3) {
              min-width: 110px;
            }

            &:nth-child(2),
            &:nth-child(4) {
              min-width: 135px;
            }

            &:nth-child(5) {
              min-width: 80px;
            }

            &:nth-child(6) {
              min-width: 100px;
              padding-right: 0;
            }
          }

        }
      }
    }
  }
}

.withdrawCash .body .left {
  .desText {
    background-color: var(--color-green5);
  }

  .warningText {
    background-color: var(--color-yellow3);
  }
}

.profile {
  @extend %flexStart;
  margin-top: 24px;
  width: 100%;
  align-items: stretch;
  gap: 15px;


  .navProfile {
    background: var(--color-white);
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    padding: 64px 0 16px 0;
    margin-left: auto;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);

    .user {
      @extend %flexCenter;
      flex-direction: column;
      background: var(--color-white);

      .userImg {
        width: 100px;
        height: 100px;

        .iconUser,
        .iconRate {
          width: 100px;
        }

        span {
          width: 30px;
          margin-top: 67px;
          margin-left: -30px;
        }

      }

      h4 {
        font-size: 18px;
        margin: 10px 0;
        color: var(--color-gray3);
        font-weight: 600;
        display: block;
      }

      h5 {
        font-weight: 400;
        color: var(--color-txt4);
        font-size: 12px;
      }

      .confirmed {
        background: var(--color-green);
        color: var(--color-white);
        border: none;
        font-size: 15px;
        padding: 5px 20px;
        transition: 0.3s ease;
        -webkit-border-radius: 800px;
        -moz-border-radius: 800px;
        -ms-border-radius: 800px;
        -o-border-radius: 800px;
        border-radius: 800px;
        cursor: pointer;

        &:hover {
          background: var(--color-blue);
        }
      }
    }

    .nav {
      margin-top: 1.5rem;
      padding: 30px 30px 0 0;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 16px;

      a {
        @extend %flexCenter;
        justify-content: flex-start;
        direction: rtl;
        padding: 15px 0;
        opacity: 0.5;
        cursor: pointer;
        gap: 10px;


        &:active {}
      }

      .active {
        opacity: 1;
        background: var(--color-white3);
        background: linear-gradient(90deg, var(--color-gray1)0%, var(--color-NO) 100%);
      }
    }
  }



}

.accountBank {
  direction: rtl;
  padding: 45px;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);
  background-color: var(--color-white);
  width: 100%;
  padding: 64px 32px 32px 32px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  min-height: calc(100% - 109px);

  .addCard {
    direction: ltr;
    font-size: 13px;
    padding: 13px 25px;
    color: var(--color-textOnlyWhite);
    border: none;
    background: var(--color-orange2);
    margin-bottom: 2rem;
    box-shadow: 0px 8px 41px 0px var(--color-orange9);
    border-radius: 8px;
    transition: 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &:hover {
      background: var(--color-green);
    }
  }

  .allTable {

    .headerTabel,
    .bodyTabel {
      .tRow {
        >div:nth-child(5) {
          flex: 0 0 20%;
          max-width: 20%;
        }

        >div:nth-child(4) {
          flex: 0 0 21%;
          max-width: 21%;
        }

        >div:nth-child(3) {
          flex: 0 0 27%;
          max-width: 27%;
        }

        >div:nth-child(2) {
          flex: 0 0 22%;
          max-width: 22%;
        }

        >div:nth-child(1) {
          flex: 0 0 10%;
          max-width: 10%;
          cursor: pointer;
          transition: 0.3s ease;

          &:hover {
            color: var(--color-blue);
          }
        }

        .cardnum {
          font-size: 12px;
        }


      }


    }


  }

  .tb_CardBank {
    max-height: 400px;
    min-height: 300px;

    table {
      // min-width: 800px;
      // max-width: 800px;

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;

          &.fontCard2 {
            letter-spacing: 0.7px;
          }

          &:last-child {
            min-width: 80px;
            text-align: right;
          }

          &:nth-child(5) {
            // min-width: 35px;
            padding-right: 0;
            // text-align: center;
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
              color: var(--color-blue);
            }
          }

          // &:nth-child(4) {
          //   min-width: 180px;
          // }

          // &:nth-child(3) {
          //   min-width: 190px;
          // }

          // &:nth-child(2) {
          //   min-width: 170px;
          // }

          // &:nth-child(1) {
          //   min-width: 170px;
          //   padding-left: 0;
          // }

          .nameBanck {
            @extend %flexStart;
            gap: 10px;
            justify-content: flex-start;
          }

          .btnControl {
            direction: rtl;
            @extend %flexCenter;
            gap: 8px;
            justify-content: center;

            span {
              padding: 5px 15px;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              font-size: 13px;
            }

            .defaultCard {
              background-color: var(--color-br2);
              color: var(--color-txt4);
              cursor: pointer;
              text-wrap: nowrap;
              white-space: nowrap;

              &.active {
                // background: var(--color-green);
                // color: var(--color-white10);
                background: var(--color-bgDone);
                color: var(--color-textDone);
              }
            }

            .confirm {
              background: var(--color-bgDone);
              color: var(--color-textDone);
              text-wrap: nowrap;
              white-space: nowrap;
            }
          }

        }

      }

      tr th {
        &:nth-child(1) {
          text-align: right;
          padding-right: 50px;
        }
      }
    }
  }


  .loadingCircular {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-blue);
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
    height: 61px;
    width: 100%;
    margin-top: 15px;
  }


}

.changePass {
  background-color: var(--color-white);
  width: 100%;
  // flex: 0 0 75%;
  // max-width: 75%;
  padding: 64px 32px 32px 32px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  height: calc(100% - 109px);

  h3 {
    font-size: 16px;
    font-weight: bold;

    direction: rtl;
    text-align: right;
  }

  .description {
    @extend %flexCenter;
    gap: 30px;
    direction: rtl;
    width: 100%;
    align-items: self-start;

    .item {
      // flex: 0 0 33.333333%;
      width: 33.333333%;
      max-width: 33.333333%;
      direction: rtl;
      text-align: right;


      p {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-gray4);
        margin-bottom: 5px;

        &::before {
          content: "•";
          color: var(--color-red);
          font-weight: bold;
          display: inline-block;
          width: 1em;
        }
      }
    }
  }

  .body {
    @extend %flexCenter;
    direction: rtl;

    .left {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 4%;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .right {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;

      .customInput .inputNumeric {
        direction: ltr;
      }

      .finalCheckBtn {
        padding: 10px 35px;
        font-size: 15px;

        &:hover {
          background: var(--color-orange2) !important;
        }
      }

      .loadingCircular {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 44px;
        background: var(--color-blue);
        border-radius: 13px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        -ms-border-radius: 13px;
        -o-border-radius: 13px;
        margin-top: 27px;
      }
    }
  }
}

.accountUser {
  background-color: var(--color-white);
  width: 100%;
  // flex: 0 0 75%;
  // max-width: 75%;
  padding: 64px 32px 32px 32px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  height: calc(100% - 109px);

  h3 {
    font-size: 16px;
    font-weight: bold;

    direction: rtl;
    text-align: right;
    margin-bottom: 60px
  }

  .body {
    @extend %flexStart;
    width: 100%;
    direction: rtl;

    .box {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 5%;
      padding-right: 15px;

      .inputTextField {
        margin: 32px 0;

        >div {
          width: 100%;
          gap: 5px;

          input {
            width: 100%;
            direction: ltr;
            text-align: right;
          }
        }
      }
    }

  }


  .boxButtonImg {
    @extend %flexCenter;
    width: 100%;
    direction: rtl;

    .right {
      font-size: 15px;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      @extend %flexCenter;
      justify-content: flex-start;
      gap: 15px;

      img {
        margin-left: 2%;
        width: 180px;
        height: 169px;
      }

      >div {
        @extend %flexStart;
        flex-direction: column;
        gap: 8px;


        h5 {
          font-size: 16px;
        }

        p {
          font-size: 13px;
        }
      }
    }

    .left {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      text-align: left;
      padding: 0 15px;

      .finalCheckBtn {
        font-size: 15px;
        padding: 10px 35px;
        width: unset;
        margin-right: auto;
      }
    }
  }

}


.receive {
  @extend %flexCenter;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  margin: 16px auto;
  margin-bottom: 0;
  width: 100%;
  gap: 24px;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);

  .top {
    @extend %flexStart;
    flex-direction: column;
    align-items: center;
    background: var(--color-white);
    border-radius: 10px;
    padding: 25px;
    width: 100%;


    .progress-btn {
      height: 28px;
      max-height: 28px;
      max-width: 150px;
      padding: 0;
      background: var(--color-orange1);
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
      margin-right: auto;
      margin-top: 0;

      .btn {
        line-height: 28px;

        .row {
          @extend %flexCenter;
          gap: 20px;
          font-size: 12px;
        }
      }

      .progress {
        background: var(--color-orange1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }

      &.active {
        box-shadow: none;
        animation: bgcolor-animRed 240s ease 0s;
        -webkit-animation: bgcolor-animRed 240s ease 0s;
      }

      @keyframes bgcolor-animRed {
        0% {
          background-color: var(--color-orange4);
        }

        97% {
          background-color: var(--color-orange4);
        }

        100% {
          background-color: var(--color-orange1);
        }
      }
    }

    .title {
      text-align: center;

      img {
        width: 60px;
        height: 60px;
      }

      p {
        font-size: 14px;
        margin: 15px 0 16px 0;
        color: var(--color-gray4);
      }

      h4 {
        font-size: 16px;
        font-weight: 400;

        b {
          color: var(--color-blue);
        }
      }
    }

    .walletAddress {
      @extend %flexCenter;
      direction: rtl;
      padding: 12px;
      margin-top: 1rem;
      border-radius: 12px;
      border: 1px solid var(--color-white3);
      gap: 5px;

      .qr {
        max-width: 25%;
        flex: 0 0 25%;
        width: 100%;
        padding: 5px;
        margin-bottom: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .address {
        max-width: 75%;
        flex: 0 0 75%;
        width: 100%;
        @extend %flexStart;
        flex-direction: column;
        padding: 5px;

        .text {
          @extend %flexCenter;
          direction: ltr;
          width: 100%;
          justify-content: space-between;
          margin: 10px 0;
          background: var(--color-white5);
          padding: 11px;
          border: 2px solid var(--color-white5);
          transition: 0.5s ease;
          border-radius: 14px;

          &:hover {
            border: 2px solid var(--color-blue);
          }

          >button {
            padding: 6px 13px;
            left: 26px;
            transition: 0.3s ease;
            font-size: 13px;
            background: var(--color-blue);
            color: var(--color-white);
            border-radius: 8px;
            border: none;
            direction: rtl;
            cursor: pointer;

            &:hover {
              background: var(--color-green);
            }

            img {
              width: 15px;
              height: 15px;
              margin-left: 3px;
            }
          }

          h4 {
            font-weight: 400;
            font-size: 16px;
          }

          >div {
            padding: 0 8px;
            @extend %flexCenter;
            gap: 20px;

            p {
              font-size: 12px;
              font-weight: 600;
              color: var(--color-blue);
            }
          }
        }

        .alertText {
          @extend %flexStart;
          gap: 5px;
          background: var(--color-white2);
          padding: 20px;
          border-radius: 13px;

          img {
            width: 40px;
            margin-left: 10px;
          }

          p {
            color: var(--color-orange1);
            text-align: right;
            font-weight: 600;
            width: 85%;
            font-size: 13px;
          }
        }
      }
    }
  }

  .bottom {
    @extend %flexStart;
    flex-direction: column;
    align-items: center;
    background: var(--color-white);
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    direction: rtl;
    position: relative;

    .idOrder {
      @extend %flexCenter;
      gap: 3px;
      font-size: 14px;
      top: 0;
      right: 8%;
      border-radius: 0 0 15px 15px;
      padding: 11px 15px;
      color: var(--color-white);
      position: absolute;
      background: var(--color-blue);
      text-align: center
    }

    .des {
      @extend %flexCenter;
      width: 100%;
      margin-top: 48px;

      .item {
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
        @extend %flexCenter;
        flex-direction: column;
        gap: 6px;
        text-align: right;
        align-items: flex-start;
        padding: 0 15px;

        p {
          font-size: 14px;
          margin-top: 15px;
          color: var(--color-gray4);
          font-weight: 400;
        }

        h4 {
          font-weight: 400;
          font-size: 16px;

        }
      }
    }
  }
}

.send {
  @extend %flexCenter;
  width: 100%;
  direction: rtl;
  gap: 14px;
  margin: 24px auto;
  margin-bottom: 0;
  width: 100%;
  align-items: flex-start;

  .idOrder {
    @extend %flexCenter;
    gap: 3px;
    font-size: 14px;
    top: 0;
    right: 8%;
    border-radius: 0 0 15px 15px;
    padding: 11px 15px;

    position: absolute;
    background: var(--color-white3);
    text-align: center
  }

  .right,
  .left {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    background: var(--color-white);
    border-radius: 13px;
    padding: 15px;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);
  }

  .right {
    @extend %flexCenter;
    flex-direction: column;
    text-align: center;
    padding-top: 63px;
    position: relative;

    .title {
      text-align: center;

      img {
        width: 52px;
        height: 52px;
      }

      p {
        font-size: 14px;
        margin: 10px 0;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--color-green);

        b {
          color: var(--color-blue);
        }
      }
    }


    .textWallet {
      @extend %flexCenter;
      direction: ltr;
      width: 97%;
      justify-content: space-between;
      margin: 10px;
      background: transparent;
      padding: 11px;
      border: 2px solid var(--color-white5);
      transition: 0.5s ease;
      border-radius: 14px;
      cursor: pointer;

      &:hover {
        border: 2px solid var(--color-blue);
      }

      h4 {
        font-weight: 400;
        padding: 0;
        font-size: 12px;
        font-family: "Arial", sans-serif !important;
        padding: 5px;
        padding-left: 20px;
        text-align: center;
      }

      >div {
        padding: 0 8px;
        @extend %flexCenter;
        gap: 40px;

        p {
          font-size: 12px;
          font-weight: 600;
          color: var(--color-blue);
        }
      }
    }

    .des {
      width: 100%;
      margin: 20px 0;

      .item {
        width: 100%;
        @extend %flexCenter;
        justify-content: space-between;

        p {
          flex: 0 0 50%;
          max-width: 50%;
          color: var(--color-txt4);
          margin: 4px 0;
          text-align: right;
          padding: 0 15px;
          direction: rtl;
          font-weight: 400;
        }

        h5 {
          flex: 0 0 50%;
          max-width: 50%;
          text-align: left;
          direction: rtl;
          padding: 0 15px;
          font-weight: 400;
        }
      }
    }

    .statusPay {
      background: var(--color-white11);
      padding: 5px 10px;
      border-radius: 10px;
      width: 100%;

      .item {
        @extend %flexCenter;
        width: 100%;
        justify-content: space-between;
        margin: 13px 0;
        direction: rtl;

        .status {
          text-align: left;
          font-size: 13px;
          border: none;
          background: var(--color-green);
          color: var(--color-white);
          padding: 8px 21px;
          border-radius: 8px;
          cursor: pointer;
        }

        .text {
          @extend %flexCenter;
          justify-content: flex-start;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

  }

  .left {
    @extend %flexCenter;
    flex-direction: column;
    text-align: center;
    padding-top: 72px;
    width: 100%;
    position: relative;

    .iconCoinCurrency {
      width: 100%;
      text-align: center;
      @extend %flexCenter;
      margin-bottom: 48px;

      .itemDetail {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        position: relative;
        padding: 0 15px;

        span {
          background: var(--color-txt4);
          color: var(--color-white);
          position: absolute;
          font-size: 10px;
          padding: 1px 4px;
          margin-right: 13px;
          border: 3px solid;
          margin-top: 51px;
          border-radius: 7px;
          text-align: center;

          &.green {
            background-color: var(--color-green);
          }
        }

        .img {
          border: 1px solid var(--color-white6);
          display: inline-block;
          border-radius: 50%;
          width: 65px;
          height: 65px;
          padding: 2px;

          img {
            width: 59px;
            height: 59px;
          }
        }

        .text {
          margin-top: 16px;

          p {
            font-weight: 600;
            font-size: 17px;
          }

          h6 {
            display: block;
            font-size: 13px;
            font-weight: 400;
            color: var(--color-txt4);
          }
        }
      }

      .itemImg {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;

        img {
          width: 33px;
        }
      }
    }

    .title {
      @extend %flexCenter;
      justify-content: flex-start;
      direction: rtl;
      gap: 10px;
      margin: 15px 0;
      text-align: right;
      width: 100%;


      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-weight: 400;
        font-size: 14px;

      }
    }

    .counterinfo {
      text-align: center;
      border-radius: 12px;
      background: var(--color-white13);
      padding: 18px;
      margin-bottom: 24px;
      width: 100%;

      &.textRight {
        text-align: right;
      }

      p {
        color: var(--color-gray4);
        font-size: 16px;
        font-weight: 400;
      }

      h6 {
        color: var(--color-gray4);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.defaultTicket {
  background-color: var(--color-white);
  padding: 32px;
  padding-top: 48px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  margin: 24px auto;
  margin-bottom: 0;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);

  h3 {
    font-weight: bold;
    font-size: 16px;

    margin-bottom: 24px;
    text-align: right;
  }

  .description {
    @extend %flexStart;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    direction: rtl;

    p {
      font-size: 14px;
      padding-right: 0;
      list-style: none;
      margin-bottom: 5px;
      color: var(--color-gray4);

      &::before {
        content: "•";
        color: var(--color-red);
        font-weight: bold;
        display: inline-block;
        width: 1em;
      }
    }
  }

  h4 {
    color: var(--color-blue);
    font-weight: 700;
    margin-bottom: 24px;
    font-size: 16px;
    border: none;
    background: transparent;
  }

  .accordionBox {
    width: 100%;
    padding: 15px;
    border-radius: 13px;
    background: var(--color-white12);

    .item {
      width: 100%;
      box-shadow: 0px 0px 36px 0px var(--color-blue6);
      margin: 1rem 0;
      border-radius: 13px;
      color: var(--color-txtblue2) !important;
      border: none;
      background: var(--color-white) !important;
    }

    .accordion {
      width: 100%;
      @extend %flexStart;
      flex-direction: column;
      background: var(--color-white);

      .title {
        @extend %flexCenter;
        justify-content: space-between;
        border: none;
        border-radius: 13px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        -ms-border-radius: 13px;
        -o-border-radius: 13px;
        font-weight: 500;
        font-size: 15px;
        color: var(--color-gray4);
        padding: 20px;
        text-decoration: none !important;
        direction: rtl;
        width: 100%;
        transition: 0.3s ease;
        -webkit-transition: 0.3s ease;
        -moz-transition: 0.3s ease;
        -ms-transition: 0.3s ease;
        -o-transition: 0.3s ease;
        cursor: pointer;


        &.open {
          color: var(--color-blue) !important;

          img {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
          }

          div span {
            background: var(--color-blue) !important;
          }
        }

        img {
          width: 18px;
          height: 18px;
        }

        div {
          @extend %flexCenter;
          justify-content: flex-start;
          gap: 15px;

          span {
            text-align: center;
            background: var(--color-gray4);
            width: 20px;
            margin-left: 15px;
            height: 20px;
            color: var(--color-white);
            border-radius: 50%;
            display: inline-block;
            line-height: 20px;
            transition: 0.3s ease;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;
          }
        }
      }

      .des {
        border-top: 1px solid var(--color-white3);
        color: var(--color-gray4);
        font-size: 14px;
        padding: 0 20px;
        //display: none;
        transition: 0.3s ease;
        -webkit-transition: 0.3s ease;
        -moz-transition: 0.3s ease;
        -ms-transition: 0.3s ease;
        -o-transition: 0.3s ease;
        height: 0;
        overflow: hidden;
        min-height: 0px;

        &.open {
          // display: block;
          height: auto;
          padding-top: 20px;
          padding-bottom: 20px;
          min-height: 100px;
        }
      }
    }
  }

  .btnNewTicket {
    width: 100%;
    color: var(--color-white);
    font-size: 14px;
    padding: 13px 30px;
    margin-top: 1rem;
    border-radius: 8px;
    text-align: right;
    cursor: pointer;
    width: 100%;
    background: var(--color-blue1);
    background: linear-gradient(90deg, var(--color-blue1) 0%, var(--color-blue) 100%);
    @extend %flexCenter;
    justify-content: space-between;
    direction: rtl;

    .text {
      @extend %flexCenter;
      gap: 20px;

      img {
        width: 28px;
        height: 28px;
      }
    }


  }
}

.createTicket {
  background-color: var(--color-white);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  margin: 24px auto;
  margin-bottom: 16px;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);
  min-height: calc(100% - 113px);

  .headTitle {
    @extend%flexCenter;
    justify-content: flex-start;
    direction: rtl;
    color: var(--color-white);
    gap: 5px;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    width: 100%;
    background: var(--color-blue1);
    background: linear-gradient(90deg, var(--color-blue1) 0%, var(--color-blue) 100%);
    padding: 18px 15px;
    border-radius: 15px 15px 0 0;
    -webkit-border-radius: 15px 15px 0 0;
    -moz-border-radius: 15px 15px 0 0;
    -ms-border-radius: 15px 15px 0 0;
    -o-border-radius: 15px 15px 0 0;

    img {
      width: 28px;
      height: 28px;
    }

    p {
      font-size: 14px;
      color: var(--color-textOnlyWhite);
    }
  }

  .body {
    padding: 20px;
    direction: rtl;

    .typeTicket {
      @extend%flexCenter;
      gap: 10px;
      padding: 5px;
      margin: 16px 0;
      direction: rtl;

      .w58 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }

      .formControlLogin input {
        border: none !important;
      }

      .divideNone {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }

    .textareaStyle .inputGroup {
      padding: 14px 20px;
      height: 250px;

      textarea {
        resize: none;
      }
    }

    .bottom {
      width: 100%;
      @extend%flexCenter;
      justify-content: space-between;

      .finalCheckBtn {
        max-width: 137px;
        width: 137px;
        font-size: 14px;
        font-weight: 300;
        padding: 10px 35px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        color: var(--color-textOnlyWhite);

        &:hover {
          background: var(--color-orange2) !important;
        }
      }

      .loadingCircular {
        width: 137px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        background: var(--color-blue);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        margin-top: 15px;
      }

      .right {
        display: flex;
        justify-self: flex-start;
        align-items: center;
        gap: 10px;
        direction: rtl;

        .nameFile {
          display: flex;
          justify-self: flex-start;
          align-items: center;
          gap: 10px;

          h5 {
            font-size: 12px;
            font-weight: 400;
            max-width: 300px;
            overflow: hidden;
          }

          span {
            width: 10px;
            height: 10px;
            background-color: var(--color-red7);
            cursor: pointer;
          }

        }


        .selectFile {
          border: 2px solid var(--color-white14);
          background: transparent;
          padding: 10px 35px;
          border-radius: 8px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 300;
          transition: 0.3s ease;
          direction: rtl;

          input[type="file"] {
            display: none;
          }

          div {
            @extend%flexCenter;
            gap: 5px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          &:hover {
            background: var(--color-br2);
          }

        }
      }

    }

  }
}

.historyTicket {
  background-color: var(--color-white);
  padding: 32px;
  padding-top: 48px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  margin: 24px auto;
  margin-bottom: 0;

  h3 {
    font-weight: bold;
    font-size: 16px;

    margin-bottom: 24px;
    text-align: right;
  }

  .description {
    @extend %flexStart;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    direction: rtl;

    p {
      font-size: 14px;
      padding-right: 0;
      list-style: none;
      margin-bottom: 5px;
      color: var(--color-gray4);

      &::before {
        content: "•";
        color: var(--color-red);
        font-weight: bold;
        display: inline-block;
        width: 1em;
      }
    }
  }

  h4 {
    color: var(--color-blue);
    font-weight: 700;
    margin-bottom: 24px;
    font-size: 16px;
    border: none;
    background: transparent;
  }

  .tb_Ticket {
    max-height: 400px;
    min-height: 300px;

    table {
      // min-width: 770px;

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;

          &:last-child {
            min-width: 80px;
            text-align: right;
          }

          // &:nth-child(6) {
          //   min-width: 35px;
          // }

          &:nth-child(5) {
            justify-content: flex-start;
            // min-width: 60px;
          }

          // &:nth-child(4) {
          //   min-width: 60px;
          // }

          // &:nth-child(3) {
          //   min-width: 90px;
          // }

          &:nth-child(2) {
            // min-width: 120px;
            max-width: 160px;
            overflow: hidden;
          }

          &:nth-child(1) {
            // min-width: 170px;
            padding-left: 0;
          }

        }

        td {

          &:nth-child(6) {

            text-align: center;
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
              color: var(--color-blue);
            }
          }

          a {

            cursor: pointer;

            &:active {}

            >svg {

              &:hover {
                color: var(--color-blue);
              }
            }
          }
        }

      }
    }
  }


  .pagination {
    @extend %flexCenter;
    justify-content: flex-start;
    gap: 40px;
    margin-top: 48px;
  }

  .finalCheckBtn {
    max-width: 140px;
    font-size: 15px;
    font-weight: 300;
    padding: 10px 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-top: 0;
    @extend %flexCenter;
    gap: 12px;
  }
}

.chatTicker {
  @extend%flexCenter;
  width: 100%;
  direction: rtl;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  margin: 24px auto;
  margin-bottom: 0;
  align-items: flex-start;
  height: calc(100% - 174px);


  .right {
    max-width: 75%;
    border: 0;
    width: 75%;
    border-radius: 14px;
    margin-bottom: 30px;
    transition: 0.5s;
    margin-left: 20px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-white);
    box-shadow: 0px 0px 52px 0px var(--color-blue10);
    height: calc(100% - 4px);


    .enterMessage {
      width: 100%;
      @extend%flexCenter;
      justify-content: flex-start;
      align-items: stretch;
      direction: rtl;
      padding: 16px;
      border-top: 1px solid var(--color-divider);
      margin-top: auto;

      .textMessage {
        padding: 8px 12px;
        width: 80%;
        border-radius: 0 13px 13px 0;
        background: var(--color-divider);
        @extend%flexCenter;
        gap: 10px;
        justify-content: flex-start;
        // height: 65px;

        img {
          width: 36px;
          height: 30px;
        }

        textarea {
          resize: none;
          height: 50px;
          padding-top: 17px;

          // &::placeholder {
          //   padding-top: 20px;
          // }
        }

        .inputChat {
          width: 100%;
          border: none;
          background-color: transparent;
        }
      }

      .selectFile {
        // height: 65px;
        border-radius: 13px 0 0 13px;
        width: 10%;
        @extend%flexCenter;
        justify-content: center;
        background: var(--color-divider);
        -webkit-border-radius: 13px 0 0 13px;
        -moz-border-radius: 13px 0 0 13px;
        -ms-border-radius: 13px 0 0 13px;
        -o-border-radius: 13px 0 0 13px;

        img {
          margin-left: 20px;
          cursor: pointer;
        }

        input[type="file"] {
          display: none;
        }
      }

      .sendMessage {
        padding: 0 12px;
        width: 10%;
        height: 46px;
        min-width: 66px;
        align-self: center;

        div {
          @extend%flexCenter;
          width: 100%;
          font-size: 14px;
          font-weight: 300;
          border-radius: 12px;
          border: none;
          padding: 4px 15px;
          background: var(--color-blue);
          height: 100%;
          transition: 0.3s ease;
          cursor: pointer;
          text-align: center;

          &:hover {
            background: var(--color-green);
          }
        }

      }
    }

    .chatList {
      touch-action: auto;
      overflow: hidden !important;
      overflow-y: auto !important;
      min-height: 400px !important;
      height: 100%;
      position: relative;
      padding: 16px 12px;
      max-height: 760px;

      .titleTime {
        max-width: 100%;
        width: 100%;
        border-bottom: 1px solid var(--color-grayLine);
        position: relative;
        margin: 24px 0;

        p {
          background: var(--color-white);
          padding: 10px;
          font-size: 14px;
          font-weight: 400;
          color: var(--color-text2);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
        }
      }

      .itemAdmin {
        @extend%flexStart;
        margin: 5px 0;
        max-width: 55%;
        width: 55%;
        margin-right: auto;
        direction: rtl;
        align-items: flex-end;
        justify-content: flex-end;

        >img {
          width: 36px;
          height: 36px;
          margin: 0 8px
        }

        .body {
          @extend%flexStart;
          flex-direction: column;
          width: 100%;

          .time {
            width: 100%;
            text-align: right;
            color: var(--color-textSlider);
            direction: rtl;
            font-size: 13px;
            margin: 4px;
            opacity: 0.8;

          }

          .boxText {
            @extend%flexStart;
            width: 100%;
            flex-direction: column;
            gap: 5px;
            direction: rtl;
            text-align: right;
            background-color: var(--color-bg3);
            border-radius: 9px;
            padding: 13px;
            min-width: 100px;

            >div {
              @extend%flexCenter;
              direction: rtl;
              gap: 3px;
              font-size: 13px;

              img {
                width: 13px;
                height: 13px;
              }

              h5 {
                font-weight: 600;
                color: var(--color-blue);
              }
            }

            p {
              font-size: 13px;
              width: 100%;
              color: var(--color-gray8);
              padding-top: 0;
            }

            .boxImg {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 10px;
              flex-direction: column;
              width: 100%;

              >svg {
                cursor: pointer;
              }

              img {
                width: 200px;
                max-height: 200px;
              }

            }
          }
        }
      }

      .itemUser {
        @extend%flexStart;
        margin: 5px 0;
        max-width: 55%;
        width: 55%;
        margin-left: auto;
        direction: rtl;
        align-items: flex-start;
        flex-direction: column;

        .time {
          width: 100%;
          text-align: right;
          color: var(--color-textSlider);
          direction: rtl;
          font-size: 13px;
          margin: 4px;
          opacity: 0.8;

        }

        .boxText {
          @extend%flexStart;
          flex-direction: column;
          gap: 5px;
          direction: rtl;
          text-align: right;
          float: right;
          clear: right;
          background-color: var(--color-white22);
          color: var(--color-gray8);
          border-radius: 9px;
          padding: 13px;

          p {
            font-size: 13px;
            width: 100%;
            color: var(--color-gray8);
            padding-top: 0;
          }
        }

        .boxImg {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          flex-direction: column;
          min-width: 100px;

          >svg {
            cursor: pointer;
          }

          img {
            width: 200px;
            max-height: 200px;
          }

        }
      }

      .imgSmall {
        width: auto !important;
        height: 60px !important;
        cursor: pointer;
      }

    }

  }

  .left {
    max-width: 25%;
    width: 25%;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);
    height: calc(100% - 81px);

    .headTitle {
      @extend%flexCenter;
      justify-content: space-between;
      direction: rtl;
      color: var(--color-white);
      gap: 5px;
      font-size: 14px;
      text-align: right;
      cursor: unset;
      width: 100%;
      background: var(--color-blue1);
      background: linear-gradient(90deg, var(--color-blue1) 0%, var(--color-blue) 100%);
      padding: 18px 15px;
      border-radius: 15px 15px 0 0;
      -webkit-border-radius: 15px 15px 0 0;
      -moz-border-radius: 15px 15px 0 0;
      -ms-border-radius: 15px 15px 0 0;
      -o-border-radius: 15px 15px 0 0;

      div {
        @extend%flexCenter;
        justify-content: space-between;
        direction: rtl;
        color: var(--color-white);
        gap: 5px;
        font-size: 14px;
        text-align: right;
      }

      img {
        width: 28px;
        height: 28px;
      }

      p {
        font-size: 13px;
        color: var(--color-textOnlyWhite);
      }

      h5 {
        font-size: 12px;
        font-weight: 400;
        max-width: 140px;
        overflow: hidden;
        color: var(--color-textOnlyWhite);

      }
    }

    .body {
      text-align: right;
      background: var(--color-white);
      padding-bottom: 16px;
      direction: rtl;
      border-radius: 0 0 15px 15px;
      -webkit-border-radius: 0 0 15px 15px;
      -moz-border-radius: 0 0 15px 15px;
      -ms-border-radius: 0 0 15px 15px;
      -o-border-radius: 0 0 15px 15px;
      height: 100%;

      .item {
        @extend%flexCenter;
        justify-content: flex-start;
        direction: rtl;
        padding: 15px 20px;
        gap: 5px;

        .imgborder {
          padding: 2px;
          width: 50px;
          height: 50px;
          border-radius: 50%;

          img {
            width: 46px;
            height: 46px;
          }
        }

        .text {
          @extend%flexStart;
          flex-direction: column;
          gap: 3px;

          h4 {
            font-size: 12px;
            color: var(--color-text3);
            // color: var( --color-txt4);
          }

          p {

            font-weight: 600;
            font-size: 14px;
            display: block;
            max-width: 135px;
            text-wrap: wrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}


.OrderPro {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 4.5px;

  .head {
    background: var(--color-white);
    padding: 0px 10px;
    // gap: 20px !important;

    &.headTop {
      padding: 20px 40px;
      // padding: 20px 100px;
      // padding-right: 40px;
    }

    .btnMenuDrawer {
      display: none;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .bodyBox,
  .head {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4.5px;
    max-width: 100%;
    margin-bottom: 11px;

    .centerBox {
      flex: 1 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .mapChart {
        width: 100%;
        height: 500px;
        margin-bottom: 21px;
        position: relative;

        .iframeTradingViewWidget {
          width: 100%;
          height: 100%;
          border: none;
        }

        .fullScreen {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 10px var(--color-text2);
          bottom: 20px;
          right: 20px;
          background: var(--cBox-coin);
          width: 50px;
          height: 50px;
          cursor: pointer;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          z-index: 100;

          &:hover {
            background: var(--color-br1);

            span {
              background-color: var(--color-black3);
            }
          }
        }
      }

      .btnChangeType {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        width: 100%;
        margin-bottom: 21px;
        border-bottom: 1px solid var(--color-divider2);

        .item {
          max-width: 92px;
          background-color: transparent;
          text-align: center;
          border: none;
          color: var(--txBody-color);
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          position: relative;
          padding: 0 15px;
          padding-bottom: 4px;
          cursor: pointer;

          &.active {
            color: var(--color-title);

            &::after {
              position: absolute;
              content: "";
              width: 70%;
              border-bottom: 1px solid var(--color-title);
              bottom: -1px;
              right: 15%;
            }

          }

        }
      }

      .CreateOrderBox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        background: var(--color-white);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 15px;
        width: 100%;
        direction: rtl;
        flex: 1 0 auto;

        .changePrice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          width: 100%;
          direction: rtl;

          .btns {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            .item {
              padding: 5px 11px;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              border: none;
              background: transparent;
              color: var(--color-txt4);
              font-size: 13px;
              font-weight: 500;
              text-align: center;
              cursor: pointer;

              &.active {
                color: var(--color-title);
                background: var(--color-Boxblue1);
              }
            }
          }

          .learningOrderEx {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              font-size: 12px;
              font-weight: 500;
              text-align: right;
              color: var(--color-txt4);
              margin-left: 8px;

              &.p2 {
                display: none;
              }
            }

            a {
              font-size: 12px;
              font-weight: 500;
              text-align: right;

              cursor: pointer;
            }
          }
        }

        .buyAndSellBox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 30px;
          direction: rtl;
          margin-top: 12px;

          .boxBS {
            width: 50%;
            max-width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            direction: rtl;
            flex-direction: column;

            .titleBS {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              width: 100%;
              margin-bottom: 15px;

              h3 {
                color: var(--color-text);
                font-size: 22.36px;
                font-weight: 600;
                line-height: 29.81px;
                text-align: right;
                text-wrap: nowrap;
                white-space: nowrap;
              }

              >div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 4px;

                p {
                  color: var(--color-text);
                  font-size: 11px;
                  // font-weight: 600;
                  text-align: right;
                  position: relative;
                  top: 1px;
                  text-wrap: nowrap;
                  white-space: nowrap;
                }

                img {
                  position: relative;
                  top: -2px;
                }
              }

            }

            .styleP {
              p {
                font-size: 14px !important;
                font-weight: 400 !important;
                color: var(--color-txt4);
              }
            }

            .availableAmount {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              border: 1px solid var(--color-white14);
              border-radius: 10px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              width: 100%;
              padding: 14px;

              &.center {
                justify-content: center;
                height: 56.5px;
              }

              p {
                font-size: 14px;
                font-weight: 400;
                color: var(--color-txt4);
              }
            }

            .sliderBS {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              margin-bottom: 17px;
            }

            .submitBS {
              width: 100%;
              text-align: center;
              padding: 15px 30px;
              color: var(--color-textOnlyWhite);
              border-radius: 3px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              background: var(--color-green);
              border: none;
              margin-top: 12px;
              cursor: pointer;
              transition: 0.3s ease;
              -webkit-transition: 0.3s ease;
              -moz-transition: 0.3s ease;
              -ms-transition: 0.3s ease;
              -o-transition: 0.3s ease;

              &:hover {
                background: var(--color-green1);
              }

              &.sell {
                background: var(--color-red1);

                &:hover {
                  background: var(--color-red2);
                }
              }

            }


            .loadingCircular {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 51px;
              color: var(--color-textOnlyWhite);
              border-radius: 3px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              background: var(--color-green);
              margin-top: 12px;


              &.sell {
                background: var(--color-red1);
              }

            }

            .priceFee {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 8.5px 14.5px;
              gap: 15px;
              margin-top: 10px;

              p {
                font-size: 13px;
                font-weight: 400;
                color: var(--color-black2);
              }

              h5 {
                font-size: 13px;
                font-weight: 400;
                color: var(--color-text);
                font-family: YekanBakh !important;
              }

            }

            .priceLimit {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 15px;
              margin-top: -3px;
              margin-bottom: -3px;

              p {
                font-size: 11px;
                font-weight: 400;
                color: var(--color-red);
              }

              h5 {
                font-size: 12px;
                font-weight: 400;
                color: var(--color-red);
                font-family: YekanBakh !important;
              }

            }

            .bestAmount {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;

              p {
                font-size: 13px;
                font-weight: 400;

                b {
                  font-weight: 400;
                  margin-right: 3px;
                }
              }
            }

            .customInput {
              justify-content: space-between;

              .inputNumeric {
                width: 100px;
                flex: 1;
              }
            }
          }
        }

      }

      .responsOrderBook {
        display: none;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        background: var(--color-white);
        margin-bottom: 10px;
        padding-bottom: 10px;

        .hoverOrderBook {
          display: none;
        }

        .tbOpenOrderList {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;
          width: 100%;

          .loadingBook {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 250px;
          }

          .head {
            padding: 10px;
          }

          .body {
            height: 250px;
            overflow: auto;
          }

          .head,
          .body {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            .arrowOrderBook {
              padding-left: 3px !important;
              position: relative;

              &::after {
                position: absolute;
                top: 50%;
                left: -3px;
                content: "⯈";
                font-size: 16px;
                color: var(--color-arrowOrderBook);
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
              }
            }

            .tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 5px;
              width: 100%;
              position: relative;
              padding: 6px 4px;

              &:hover {
                background: var(--color-hoverNav);
              }

              .progOrder {
                position: absolute;
                width: 50%;
                height: 100%;
                background: var(--color-orange10);
                opacity: 15%;
                right: 0;
                top: 0;
              }


              .th,
              .td {
                z-index: 9;

                p {
                  color: var(--color-txt4);
                  font-size: 12px;
                  font-weight: 500;
                  text-align: right;
                  direction: rtl;
                }

                &:nth-child(1) {
                  // width: 65px;
                  margin-right: 7px;
                }

                &:nth-child(2) {
                  // width: 65px;
                }

                &:nth-child(3) {
                  // width: 75px;

                  p {
                    text-align: center;
                  }
                }
              }

              .th {
                &:nth-child(3) {
                  text-wrap: nowrap;
                  white-space: nowrap;
                }

                p {
                  text-wrap: nowrap;
                  white-space: nowrap;
                  letter-spacing: -0.6px;
                }
              }
            }
          }
        }
      }
    }

    .pairSelect {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      gap: 8px;
      width: 216px;
      flex: 0 0 216px;
      margin-left: 12px;

      .imgPair {
        width: 55px;
        min-width: 55px;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;

        img {
          width: 32px;
          height: 32px;
        }

        .coin {
          position: absolute;
          top: 0px;
          right: 5px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }

      .namePair {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        h3 {
          color: var(--color-black);
          font-size: 20px;
          font-weight: 600;
          text-align: right;
          line-height: 25px;
        }

        p {
          color: var(--color-txt4);
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          text-wrap: nowrap;
          white-space: nowrap;
        }
      }

      .arrowSelecting {
        cursor: pointer;
      }
    }

    .userProfile {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 222px;
      flex: 0 0 222px;
    }

    .headerPriceShow {
      flex: 1 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 16px;
      direction: rtl;

      .divider {
        width: 1px;
        background-color: var(--color-white23);
      }

      .showPrice {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        direction: rtl;
        gap: 4px;
        // width: 140px;
        min-width: 140px;

        >div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 4px;
          direction: rtl;

          p {
            color: var(--color-txt4);
            font-size: 12px;
            font-weight: 400;
            text-align: right;
            direction: rtl;
          }
        }

        h4 {
          font-size: 21px;
          font-weight: 600;
          text-align: left;
          color: var(--color-green);
        }
      }

      .decPrice {
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
        flex-direction: column;
        direction: rtl;
        gap: 4px;
        // width: 165px;
        // min-width: 165px;
        min-width: 140px;
        border-right: 1px solid var(--color-divider4);
        padding-right: 20px;

        >div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          direction: ltr;

          p {
            color: var(--color-txt4);
            font-size: 12px;
            font-weight: 400;
            text-align: right;
            direction: rtl;
          }
        }

        h5 {
          font-size: 14px;
          font-weight: 600;
          text-align: right;
          direction: ltr;

          b {
            opacity: 0.8;
            font-weight: 400;
            margin-right: 3px;
          }

        }
      }
    }

    .priceLastOrder {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 8px 2px 13px 2px;
      min-width: 295px;
      max-width: 350px;
      flex: 1;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      background-color: var(--color-white);

      .btnChange {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        width: 100%;

        .item {
          width: 50%;
          max-width: 50%;
          background-color: transparent;
          text-align: center;
          border: none;
          color: var(--txBody-color);
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          position: relative;
          padding-bottom: 6px;
          cursor: pointer;

          &::after {
            position: absolute;
            content: "";
            width: 80%;
            border-bottom: 1px solid var(--color-NO);
            bottom: 0;
            right: 10%;
          }

          &.active {
            color: var(--color-title);

            &::after {
              position: absolute;
              content: "";
              width: 80%;
              border-bottom: 1px solid var(--color-title);
              bottom: 0;
              right: 10%;
            }
          }

        }
      }

      .priceBox {
        padding: 14.9px;
        padding-left: 0px;
        padding-bottom: 0;
        width: 100%;
        max-width: 100%;

        .filteSymbol {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          gap: 5px;

          .item {
            width: 25%;
            text-align: center;
            max-width: 25%;
            border-radius: 4.5px;
            -webkit-border-radius: 4.5px;
            -moz-border-radius: 4.5px;
            -ms-border-radius: 4.5px;
            -o-border-radius: 4.5px;
            background: var(--color-white);
            cursor: pointer;

            &.favorite {
              width: 30px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.active {
                background: var(--color-Boxblue1);
                padding: 0;
              }
            }

            p {
              font-size: 13px;
              font-weight: 400;
              color: var(--color-txt4);
            }


            &.active {
              background: var(--color-Boxblue1);
              padding: 5.5px 11px;

              p {
                color: var(--color-title);
              }
            }

          }
        }

        .searcBox {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          border: 1.86px solid var(--color-white23);
          padding: 9px 15px;
          margin-top: 12px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          margin-left: 10px;


          img {
            width: 18.5px;
            height: 18.5px;
            cursor: pointer;
          }

          input {
            flex: 1 0 auto;
            background-color: transparent;
            border: none;
            font-size: 14px;


            &::placeholder {
              font-size: 12px;
              font-weight: 400;
              text-align: right;
              color: var(--color-txt4);
            }
          }
        }

        .tbPrice {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          margin-top: 15px;
          padding-bottom: 10px;
          margin-bottom: 21px;

          .head,
          .body {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 12px;
            width: 100%;

            .tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 2px;
              width: 100%;
              cursor: pointer;
              padding: 5px 0;
              padding-left: 5px;

              &.active {
                background: var(--color-bg2);

                .ta1 {
                  color: var(--txBody-color);
                }
              }

              &:hover {
                background: var(--color-bghoverNav);
              }



              .th,
              .td {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                direction: rtl;

                .arrow {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-direction: column;
                  margin-right: 4px;

                  span {
                    cursor: pointer;
                  }
                }

                p {
                  color: var(--color-txt4);
                  font-size: 13px;
                  // font-weight: 500;
                  text-align: right;
                  direction: ltr;

                  &.tx1 {
                    word-break: break-all;
                  }
                }


                .imgPair {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  position: relative;
                  width: 17px;

                  img {
                    width: 10px;
                    height: 10px;
                  }

                  .coin {
                    position: absolute;
                    top: -5px;
                    right: 0;
                  }
                }



                &:nth-child(1) {
                  width: 120px;

                  .starBox {
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    margin-left: 3px;
                    position: relative;
                    top: -2px;
                  }

                  .symboleCoin {
                    width: 18px;
                    height: 18px;
                    margin: 0 3px;
                  }
                }

                &:nth-child(2) {
                  width: 72px;
                }

                &:nth-child(3) {
                  width: 40px;
                }
              }

              .th {
                &:nth-child(1) {
                  width: 100px;
                }

                &:nth-child(2) {
                  justify-content: right;
                }

                &:nth-child(3) {
                  width: 50px;
                }
              }

              .td {
                p {


                  b {
                    color: var(--color-txt4);
                    font-weight: 400;
                  }
                }
              }
            }
          }

          .body {
            height: 386px;
            overflow: auto;
            overflow-x: hidden;
            // padding-left: 8px;
          }
        }

        .titleList {
          font-size: 13.04px;
          font-weight: 600;
          text-align: right;
          color: var(--color-txt4);
        }

        .tbOrderList {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          margin-top: 15px;
          padding-bottom: 10px;
          margin-bottom: 21px;
          padding-left: 8px;

          .head,
          .body {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 12px;
            width: 100%;

            .tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 5px;
              width: 100%;

              .th,
              .td {

                p {
                  color: var(--color-txt4);
                  font-size: 12px;
                  font-weight: 500;
                  text-align: right;
                  direction: rtl;
                }

                &:nth-child(1),
                &:nth-child(2) {
                  width: 76px
                }

                &:nth-child(3) {
                  width: 45px
                }
              }

              .td {
                p {}
              }

              .th {
                &:nth-child(2) {
                  p {
                    letter-spacing: -0.5px;
                  }
                }
              }
            }
          }
        }

      }

    }

    .openOrderNow {
      min-width: 270px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 13px 7px;
      padding-bottom: 0;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      background-color: var(--color-white);

      .opratorControl {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        padding-left: 8px;
        // padding-right: 15px;

        .selectBox {
          width: unset;
          padding: 0;
          border: none;
          background: var(--color-bg1);
          border: none;
          direction: ltr;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding: 7px 9px;

          .textViewBox {
            gap: 10px;
            justify-content: flex-start;
          }


          .seletcing {
            color: var(--txBody-color);
            font-size: 12px;
            font-weight: 400;
          }

        }

        .boxImgs {
          align-self: flex-end;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 4px;

          .itemImg {
            padding: 10px;
            cursor: pointer;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-border-radius: 4px;

            &.active {
              background: var(--color-bg1);
            }
          }
        }



      }

      .loadingBook {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 570px;
      }

      .tbOpenOrderList {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        margin-top: 15px;
        padding-bottom: 10px;
        // overflow: hidden;

        .body {
          height: 570px;
          // overflow: hidden;
        }



        .head,
        .body {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          // gap: 10px;
          width: 100%;

          .arrowOrderBook {
            position: relative;

            &::after {
              position: absolute;
              top: 50%;
              left: -8px;
              content: "⯈";
              font-size: 16px;
              color: var(--color-arrowOrderBook);
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
            }
          }

          &.columnReverse {
            flex-direction: column-reverse !important;
            align-items: flex-end;
            justify-content: flex-end;

            .tr {
              border-top: none;
              border-bottom: 1px dashed #00000000;
            }
          }

          .tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            width: 100%;
            position: relative;
            padding: 5px 0;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            border-top: 1px dashed #00000000;

            .hoverOrderBook {
              position: absolute;
              right: -235px;
              width: 230px;
              height: 110px;
              display: none;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 10px;
              background: var(--color-bghoverOrderBook);
              border-radius: 10px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              padding: 10px;

              .itemHover {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
                width: 100%;

                p {
                  color: var(--bgBody-color);
                  font-size: 14px;
                }
              }

              .itemHover:first-child {
                padding-bottom: 8px;
                border-bottom: 1px solid var(--color-divider5);
                padding-bottom: 5px;
              }
            }

            .progOrder {
              position: absolute;
              width: 50%;
              height: 100%;
              background: var(--color-orange10);
              opacity: 15%;
              right: 0;
              top: 0;

              &.priceChanged {
                opacity: 30%;
              }
            }

            .th,
            .td {
              z-index: 9;

              p {
                color: var(--color-txt4);
                font-size: 12px;
                // font-weight: 500;
                text-align: right;
                direction: rtl;
              }

              &:nth-child(1) {
                width: 73px;
                margin-right: 7px;
              }

              &:nth-child(2) {
                width: 73px;
              }

              &:nth-child(3) {
                width: 87px;

                p {
                  text-align: center;
                }
              }
            }

            .th {
              &:nth-child(3) {
                text-wrap: nowrap;
                white-space: nowrap;
              }

              p {
                text-wrap: nowrap;
                white-space: nowrap;
                letter-spacing: -0.6px;
              }
            }

            .td {
              p {}
            }
          }
        }

        .body {
          &.columnReverse {
            .tr {
              &:hover {
                background: var(--color-hoverNav);
                border-top: none;
                border-bottom: 1px dashed var(--txBody-color);
              }
            }
          }

          .tr {
            &:hover {
              background: var(--color-hoverNav);
              border-top: 1px dashed var(--txBody-color);

              .hoverOrderBook {
                display: flex;
              }
            }

            &:hover~.tr {
              background: var(--color-hoverNav);
            }
          }
        }

      }


      .priceNow {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 1px 15px;
        margin: 11px auto;
        direction: ltr;

        h4 {
          font-size: 14px;
          font-weight: 500;
          text-align: left;

          text-wrap: nowrap;
          white-space: nowrap;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          text-align: right;
          color: var(--color-green3);
        }

        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4p;
        }
      }

    }

  }

  .bodyBox {
    position: relative;
    padding-right: 118px;

    .disPlayNavbar {
      position: absolute !important;
      z-index: 100;
      height: 100% !important;
      min-width: 108px;
      text-align: center;
      display: flex;
      justify-content: center;
      box-shadow: none;

      .boxNav .body .btBackClose {
        display: none;
      }

      .boxNav .body {
        margin-top: 93px;

        .logoTop {
          width: 88px;
          height: 85px;
          position: absolute;
          padding: 0;
          right: 15px;
          top: 0;
          transition: 0.4s ease;
          -webkit-transition: 0.4s ease;
          -moz-transition: 0.4s ease;
          -ms-transition: 0.4s ease;
          -o-transition: 0.4s ease;
        }
      }
    }

    &.forceShowNav {
      .disPlayNavbar {
        display: flex !important;
      }
    }

    .skeletonMarket {
      width: 100%;
      height: 800px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      .skItem1 {
        width: 20%;
        height: 100%;
        min-width: 260px;
      }

      .skItem2 {
        width: 78%;
        height: 100%;
      }

      .width_100d {
        height: 100%;
      }
    }

  }

  .historyOrderPro {
    padding: 25px 48px 24px 47px;
    direction: rtl;
    text-align: right;
    width: 100%;
    max-width: 100%;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-top: 25px;

    .btnFilter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;

      .item {
        padding: 5px 16px;
        background: var(--color-bg1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 4px;

        &.active {
          background: var(--color-blue);

          p {
            color: var(--color-textOnlyWhite);
          }
        }

        p {
          color: var(--color-black5);
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;

          text-align: center;

        }

        img {
          width: 24px;
          height: 24px;
        }
      }

    }

    .tb_OrderPro {
      max-height: 400px;
      min-height: 300px;

      table {
        // min-width: 1180px;

        tr {

          th,
          td {
            text-align: center;
            text-wrap: nowrap;
            white-space: nowrap;

            b {
              font-weight: 400;
              font-size: 13px;
            }

            // &:nth-child(1) {
            //   padding-right: 0;
            //   min-width: 102px;
            // }

            &:nth-child(1) {
              min-width: 135px;
            }

            &:nth-child(2) {
              min-width: 120px;
            }

            &:nth-child(3) {
              min-width: 97px;
            }

            &:nth-child(4) {
              min-width: 67px;
            }

            &:nth-child(5) {
              min-width: 155px;
              direction: ltr;

              b {
                margin-left: 2px;
              }
            }

            &:nth-child(6) {
              min-width: 135px;
              direction: ltr;

              b {
                margin-left: 2px;
              }
            }

            // &:nth-child(8) {
            //   min-width: 135px;
            // }
            &:nth-child(7) {
              min-width: 145px;
              direction: ltr;

              b {
                margin-left: 2px;
              }
            }

            &:nth-child(8) {
              min-width: 85px;
            }

            &:nth-child(9) {
              min-width: 70px;
            }

            .btnDetail {
              min-width: 50px;
              text-align: center;
            }

          }

          #name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;

            img {
              width: 20px;
              height: 20px;
            }

            .imgPair {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              position: relative;
              width: 27px;

              img {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
              }

              .coin {
                position: absolute;
                top: -5px;
                right: 0;
              }
            }


            h5 {
              font-size: 12px;
              font-weight: 600;
              line-height: 24px;
              text-align: right;
              color: var(--color-text);
            }

            p {
              font-size: 12px;
              font-weight: 300;
              line-height: 24px;

              text-align: right;
              color: var(--color-gray11);
            }
          }

          #cancleOrder {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            direction: rtl;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            border: 1px solid #00000000;
            padding: 4px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            &:hover {
              opacity: 0.7;
              border: 1px solid var(--color-red7);
            }

            img {
              width: 18px;
              height: 18px;
            }

            p {
              font-size: 12px;
              font-weight: 600;
              line-height: 24px;

              text-align: right;
              color: var(--color-red7);
            }
          }

          #complete,
          #pending,
          #reject,
          #waiting,
          #cancel,
          #checking {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: var(--color-textOnlyWhite);
            background: var(--color-green);
            padding: 3px 7px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }

          #pending {
            // background: var(--color-yellow);
            background: var(--color-yellow7) !important;
          }

          #waiting {
            color: var(--color-textOnlyWhite) !important;
            background: var(--color-yellow7) !important;
          }

          #checking {
            background: var(--color-white14);
          }

          #reject,
          #cancel {
            background: var(--color-red1);
          }
        }
      }
    }

  }

}

.orderProNothing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 200px 50px;

  .notfound {
    position: unset !important;
    transform: unset !important;
    -webkit-transform: unset !important;
    -moz-transform: unset !important;
    -ms-transform: unset !important;
    -o-transform: unset !important;
  }

}

.sendNew {
  @extend %flexStart;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  flex: 0 0 100%;
  margin: 16px auto;
  margin-bottom: 0;
  // padding-bottom: 15px;

  .cashCardAlert {
    width: 100%;
    font-size: 13px;
    color: var(--color-red2);
    margin-bottom: 28px;
    margin-top: 10px
  }

  .bodyBox {
    @extend %flexStart;
    direction: rtl;
    width: 100%;
    gap: 30px;
    align-items: flex-start;
    flex-direction: column-reverse;

    .rightBox,
    .leftBox {
      @extend %flexStart;
      flex-direction: column;
      text-align: right;
      width: 100%;
      max-width: 100%;
      direction: rtl;

      .title {
        @extend %flexStart;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-txt4);
        margin-bottom: 15px;
        gap: 8px;

        b {

          font-size: 16px;
        }

        img {
          width: 32px;
          height: 32px;

        }
      }

      .box {
        @extend %flexStart;
        flex-direction: column;
        background: var(--color-white);
        border-radius: 10px;
        padding: 25px;
        width: 100%;
        direction: rtl;
        box-shadow: 0px 0px 52px 0px var(--color-blue10);
        height: 100%;
      }

    }

    .rightBox {
      .box {
        .text {
          font-size: 14px;
          font-weight: 400;


          b {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-gray3);
          }
        }

        .listNetwork {
          @extend %flexCenter;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;
          margin-bottom: 24px;
          width: 100%;

          .networkItem {
            @extend%flexCenter ;
            justify-content: space-between;
            padding: 15px;
            direction: rtl;
            gap: 10px;
            width: 100%;
            border: 1px solid var(--color-white14);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            cursor: pointer;

            &:hover {
              background-color: var(--color-white3);
            }


            p {
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
            }

            .imgNetwork {
              @extend %flexCenter;
              justify-content: flex-start;
              gap: 10px;


              >img {
                width: 30px;
                height: 30px;
              }

              >div {
                @extend %flexCenter;
                align-items: flex-start;
                flex-direction: column;
              }
            }

            .nameNetwork {
              @extend %flexCenter;
              align-items: flex-start;
              flex-direction: column;
            }

            .feeNetwork {
              @extend %flexCenter;
              align-items: flex-start;
              flex-direction: column;

              p {
                color: var(--color-txt4);

                b {

                  font-weight: 400;
                }
              }
            }
          }

        }

        .amountSend {
          @extend%flexCenter;
          justify-content: space-between;
          width: 100%;
          background-color: var(--color-white25);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          padding: 5px 10px;
          gap: 30px;
          margin-bottom: 15px;

          >div {
            @extend%flexCenter;
            gap: 4px;
          }

          >div:has(input) {
            flex: 1 0 auto;

            input {
              flex: 1 0 auto;
              border: none;
              background-color: transparent;
              font-size: 14px;

              font-weight: 400;
            }
          }



          p {
            font-size: 14px;

            text-wrap: nowrap;
            white-space: nowrap;
            display: inline;
          }

          button {
            font-size: 14px;

            text-wrap: nowrap;
            white-space: nowrap;
            display: inline;
            background-color: var(--color-white6);
            padding: 5px 10px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            transition: 0.3s ease;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;
            cursor: pointer;
            border: none;
            background-color: transparent;

            &:hover {
              background-color: var(--color-white3);
            }
          }
        }

        .finalDetail {
          @extend %flexCenter;
          justify-content: space-between;
          width: 100%;
          gap: 5px;
          margin-bottom: 15px;

          p {
            width: 33%;
            max-width: 33%;
            padding: 10px 5px;
            font-weight: 400;
            background-color: var(--color-txt4);
            color: var(--color-white);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            font-size: 11px;
            text-align: center;
          }
        }

        .submitBtn {
          width: 100%;
          padding: 10px 25px;
          background-color: var(--color-blue);
          color: var(--color-white);
          transition: 0.03s ease;
          -webkit-transition: 0.03s ease;
          -moz-transition: 0.03s ease;
          -ms-transition: 0.03s ease;
          -o-transition: 0.03s ease;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: var(--color-green)
          }
        }
      }

      .chooseNetwork {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        .networkItem {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin-bottom: 15px;


          h4 {
            font-size: 16px;
            font-weight: 600;

            position: relative;
            padding: 10px 30px;
            cursor: pointer;

            &:hover {
              background-color: var(--color-white3);
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
            }

            &.active {
              color: var(--color-orange2);
              background-color: var(--color-white);
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;

              &::before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 20px solid var(--color-white);
                position: absolute;
                bottom: 0;
                //left: 0;
                transform: translate(-50%, 115%);
                -webkit-transform: translate(-50%, 115%);
                -moz-transform: translate(-50%, 115%);
                -ms-transform: translate(-50%, 115%);
                -o-transform: translate(-50%, 115%);
              }

            }


            &::after {
              content: "•";
              position: absolute;
              right: 10px;
              top: 10px;
            }
          }
        }
      }

      .boxReceive {
        display: flex;
        justify-content: center;
        align-content: flex-start;
        gap: 30px;
        direction: rtl;
        width: 100%;
        background: var(--color-white);
        border-radius: 10px;
        padding: 25px;
        direction: rtl;
        box-shadow: 0px 0px 52px 0px var(--color-blue10);


        .qrCode {
          width: 250px;
          flex: 0 0 250px;
          max-width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;

          // img {
          //   width: 230px;
          //   height: 230px;
          // }
        }

        .dec {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          direction: rtl;
          flex-direction: column;
          width: 100%;
          max-width: 100%;

          .text {
            font-size: 14px;
            font-weight: 400;

            margin-top: 15px;

            b {
              font-size: 14px;
              font-weight: 400;
              color: var(--color-gray3);
            }
          }

          .addressShow {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            padding: 12px 20px;
            height: 53px;
            background-color: var(--color-white3);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            margin-top: 15px;

            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
            }

            h4 {
              flex: 1 0 auto;
              font-size: 15px;
              font-weight: 400;
              color: var(--color-txtblue2);
              text-align: left;
            }
          }

          .warning {
            color: var(--color-orange2);
            font-size: 12px;
            font-weight: 400;
            margin-top: 10px;
          }
        }
      }
    }

    .leftBox {
      direction: rtl;

      .box {
        .showCoin {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;

          img {
            width: 24px;
            height: 24px;
          }

          h4 {
            flex: 0;
            font-size: 14px;
            font-weight: 400;

            position: relative;
            top: 2px;
          }
        }
      }

      .infoSelect {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: 100%;

        .imgCoin {
          img {
            width: 62px;
            height: 62px;
          }

          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          // background-color: var(--color-orange2);
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          overflow: hidden;
        }

        .text {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;

            h4 {
              font-size: 16px;
              font-weight: 400;
              color: var(--color-txtblue2);
            }

            p {
              font-size: 14px;
              font-weight: 400;
              background-color: var(--color-white3);
              // padding: 0 2px;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              -ms-border-radius: 2px;
              -o-border-radius: 2px;

              // position: relative;
              // top: -2px;
            }
          }

          .amount {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            >div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 10px;
              gap: 3px;

              &:first-child {
                border-left: 1px solid var(--color-white3);
                padding-right: 0;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: var(--color-gray8);
                position: relative;
                top: -2px;
              }

              h5 {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-txtblue2);
              }
            }
          }
        }
      }

      .warning {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        direction: rtl;
        border: 1px solid var(--color-white14);
        gap: 10px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 10px 5px;
        width: 100%;
        margin-top: 24px;

        .item {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-gray8);
          position: relative;
          padding-right: 20px;
          padding-top: 10px;

          &::before {
            content: "♦";
            position: absolute;
            font-size: 20px;
            right: 0;
            top: 0;
            transform: translate(-50%, 20%);
            -webkit-transform: translate(-50%, 20%);
            -moz-transform: translate(-50%, 20%);
            -ms-transform: translate(-50%, 20%);
            -o-transform: translate(-50%, 20%);
          }
        }
      }
    }

    .tb_Receive {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      direction: rtl;

      .head .tRow {
        >div>h5 {
          font-size: 12px;
          font-weight: 400;

          background-color: var(--color-white3);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding: 3px 5px;
        }
      }

      .body {
        min-height: 300px;
        height: auto;
        max-height: 800px;
        overflow: auto;

        .tRow {
          padding: 10px 0;
          border-bottom: 1px solid var(--color-white14);
        }

      }

      .head,
      .body {
        width: 100%;

        .tRow {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          direction: rtl;
          width: 100%;
          gap: 10px;


          >div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-align: center;

            font-size: 13px;

            b {
              color: gray;
            }
          }

          >div:nth-child(1) {
            justify-content: flex-start;
            flex: 0 0 145px;
          }

          >div:nth-child(2) {
            flex: 0 0 110px;
          }

          >div:nth-child(3) {
            flex: 0 0 110px;
            ;
          }

          >div:nth-child(4) {
            flex: 0 0 110px;
          }

          >div:nth-child(5) {
            flex: 0 0 140px;
          }

          >div:nth-child(6) {
            flex: 0 0 180px;
            background-color: transparent;
          }

          .name {
            img {
              width: 24px;
              height: 24px;
            }

            h4 {
              font-size: 12px;
              font-weight: 400;

            }

            p {
              font-size: 12px;
              color: var(--color-txt4);
              font-weight: 400;
            }
          }

          .operator {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              width: 54px;
              padding: 5px 10px;
              text-align: center;
              border: none;
              color: var(--color-white);
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 !important;
              font-size: 12px;
              cursor: pointer;

              &.receive {
                background-color: var(--color-green6);
              }

              &.send {
                background-color: var(--color-red1);
              }

              &.order {
                background-color: var(--color-blue);
              }
            }
          }

        }
      }

    }


  }


  .bodyBoxNew {
    @extend %flexStart;
    direction: rtl;
    width: 100%;
    gap: 19px;
    align-items: stretch;

    .chooseBox {
      width: 55%;
      max-width: 55%;
      background: var(--color-white);
      padding: 32px 30px 32px 30px;
      border-radius: 13px;
      box-shadow: 0px 0px 52px 0px var(--color-blue10);

      .emptyLoad {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        p {
          font-size: 14px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        img {
          width: 85px;
          height: 70px;
        }
      }


      .addCardBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;
        cursor: pointer;

        .stepDepWith {
          margin-bottom: 0;
        }

        .addCard {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 10px;

          p {
            color: var(--color-title);
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;

            b {
              font-weight: 400;
            }
          }
        }

      }

      .stepDepWith {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;

        p {
          width: 25px;
          height: 27px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-Boxblue1);
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;

          span {
            font-size: 15px;
            font-weight: 400;
            color: var(--color-title);
            font-family: YekanBakh !important;
          }
        }

        h5 {
          color: var(--color-black5);
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          text-align: right;
        }
      }

      .decAccountBanck1 {
        color: var(--color-black5);
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
        margin-bottom: 15px;
      }

      .selectNameBank {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .item {
          width: 59px;
          height: 57px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid transparent;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          margin-bottom: 32px;
          cursor: pointer;

          &.active {
            border: 1px solid var(--color-white21);
          }
        }
      }

      .btnRequestID {
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 11px;
        background: var(--color-blue);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        cursor: pointer;
        margin-top: 31px;
        border: none;

        &:hover {
          background: var(--color-blue9);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-textOnlyWhite);
        }
      }

      .loadingCircularMax {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 46px;
        background: var(--color-blue);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        margin-top: 31px;
      }

      .reCreateID {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-top: 44px;
        margin-bottom: 12px;

        .textOkCreate {
          padding: 10px;
          color: var(--color-textDone);
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          background: var(--color-bgDone);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }

        .btnRequestID {
          margin: 0;
          margin-right: auto;
        }
      }

      .generatedID {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 9px;
        padding: 12px 15px 15px 15px;
        background: var(--color-bg1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        margin-bottom: 20px;

        p {
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: var(--color-black)
        }

        .item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;

          .dividerDash {
            flex: 1 0 auto;
            border-bottom: 1px dashed var(--color-gray10);
          }

          .imgOpatrtor {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
          }

        }
      }

      .showCoin {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        gap: 15px;

        img {
          width: 24px;
          height: 24px;
        }

        h4 {
          padding: 0;
          flex: 0;
          font-size: 14px;
          font-weight: 400;

          position: relative;
          top: 2px;
          flex: 1 0 auto;
          text-align: right;

          b {
            color: var(--color-black2);
            font-weight: 400;
          }
        }
      }

      .limitDepositBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        p {
          color: var(--color-title);
          font-size: 12px;
          font-weight: 400;
          text-align: right;
          padding-right: 5px;

          b {
            font-weight: 400;
            color: var(--color-txtBlue1);
            margin-right: 2px;
          }
        }

      }

      .btnsPercentage {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
        margin-top: 18px;

        .item {
          background: var(--color-bg1);
          padding: 5px 10px;
          cursor: pointer;

          &:hover {
            background: var(--color-bg2);
          }

          p {
            color: var(--color-black5);
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            font-family: YekanBakh !important;
          }
        }
      }

      .createDepositIDBox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 17px 20px;
        border: 1px solid var(--color-white18);
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        min-height: 157px;

        .defultBtns {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;

          .desText {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: right;
            color: var(--color-black2);
          }

          .creatrID {
            border: none;
            padding: 10px;
            color: var(--color-white);
            font-size: 15px;
            font-weight: 600;
            line-height: 15px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            background: var(--color-blue);
            margin-top: 15px;
            cursor: pointer;

            &:hover {
              background: var(--color-blue1);
            }
          }
        }



      }

      .divider {
        margin-top: 35px;
        margin-bottom: 29px;
      }

      .feeFinalAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 16px;

        >div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          img {
            cursor: pointer;
          }
        }

        p {
          color: var(--color-black5);
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          overflow: hidden;

          b {
            color: var(--color-black2);
            font-weight: 400;
          }
        }


        p:first-child {
          text-wrap: nowrap;
          white-space: nowrap;
          text-align: right;
        }


        p:last-child {
          flex: 1;
        }
      }

      .textSample {
        color: var(--color-black2);
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
        margin-bottom: 16px;
        margin-top: -6px;
      }

      .submit {
        background: var(--color-green);
        color: var(--color-textOnlyWhite);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        padding: 12px;
        margin-top: 9px;
        border: none;
        cursor: pointer;

        &:hover {
          background: var(--color-green2);
        }
      }

      .loadingCircularMin {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        background: var(--color-green);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        margin-top: 25px;
      }

      .warningMemoTag {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        background-color: var(--color-bg4);
        bottom: 0;
        right: 0;
        border-radius: 13px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        -ms-border-radius: 13px;
        -o-border-radius: 13px;
        padding: 20px;
        height: 470px;
        position: absolute;
        z-index: 101;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);

        p {
          font-size: 16px;
          text-align: center;
          line-height: 32px;
          font-weight: 400;
          color: var(--color-black);
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 7px 20px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          background: var(--color-blue);
          cursor: pointer;
          border: none;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          color: var(--color-textOnlyWhite);
          margin-top: 20px;

          &:hover {
            background: var(--color-blue3);
          }
        }
      }

      .limitWithdraw {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        flex-direction: column;
        margin-top: 21px;

        .dailyText {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          width: 100%;

          p,
          h5 {
            font-size: 14px;
            font-weight: 400;
            color: var(--txBody-color);
          }

          p::before {
            content: "⚈";
            color: var(--color-red);
            font-size: 14px;
            font-weight: 400;
            padding-left: 5px;
            position: relative;
            top: 2px;
          }

          .dotGreen::before {
            color: var(--color-green);
          }
        }

        .remainingDailyText {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
          flex-direction: column;
          width: 100%;

          .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 6px;

            h5 {
              font-size: 14px;
              font-weight: 400;
              color: var(--txBody-color);
            }

            .icon-IconInfoCircle {
              position: relative;
              bottom: 3px;
              cursor: pointer;
            }

            .boxDetailMonthly {
              position: relative;

              &:hover {
                .monthly {
                  // display: block !important;
                  opacity: 1;
                  visibility: visible;
                }
              }

              .monthly {
                //display: none;
                opacity: 0;
                position: absolute;
                background-color: var(--color-white);
                padding: 10px;
                right: 20px;
                top: 0;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
                width: 400px;
                z-index: 1001;
                visibility: hidden;
                box-shadow: 0 0 10px var(--color-textSlider);
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
              }
            }
          }



          .allGreen {
            position: relative;
            width: 100%;
            height: 8px;
            background: var(--color-green);
            border-radius: 64px;
            -webkit-border-radius: 64px;
            -moz-border-radius: 64px;
            -ms-border-radius: 64px;
            -o-border-radius: 64px;

            .useRed {
              position: absolute;
              width: 40%;
              height: 8px;
              left: 0;
              background: var(--color-red);
              border-radius: 64px;
              -webkit-border-radius: 64px;
              -moz-border-radius: 64px;
              -ms-border-radius: 64px;
              -o-border-radius: 64px;
            }
          }
        }
      }

      .generateCode {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        direction: rtl;
        padding: 15px 28px;
        gap: 35px;
        background: var(--cBox-coin);
        margin-top: 18px;
        min-height: 118px;

        .qrCode {
          max-width: 76px;
          flex: 0 0 76px;
          padding: 3px;


          span {
            width: 73px;
            height: 88px;
          }
        }

        .address {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 15px;

          p {
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            text-align: right;

            padding-right: 10px;
          }

          .txtAddress,
          .txtInfo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;


            h4 {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              color: var(--color-txtblue2);
              text-align: left;
              word-break: break-all;
            }

            .operatoer {
              direction: rtl;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;

              svg {
                cursor: pointer;
              }
            }
          }

          .txtAddress {
            box-shadow: 0px 0px 0.5px 0px var(--color-black4);
            background: var(--color-white);
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            padding: 5px 8px;
          }

          .txtInfo {
            gap: 10px;

            h4 {
              text-align: right;
            }
          }


        }
      }

      .warningMaxAmount {
        height: 80px;
        width: 100%;

        h3 {
          font-size: 14px;
          font-weight: 400;
          color: var(--txBody-color);
          line-height: 29px;

          b {
            color: var(--cBoxActive-coin);
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }

      //Send Coin


      .toAddress,
      .amountSend {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        // background: var(--color-white3);
        gap: 10px;
        border: 1px solid var(--color-white14);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        input {
          flex: 1 0 auto;
          font-size: 14px;
          padding: 5px;
          font-weight: 400;
          color: var(--color-txtblue2);
          text-align: left;
          border: none;
          background-color: transparent;
        }

        svg {
          cursor: pointer;
        }

        p {
          font-size: 13px;
          font-weight: 400;
          padding: 5px;
          background-color: var(--color-white3);
          cursor: pointer;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid var(--color-white6);
          }
        }
      }

      .finalDetail {
        @extend %flexCenter;
        justify-content: space-between;
        width: 100%;
        gap: 4px;
        margin-top: 20px;

        p {
          width: 33%;
          max-width: 33%;
          padding: 10px 2px;
          font-weight: 400;
          background-color: var(--color-txt4);
          color: var(--color-white);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          font-size: 12px;
          text-align: center;
        }
      }

    }

    .detailBox {
      width: 45%;
      max-width: 45%;
      padding-top: 15px;
      text-align: center;

      .imgPage {
        margin-bottom: 3px;
      }

      .minDepoText {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background: var(--color-green4);
        padding: 10px;
        border-radius: 9px;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        -ms-border-radius: 9px;
        -o-border-radius: 9px;
        margin-top: 47px;

        &.denger {
          background: var(--color-orange3);

          p {
            color: var(--color-orange10);
            font-weight: 400;
          }
        }

        &.denger1 {
          background: var(--color-orange3);

          p {
            color: var(--color-orange11);
            font-weight: 400;
          }
        }

        p {
          color: var(--color-green);
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
          text-align: right;
        }
      }

      .infoText {
        padding: 11px 14px;
        padding-bottom: 18px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
        background: var(--color-white);
        box-shadow: 0px 0px 52px 0px var(--color-blue10);
        direction: rtl;
        border-radius: 9px;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        -ms-border-radius: 9px;
        -o-border-radius: 9px;

        p {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-txtblue2);
          padding-right: 10px;
          text-align: right;
          position: relative;

          &::before {
            content: "•";
            color: var(--color-txtblue2);
            position: absolute;
            right: 0;
            top: 0;
          }

          b {
            font-weight: 400;
            color: var(--color-red1);
          }
        }

        h5 {
          color: var(--color-red1);
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
          text-align: right;
          padding-bottom: 2px;

          b {
            position: relative;
            font-weight: 700;
            color: var(--color-red1);

            &::before {
              content: "";
              height: 2px;
              width: 100%;
              background-color: var(--color-red1);
              position: absolute;
              right: 0;
              bottom: -2px;
            }
          }



        }
      }
    }

    .remainderMaxWithdraw {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      margin: 6px 0;

      .t1 {
        font-size: 11px;
        font-weight: 300;
        color: var(--color-text3);
      }

      .t2 {
        font-size: 11px;
        font-weight: 300;
        color: var(--color-txt4);
      }
    }

  }

}

/////////////Dialogs
.dialogContainer {
  // background: var(--color-white);

  .formControlLogin {
    margin: 20px 0;
  }

  .headDialog {
    @extend %flexCenter;
    justify-content: space-between;
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 16px 32px;
    margin-bottom: 20px;
    border: 1px solid var(--color-blue);

    .iconClose {
      cursor: pointer;
      color: var(--color-textOnlyWhite);

      &:hover {
        color: var(--color-textOnlyWhite);
      }
    }

    div {
      @extend %flexCenter;
      gap: 10px;
      direction: rtl;

      p {
        color: var(--color-textOnlyWhite);
      }
    }
  }

  .addCard {
    width: 500px;
    padding: 20px 25px;

    .customInput {
      direction: ltr;
    }
  }

  .finalCheckBtn {
    background: var(--color-orange2);
    margin-top: 25px;
  }

  .otherCoin {
    max-height: 437px !important;
    overflow-y: auto;
    padding: 20px 25px;
    max-width: 800px;


    .listCoin {
      @extend %flexCenter;
      flex-wrap: wrap;
      gap: 16px;
      margin: 8px;
      direction: rtl;

      .item {
        @extend %flexCenter;
        flex-direction: column;
        gap: 5px;
        padding: 12px;
        cursor: pointer;
        transition: 0.3s ease;
        width: 100px;
        border-radius: 15px;
        height: 100px;
        border: 1px solid var(--color-white3);
        position: relative;

        &.active {
          border: 1px solid var(--color-blue);

          .done {
            opacity: 1 !important;
          }
        }

        &:hover {
          border: 1px solid var(--color-blue);
        }

        .icon {
          width: 23px;
          height: 23px;
        }

        .done {
          width: 20px;
          position: absolute;
          top: -7px;
          right: -7px;
          opacity: 0;
        }
      }
    }
  }

  .addAdress {
    padding: 0px 25px 35px 25px;
    max-width: 450px;

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
      right: 70px;
      left: unset;
      padding: 0 10px;
      background-color: var(--color-white);
      transform-origin: top right;
    }

    .formControlLogin input {
      direction: rtl;
      border-right: 1px solid var(--color-white3) !important;
    }

    .caption {
      font-size: 16px;
      color: var(--color-txt4);
      margin: 40px 0 30px 0;
      text-align: center;
    }
  }

  .confirmBuy {
    padding: 20px 25px;
    padding-top: 0;
    max-width: 400px;

    .des {
      background: var(--color-white16);
      border-radius: 10px;
      padding: 20px 25px;
      text-align: center;
      line-height: 20px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: var(--color-txt4);
      }
    }

    .timing {
      padding: 0 15px;

      div {
        margin: 20px 0;
        @extend %flexCenter;
        width: 100%;
        border-radius: 6px;
        color: var(--color-white);
        overflow: hidden;
      }


      p {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 5px;
        background: var(--color-gray4);
        text-align: center;
        font-size: 12px;
        direction: rtl;

        &.textLeft {
          background: var(--color-gray5);
        }
      }
    }

    .radioGroup {
      direction: rtl;

      .myRadio {
        margin-right: 5px !important;
      }
    }

    .finalCheckBtn {
      div {
        @extend %flexCenter;
        justify-content: space-between;
        width: 100%;
        direction: rtl;

        p {
          font-size: $fontsize1;
          flex: 1;
          text-align: center;
        }
      }
    }

  }

  .imgSize24 {
    width: 24px;
    height: 24px;
  }

}

/////////////// dialogAddImgText
.dialogAddImgText {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-start;
  gap: 15px;
  width: 450px;
  background: var(--color-white);
  direction: rtl;

  .headTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-bottom: 1px solid var(--color-br2);
    background: var(--color-blue);
    padding: 35px 20px;

    .t1 {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-textOnlyWhite);
    }

  }

  .bodyBox {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: flex-start;
    gap: 25px;
    width: 100%;
    padding: 25px 20px;

    .imgBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 400px;
      max-height: 400px;

      >img {
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .inputBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 10px 15px;
      border: 1px solid var(--color-br1);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;

      input {
        width: 100%;
        font-size: 14px;
        color: var(--txBody-color);
        font-weight: 400;
        background: transparent;
        border: none;
      }
    }

    .btnsBox {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      width: 100%;
      margin-top: 20px;

      >button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-textOnlyWhite);
        cursor: pointer;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        transition: 0.3s ease-in;
        -webkit-transition: 0.3s ease-in;
        -moz-transition: 0.3s ease-in;
        -ms-transition: 0.3s ease-in;
        -o-transition: 0.3s ease-in;

        &:hover {
          opacity: 0.7;
        }

        &.btnOk {
          background: var(--color-green);
        }

        &.btnCancel {
          background: var(--color-red);
        }
      }
    }
  }
}

/////////////Dialog WarningMemo
.css-1k0ku3a-MuiPaper-root-MuiDialog-paper:has(.warningMemo) {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.warningMemo {
  padding: 16px;
  width: 469px;
  direction: rtl;
  text-align: center;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    width: 100%;

    p {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-red1);
    }

    img {
      cursor: pointer;
      width: 12px;
      height: 12px;
    }
  }

  .text {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    text-wrap: wrap;
    margin-bottom: 16px;
    text-align: right;
  }

  .finalCheckBtn {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }

  .btnControl {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    .goto,
    .hideLater {
      flex: 1;
      text-align: center;
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-textOnlyWhite);
      background: var(--color-blue);
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border: none;
      cursor: pointer;
    }

    .hideLater {
      background: var(--color-gray18);
    }
  }

}

.css-1k0ku3a-MuiPaper-root-MuiDialog-paper:has(.warningVPN) {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.warningVPN {
  padding: 35px;
  padding-top: 18px;

  h3 {
    font-size: 20px;
    margin: 10px 0;
    text-align: center;
    font-weight: 600;
    color: var(--color-red1);
  }

  .text {
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 14px;

    b {
      font-weight: 600;
      margin: 0 4px;
    }
  }
}

//////////// Dialog UseVoucher
.useVoucher {
  width: 400px;


  .body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
    padding: 15px 20px;
    direction: rtl;
    padding-bottom: 30px;

    h4 {
      font-size: 14px;
      font-weight: 400;
      color: var(--txBody-color);
      direction: rtl;
      line-height: 30px;

      &::before {
        content: "*";
        color: red;
        font-size: 16px;
        margin-left: 10px;
      }
    }

    .inputVoucher {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      direction: rtl;
      width: 100%;


      .customInput {
        direction: ltr;
        margin-bottom: 10px;
        border-color: var(--color-white3);
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-txt4);
        font-family: YekanBakh !important;
      }
    }

    .birthDate {
      @extend %flexStart;
      flex-direction: column;
      gap: 6px;
      width: 100%;

      .MuiSvgIcon-root {
        color: var(--txBody-color);
      }

      span {
        font-size: $fontsize2;
        color: var(--color-txt4);
        font-weight: 400;
      }

      div {
        @extend %flexStart;
        gap: 12px;
        width: 100%;
        color: var(--txBody-color) !important;

        input {
          flex: 1;
          font-size: $fontsize2;
          text-align: center;
          border-radius: 10px;
          padding: 10px 12px;
          border: 2px solid var(--color-white3);

          &::placeholder {
            color: red
          }
        }
      }
    }


    .finalCheckBtn {
      background: var(--color-blue);
      margin-top: 15px;
    }
  }
}

//////////// Dilaog fullScreenImgChat
.fullScreenImg {
  width: auto;
  height: 100%;
  max-height: 100%;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--color-white);

  img {
    width: 100%;
    // height: 100%;
    max-width: 100%;
    max-height: 80vh;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}


////////////progress-btn
/// 
.disableBtn {
  height: 28px;
  background: var(--color-gray10);
  width: 100%;
  display: inline-block;
  color: var(--color-white);
  font-weight: normal;
  height: 62px;
  padding: 20px;
  box-shadow: 0px 0px 52px -12px var(--color-blue5);
  border-radius: 13px;
  margin-top: 36px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  text-align: center;
  cursor: text;
}

.progress-btn {
  position: relative;
  width: 100%;
  display: inline-block;
  background: var(--color-blue);
  font-family: "RobotoDraft", "Roboto", sans-serif;
  color: var(--color-white);
  font-weight: normal;
  height: 62px;
  transition: all 0.4s ease;
  padding: 20px;
  box-shadow: 0px 0px 52px -12px var(--color-blue5);
  border-radius: 13px;
  margin-top: 36px;

  .again {
    text-align: center;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    background: var(--color-green);
  }

}

.progress-btn:not(.active) {
  cursor: pointer;
}

.progress-btn .btn {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 60px;
  text-align: center;
  z-index: 10;
  opacity: 1;
}

.progress-btn .progress {
  width: 0%;
  z-index: 5;
  background: var(--color-blue);
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 13px 0 0 13px;
}

.progress-btn.active .progress {
  opacity: 1;
  -webkit-animation: progress-anim 240s ease 0s;
  animation: progress-anim 240s ease 0s;
}

.progress-btn.active .btn {
  -webkit-animation: color-anim 240s ease 0s;
  animation: color-anim 240s ease 0s;
}

.progress-btn.active {
  box-shadow: none;
  -webkit-animation: bgcolor-anim 240s ease 0s;
  animation: bgcolor-anim 240s ease 0s;
}

.progress-btn[data-progress-style='fill-back'] .progress {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}


/* ///////////SlideCardsBank */
.css-1k0ku3a-MuiPaper-root-MuiDialog-paper:has(.sliderCardsBank) {
  background-color: var(--color-NO) !important;
  box-shadow: none !important;
}

.sliderCardsBank {
  min-width: 900px;
  height: 300px;
  padding: 0 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-top: 52px;
  margin-bottom: 104px;

  .back,
  .forward {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-color: var(--color-white);
    border-radius: 50%;
    padding: 16px;
    @extend %flexCenter;

    &:hover {
      background-color: var(--color-blue);
    }
  }

  .back {
    left: 0;
  }

  .forward {
    right: 0;
  }
}

.addCardBank {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white14);
  cursor: pointer;
}

.cardDetail {
  position: relative !important;
  background-color: var(--color-NO) !important;
  padding-top: 30px;

  .bodyDetail {
    height: 100%;
  }

  .close {
    position: absolute;
    top: 6px;
    left: 6px;
  }
}

.cardBankStyle {
  position: absolute;
  top: 0;
  width: 500px;
  height: 284px;
  // border: 1px solid var(--color-white14);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--color-white);

  .body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 20px;
    padding-top: 16px;
    overflow: hidden;

    .top {
      @extend %flexCenter;
      justify-content: space-between;
      width: 100%;

      div {
        @extend %flexCenter;
        gap: 5px;

        img {
          width: 40px;
          height: 40px;
        }

        h4 {
          font-weight: 500;
          font-size: 18px;
        }
      }

    }

    .imgBank {
      width: 54px;
      height: 61px;
      margin-bottom: 14px;
      object-fit: cover;
    }

    .imgShadowBank {
      position: absolute;
      width: 280px;
      height: 280px;
      left: -65px;
      top: 95px;
      opacity: 0.2;
      vertical-align: middle;
      border-style: none;
    }

    #number {
      font-size: 24px;
      margin-top: 32px;
      // font-family: OCR A Std !important;
      text-align: center;
    }

    #name {
      font-weight: 600;
      margin-top: 21px;
      margin-bottom: 21px;
      display: block;
      text-align: center;
      font-size: 18px;
      width: 100%;
      color: var(--txBody-color);
    }

    #shaba {
      text-align: center;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 400;
      color: var(--color-txt4);
      direction: rtl;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    color: var(--color-txtblue2);
    margin-bottom: 3px;
    width: 100%;
    z-index: 1;
  }

  h5 {
    font-size: 12px;
    line-height: 20px;
    color: var(--color-txtblue2);
    width: 100%;
    z-index: 1;
  }

  &.activeSlide {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    z-index: 5;
  }

  &.nextSlide {
    -webkit-transform: translate(35%);
    -moz-transform: translate(35%);
    -ms-transform: translate(35%);
    -o-transform: translate(35%);
    transform: translate(35%);
    z-index: 1;
    scale: 0.9;
  }

  &.nextSlide2 {
    opacity: 0.6;
    -webkit-transform: translate(65%);
    -moz-transform: translate(65%);
    -ms-transform: translate(65%);
    -o-transform: translate(65%);
    transform: translate(65%);
    z-index: 0;
    scale: 0.8;
  }

  &.lastSlide {
    -moz-transform: translate(-35%);
    -ms-transform: translate(-35%);
    -o-transform: translate(-35%);
    -webkit-transform: translate(-35%);
    transform: translate(-35%);
    z-index: 1;
    scale: 0.9;
  }

  &.lastSlide2 {
    opacity: 0.6;
    -webkit-transform: translate(-65%);
    -moz-transform: translate(-65%);
    -ms-transform: translate(-65%);
    -o-transform: translate(-65%);
    transform: translate(-65%);
    z-index: 0;
    scale: 0.8;
  }


}





@keyframes progress-anim {
  0% {
    width: 3%;
  }

  5% {
    width: 10%;
  }

  10% {
    width: 15%;
  }

  20% {
    width: 25%;
  }

  30% {
    width: 35%;
  }

  40% {
    width: 45%;
  }

  50% {
    width: 55%;
  }

  60% {
    width: 65%;
  }

  60% {
    width: 75%;
  }

  80% {
    width: 85%;
  }

  90% {
    width: 90%;
  }

  92% {
    width: 92%;
  }

  95% {
    width: 95%;
  }

  97% {
    width: 97%;
  }

  98% {
    width: 98%;
  }


  100% {
    width: 100%;
  }
}

@keyframes color-anim {
  0% {
    color: var(--color-black1);
  }

  25% {
    color: var(--color-txt4);
  }

  75% {
    color: var(--color-white);
  }

  100% {
    color: var(--color-white);
  }
}

@keyframes bgcolor-anim {
  0% {
    background-color: var(--color-white8);
  }

  97% {
    background-color: var(--color-white8);
  }

  100% {
    background-color: transparent;
  }
}

/////////////////////////Mobile Steps 
.mobileSteps {
  text-align: left;
  padding-left: 0;
  display: block;
  max-width: 41.666667%;

  .radial-progress {
    margin-top: 6px;
    display: inline-block;
    opacity: 1;
    max-width: 85px;
    padding: 1em;
    transform: rotate(-90deg);
    height: auto;
    width: 120px;
    z-index: 1;
    overflow: hidden;
    vertical-align: middle;

    circle.incomplete {
      opacity: 1;
      stroke: var(--color-white7) !important;
      stroke-width: 4px;
      fill: var(--color-NO);
      stroke-dashoffset: 219.9114857513;
    }

    circle.complete {
      stroke-dashoffset: 159px;
      stroke-dasharray: 219.911486;
      stroke-width: 4px;
      stroke: var(--color-blue);
      fill: var(--color-NO);
    }

    text {
      fill: var(--color-black3);
      font-weight: 600;
      text-anchor: middle;
      font-size: 20px;
    }
  }

  .step-1 {
    stroke-dashoffset: 140px !important;
  }

  .step-2 {
    stroke-dashoffset: 80px !important;
  }

  .step-3 {
    stroke-dashoffset: 0px !important;
  }

  .step-4 {
    stroke-dashoffset: 0px !important;
  }
}

//////////////////Step By Step
.stepByStep {
  max-width: 75%;
  margin: 0 auto;

  .stepsBody {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    font-weight: 400;

    .steps {
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
      }

      .stepBorder {
        width: 69px;
        height: 69px;
        background: var(--color-white);
        padding: 3px;
        border-radius: 50%;
        font-size: 23px;

        .step {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-white4);
          width: 100%;
          height: 100%;
          border-radius: 50%;
          font-size: 23px;

          span {
            color: var(--color-txt4);
          }

          img {
            display: none;
          }
        }
      }

      p {
        margin-top: 10px;
        margin-bottom: 16px;
        font-size: 12px;
        color: var(--color-txt4);
        text-wrap: nowrap;
        white-space: nowrap;
      }

      .line {
        border-bottom: 3px solid var(--color-white4);
        width: 130px;
        height: 5px;
        margin-bottom: 30px;
      }
    }

    .current {
      .line {
        border-bottom: 3px solid var(--color-blue) !important;
      }

      .stepBorder {
        border: 1px solid var(--color-blue) !important;

        .step {
          background: var(--color-blue) !important;

          span {
            color: var(--color-white) !important;
          }
        }


      }

      p {
        color: var(--color-blue) !important;
      }
    }

    .done {
      .line {
        border-bottom: 3px solid var(--color-green) !important;
      }

      .stepBorder {
        border: 1px solid var(--color-green) !important;
        background-color: var(--color-green);

        .step {
          background: var(--color-green) !important;

          span {
            display: none;
          }

          img {
            display: inline-block;
          }
        }


      }

      p {
        color: var(--color-green) !important;
      }
    }
  }
}
















@import './responsive';
@import './newResponsive';