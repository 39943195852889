@media (max-width: 1410px) {

  // .OrderPro .bodyBox .openOrderNow,
  // .OrderPro .head .openOrderNow {
  //   min-width: 236px !important;
  //   width: 236px;
  // }

  .order .coinsBox .item:nth-child(6) {
    display: none;
  }
}

@media (max-width: 1390px) {
  .OrderPro .head .headerPriceShow .decPrice:last-child {
    display: none;
  }
}

@media (max-width: 1350px) {

  .dashboard {
    .cartWallet {
      flex-direction: column;

      .right {
        width: 100%;
        justify-content: space-between;

        .detailWallet {
          flex-direction: row;
          align-items: center;
        }
      }

      .left {
        width: 100%;

        .balance,
        .activityUser {
          justify-content: flex-start;
        }
      }
    }

    .newsBox {
      flex-direction: column;
      height: unset;

      .cardNewCoin {
        width: 100%;
      }

      .cartSilder {
        width: 100%;
      }
    }

    .tableTra_NotBox {
      flex-direction: column;

      .cardTableTrans {
        width: 100%;
      }

      .cardTableNotif {
        width: 100%;
      }
    }

  }

  .OrderPro {
    margin: 0;
    width: 100% !important;

    .btnMenuDrawer {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .bodyBox {
      padding: 0px !important;
    }

    .head {
      gap: 12px;

      &.headTop {
        padding-right: 20px;
      }

      .btnMenuDrawer {
        margin-left: 10px;
      }

      .pairSelect {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        gap: 10px;
        min-width: 240px;
        flex: 0 0 240px;
      }

      .pairSelect {
        justify-content: flex-start;
        min-width: unset;
        flex: 0 0 auto;

        .imgPair {
          align-items: center;
          width: 45px;

          img {
            width: 30px;
            height: 30px;

          }
        }

        .namePair h3 {
          font-size: 15px;
        }
      }
    }
  }

  .OrderPro .bodyBox .disPlayNavbar {
    display: none;
  }

  .menuDrawerShow {
    .newNavbar {
      display: unset !important;
      padding: 0;

      .boxNav {
        .body {
          width: 300px;
          padding: 45px 20px;

          .btBackClose {
            display: none;
          }

          .logoTop {
            width: 170px;
          }

          .navControl .navMenu .titleMenu,
          .navControl .item {
            padding-left: 10px;
          }
        }

      }
    }
  }
}

@media (max-width: 1340px) {
  .sendNew .bodyBoxNew .chooseBox .generateCode {
    gap: 10px !important;
  }
}

@media (max-width: 1300px) {
  .alertRule p {
    margin-bottom: 39px;
  }
}

@media (max-width: 1265px) {
  .alertRule p {
    margin-bottom: 10px;
  }
}

@media (max-width: 1260px) {
  .sendNew .bodyBoxNew {
    .chooseBox {
      max-width: 66%;
      width: 66%;
    }

    .detailBox {
      max-width: 34%;
      width: 34%;
    }
  }
}

@media (max-width: 1250px) {

  .invitationBody {
    flex-direction: column-reverse;

    .right,
    .left {
      flex: 1;
      width: 100%;
    }
  }

  .newHeader .topHeader .titlePageDate p {
    display: none;
  }

  .OrderPro .head .headerPriceShow .decPrice:nth-child(2) {
    display: none;
  }

  .OrderPro .bodyBox .openOrderNow,
  .OrderPro .head .openOrderNow {
    display: none;
  }

  .OrderPro .bodyBox .centerBox .responsOrderBook,
  .OrderPro .head .centerBox .responsOrderBook {
    display: flex;
  }
}

@media (max-width: 1240px) {
  .order .coinsBox.respons {
    flex-wrap: wrap;
    justify-content: center;

    .item:nth-child(6) {
      display: none;
    }

    .other {
      width: calc(100%);
      order: 2;
    }
  }

  .order .bodyFastOrder {
    .right {
      max-width: 66%;
    }

    .left {
      max-width: 32%;
      width: 32%;
    }
  }

  .btnConfirm.w75,
  .addressOrderCurrency {
    max-width: calc(66% - 15px) !important;
  }

  .order .bodyFixedPriceOrder {

    .bodyBox {
      flex-direction: column-reverse;

      .right {
        width: 100%;
        max-width: 100%;
      }

      .map {
        height: 300px;
        width: 100%;
        max-width: 100%;
      }
    }

    .btnConfirm {
      width: 100%;
      max-width: 100%;
      padding: 16px 45px;
      gap: 20px;
      letter-spacing: 0;
    }
  }

  .depositCash .body,
  .withdrawCash .body {
    flex-direction: column;

    .right,
    .left {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}

@media (max-width: 1215px) {
  // .newHeader .topHeader .titlePageDate p {
  //   display: none;
  // }

  .sendNew .bodyBoxNew .chooseBox .generateCode {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .giftBody .creatGift .eleBox {
    width: calc(70% - 15px);
    max-width: calc(70% - 15px);
    min-width: calc(70% - 15px);
  }

  .giftBody .creatGift .imgBox {
    width: 30%;
    max-width: 30%;
    min-width: 30%;
  }
}

// @media (max-width: 1150px) {
// .OrderPro .bodyBox .priceLastOrder {
//   display: none;
// }

// .OrderPro .head .pairSelect {
//   min-width: unset;
//   flex: unset;
//   padding: 5px;
//   border: 1px solid var(--color-divider2);;
//   border-radius: 10px;
//   -webkit-border-radius: 10px;
//   -moz-border-radius: 10px;
//   -ms-border-radius: 10px;
//   -o-border-radius: 10px;
//   box-shadow:  0 0 10px var(--color-divider2);;
//   transition: 0.4s ease;
//   -webkit-transition: 0.4s ease;
//   -moz-transition: 0.4s ease;
//   -ms-transition: 0.4s ease;
//   -o-transition: 0.4s ease;

//   &:hover{
//     opacity: 0.7;
//   }

//   .namePair,
//   .arrowSelecting {
//     display: none;
//   }

// }
// }

@media (max-width: 1160px) {
  .orderGold .bodyGold {
    flex-direction: column;
    gap: 30px;

    .chartGolg,
    .calculaGold {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 1140px) {
  .dashboard .cartWallet .right .detailWallet {
    flex-direction: column;
    align-items: flex-start;
  }

  .order .bodyFastOrder {
    .right {
      max-width: 66%;
    }

    .left {
      max-width: 32%;
      width: 32%;
    }
  }

  .order .bodyFastOrder {
    flex-direction: column-reverse;

    .right {
      max-width: 100%;
      width: 100%;
    }

    .left {
      max-width: unset;
      width: 100%;
    }
  }

  .btnConfirm.w75,
  .addressOrderCurrency {
    max-width: 100% !important;
    width: 100%;
  }


  .newHeader .topHeader .titlePageDate p {
    display: none;
  }
}

@media (max-width: 1110px) {

  .dashboard .cartWallet .right .detailWallet {
    flex-direction: row;
    align-items: center;
  }

  .OrderPro .head .pairSelect {
    min-width: unset;
    flex: unset;

  }

  .newNavbar {
    display: none;
  }

  .otherCoinsView {
    width: 800px;
  }

  .menuDrawerShow {
    .newNavbar {
      display: unset !important;
      padding: 0;

      .boxNav {
        .body {
          width: 300px;
          padding: 45px 20px;

          .btBackClose {
            display: none;
          }

          .logoTop {
            width: 170px;
          }

          .navControl .navMenu .titleMenu,
          .navControl .item {
            padding-left: 10px;
          }
        }

      }
    }
  }

  .newHeader .topHeader .titlePageDate {
    gap: 10px;

    .btnMenuDrawer {
      display: unset !important;
    }
  }

}

@media (max-width: 1040px) {

  .order .coinsBox .item:nth-child(5),
  .order .coinsBox .item:nth-child(6) {
    display: none;
  }
}

@media (max-width: 950px) {
  .alertRule p {
    margin-bottom: 39px;
  }

  .OrderPro .bodyBox .openOrderNow {
    display: none;
  }

  .OrderPro .head .userProfile {
    min-width: unset;
    flex: unset;
  }

  .OrderPro .head .headerPriceShow .decPrice:last-child {
    display: none;
  }
}

@media (max-width: 925px) {
  .alertRule p {
    margin-bottom: 10px;
  }

  .otherCoinsView {
    width: 600px;

    .body .newTable {

      .headT .box>div:nth-child(4),
      .headT .box>div:nth-child(5),
      .bodyT .box>div:nth-child(4),
      .bodyT .box>div:nth-child(5) {
        display: none;
      }

      .headT .box,
      .bodyT .box {
        min-width: unset;
      }
    }

  }
}

@media (max-width: 900px) {
  .learning .defaultQustion .imgQuestion {
    display: none;
  }

  .learning .authLearningPage .textQuesBox {
    flex-direction: column;
    gap: 16px;
  }

  .alertBody {
    flex-direction: column;

    .priceAlert,
    .coinAlert {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
}

@media (max-width: 830px) {
  .OrderPro .head .userProfileHeader .imgprofile {
    display: none;
  }

  .detailLevelPage {
    .cards {
      flex-wrap: wrap;

      .cardItem {
        flex: unset !important;
        width: 100%;
      }
    }

    .needUpgradeMore {
      width: 100%;
      justify-content: space-between;
    }
  }

  .walletUser .historyBox .controler {
    flex-direction: column;
    align-items: flex-start;

    .oprator {
      width: 100%;
      justify-content: space-between;
    }

    .searchBox {
      width: 100%;
    }

  }

  .walletUser .bodyBox .walletProgerss .legendChart {
    margin-right: 200px;

    .item {
      flex-basis: 100%;
    }
  }

  .OrderPro .head .userProfile .signinOrLogin>h4 {
    display: none;
  }
}

@media (max-width: 800px) {
  .dashboard .cartWallet .right .detailWallet {
    flex-direction: column;
    align-items: flex-start;
  }

  .giftBody .creatGift .eleBox {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .giftBody .creatGift .imgBox {
    display: none;
  }
}

@media (max-width: 755px) {
  .alertRule p {
    margin-bottom: 39px;
  }
}

@media (max-width: 730px) {
  .alertRule p {
    margin-bottom: 10px;
  }
}

@media (max-width: 630px) {
  .alertRule p {
    margin-bottom: 39px;
  }

  .newHeader .topHeader .titlePageDate h2 {
    font-size: 25px;
  }

  .newHeader .topHeader .menuControl .dividerhorizontal {
    margin: 0 10px;
  }
}

// @media (max-width: 605px) {
//   .alertRule p {
//     margin-bottom: 10px;
//   }
// }