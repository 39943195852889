.iconPishkhanNav {
  display: flex;
  mask-image: url(../../../public/icons/pishkhannavwhite.svg);
  mask-size: cover;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
}

.iconOrdersNav {
  display: flex;
  mask-image: url(../../../public/icons/orders.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
}

.icon-support {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  display: flex;
  background-color: var(--color-textOnlyWhite);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  -webkit-mask-image: url(../../../public/icons/support.svg);
  mask-image: url(../../../public/icons/support.svg);
}


.iconMathNav {
  display: flex;
  mask-image: url(../../../public/icons/Math.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
}

//////

.m-IconOrder {
  display: flex;
  mask-image: url(../../../public/icons/m-IconOrder.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.iconWallet {
  display: flex;
  mask-image: url(../../../public/icons/wallet.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 22px;
  height: 22px;
}

.m-IconCardBank {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCardBank.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-IconSetting {
  display: flex;
  mask-image: url(../../../public/icons/m-IconSetting.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-IconBook {
  display: flex;
  mask-image: url(../../../public/icons/m-IconBook.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-pishkhanMenu {
  display: flex;
  mask-image: url(../../../public/icons/pishkhanMenu.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-Crown {
  display: flex;
  mask-image: url(../../../public/icons/m-Crown.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-IconTicket {
  display: flex;
  mask-image: url(../../../public/icons/m-IconTicket.svg);
  mask-size: contain;
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

//
.m-IconSell {
  display: flex;
  mask-image: url(../../../public/icons/m-IconSell.svg);
  mask-size: contain;
  background-color: var(--color-red1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.m-IconBuy {
  display: flex;
  mask-image: url(../../../public/icons/m-IconBuy.svg);
  mask-size: contain;
  background-color: var(--color-green);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.iconLoading {
  display: flex;
  mask-image: url(../../../public/icons/iconLoading.svg);
  background-color: var(--color-gray);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 30px;
  height: 30px;
}

.iconRed-mobile {
  display: flex;
  mask-image: url(../../../public/icons/m-CallingRed.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
}

.iconRed-card {
  display: flex;
  mask-image: url(../../../public/icons/m-CardBuy.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
}

.iconRed-document {
  display: flex;
  mask-image: url(../../../public/icons/m-IconInfoCircle.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
}

.iconRed-email {
  display: flex;
  mask-image: url(../../../public/icons/emailLogin.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
}

.iconRed-identity {
  display: flex;
  mask-image: url(../../../public/icons/m-IconInfoCircle.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
  padding: 10px;
}

.iconRed-gAuth {
  display: flex;
  mask-image: url(../../../public/icons/m-IconInfoCircle.svg);
  background-color: var(--color-orange1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 23px;
  height: 23px;
}

.iconClose {
  display: flex;
  mask-image: url(../../../public/icons/iconClose.svg);
  background-color: var(--color-gray);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
}

.iconInfo {
  display: flex;
  mask-image: url(../../../public/icons/iconInfo.svg);
  background-color: var(--color-blue);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
}

.iconSortUp {
  display: flex;
  mask-image: url(../../../public/icons/m-IconSortUp.svg);
  background-color: var(--color-gray12);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 4.5px;
  height: 5.5px;
}

.iconSortDown {
  display: flex;
  mask-image: url(../../../public/icons/m-IconSortDown.svg);
  background-color: var(--color-gray12);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 4.5px;
  height: 5.5px;
}

.iconOffStar {
  display: flex;
  mask-image: url(../../../public/icons/m-IconOffStar.svg);
  background-color: var(--color-gray12);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
}

.iconStar {
  display: flex;
  mask-image: url(../../../public/icons/m-IconStar.svg);
  background-color: var(--color-yellow4);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
}

.iconArrowUpGreen {
  display: flex;
  mask-image: url(../../../public/icons/m-IconArrowUpGreen.svg);
  background-color: var(--color-green3);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
}

.iconArrowDownRed {
  display: flex;
  mask-image: url(../../../public/icons/m-IconArrowDownRed.svg);
  background-color: var(--color-red1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

///////
.icon-sun {
  display: flex;
  mask-image: url(../../../public/icons/m-sun.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-moon {
  display: flex;
  mask-image: url(../../../public/icons/icon_moon.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-bell {
  display: flex;
  mask-image: url(../../../public/icons/m-bell.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.icon-wallet {
  display: flex;
  mask-image: url(../../../public/icons/wallet.svg);
  -webkit-mask-image: url(../../../public/icons/wallet.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.icon-Arrow2 {
  display: flex;
  mask-image: url(../../../public/icons/Arrow.2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-Arrow2Up {
  display: flex;
  mask-image: url(../../../public/icons/Arrow.2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.icon-ArrowDown {
  display: flex;
  mask-image: url(../../../public/icons/m-ArrowDown.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-ArrowUp {
  display: flex;
  mask-image: url(../../../public/icons/m-ArrowUp.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-ArrowLeft {
  display: flex;
  mask-image: url(../../../public/icons/m-ArrowLeft.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 22px;
  height: 22px;
  padding: 6px;
}

.icon-IconNote {
  display: flex;
  mask-image: url(../../../public/icons/m-IconNote.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconPaste {
  display: flex;
  mask-image: url(../../../public/icons/m-IconPaste.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconPaste2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconPaste2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-Stack {
  display: flex;
  mask-image: url(../../../public/icons/m-Stack.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-WalletBlue {
  display: flex;
  mask-image: url(../../../public/icons/m-IConWallet2.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-SecurityCard {
  display: flex;
  mask-image: url(../../../public/icons/m-SecurityCard.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-ring {
  display: flex;
  mask-image: url(../../../public/icons/ring.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 17px;
  height: 20px;
  padding: 6px;
}

.icon-showall {
  display: flex;
  mask-image: url(../../../public/icons/showall.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 15px;
  height: 17px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 6px;
}

.icon-IConUser {
  display: flex;
  mask-image: url(../../../public/icons/m-IConUser.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IConWallet2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IConWallet2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IConFingerScan {
  display: flex;
  mask-image: url(../../../public/icons/m-IConFingerScan.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IConCardTick {
  display: flex;
  mask-image: url(../../../public/icons/m-IConCardTick.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconBell {
  display: flex;
  mask-image: url(../../../public/icons/m-IconBell.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconGift {
  display: flex;
  mask-image: url(../../../public/icons/m-IconGift.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconInvitation {
  display: flex;
  mask-image: url(../../../public/icons/m-IconInvitation.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconUsers1 {
  display: flex;
  mask-image: url(../../../public/icons/m_IconUsers1.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconCards2 {
  display: flex;
  mask-image: url(../../../public/icons/m_IconCards2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconChart2 {
  display: flex;
  mask-image: url(../../../public/icons/m_IconChart2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconGift20 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconGift20.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.icon-IconSearchNormal {
  display: flex;
  mask-image: url(../../../public/icons/m-IconSearchNormal.svg);
  background-color: var(--color-black2);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 7px;
}

.icon-iconSearch2 {
  display: flex;
  mask-image: url(../../../public/icons/iconSearch2.svg);
  background-color: var(--color-black);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
  padding: 7px;
}

.icon-IconEye {
  display: flex;
  mask-image: url(../../../public/icons/m-IconEye.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconEyeClose {
  display: flex;
  mask-image: url(../../../public/icons/m_IconEyeClose.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconEyeOpen {
  display: flex;
  mask-image: url(../../../public/icons/m_IconEyeOpen.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconAddCard {
  display: flex;
  mask-image: url(../../../public/icons/m-IconAddCard.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconCopy2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCopy2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconCopy16 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCopy16.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}

.icon-list {
  display: flex;
  mask-image: url(../../../public/icons/m-Icon-list.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-list2 {
  display: flex;
  mask-image: url(../../../public/icons/m-Icon-list2.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}


.icon_addressBook {
  display: flex;
  mask-image: url(../../../public/icons/mIcon_addressBook.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconCopy3 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCopy3.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 19px;
  height: 19px;
  padding: 6px;
}

.icon-IconCopy4 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCopy4.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconLink2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLink2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 19px;
  height: 19px;
  padding: 6px;
}

.icon-IconInfoCircle {
  display: flex;
  mask-image: url(../../../public/icons/m-IconInfoCircle.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconArrowUpGray {
  display: flex;
  mask-image: url(../../../public/icons/m-IconArrowUpGray.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}

.icon-IconArrowDownGrray {
  display: flex;
  mask-image: url(../../../public/icons/m-IconArrowDownGrray.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}

.icon-IconTickSquare {
  display: flex;
  mask-image: url(../../../public/icons/m-IconTickSquare.svg);
  background-color: var(--color-green1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-userprofile {
  display: flex;
  mask-image: url(../../../public/icons/userprofile.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-kartmelli {
  display: flex;
  mask-image: url(../../../public/icons/kartmelli.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-kartmelli {
  display: flex;
  mask-image: url(../../../public/icons/kartmelli.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-emailLogin {
  display: flex;
  mask-image: url(../../../public/icons/emailLogin.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-Attachment {
  display: flex;
  mask-image: url(../../../public/icons/attachment.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.icon-editenum {
  display: flex;
  mask-image: url(../../../public/icons/editenum.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-lockwhiteSample {
  display: flex;
  mask-image: url(../../../public/icons/lockwhite.svg);
  background-color: var(--color-white);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-loginlock {
  display: flex;
  mask-image: url(../../../public/icons/loginlock.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconCandle {
  display: flex;
  mask-image: url(../../../public/icons/m-IconCandle.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.iconDocUpload_2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconDocUpload_2.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 40px;
  height: 40px;
  padding: 20px;
}

.icon-qrcode {
  display: flex;
  mask-image: url(../../../public/icons/qrcode.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.iconLearn_security {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_security.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_WDcoin {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_WDcoin.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_WDfiat {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_WDfiat.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_auth {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_auth.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_product {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_product.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_logo {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_logo.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_wallet {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_wallet.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.iconLearn_BScoin {
  display: flex;
  mask-image: url(../../../public/icons/m-IconLearn_BScoin.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 57px;
  height: 57px;
  padding: 6px;
}

.icon_Onlyplus {
  display: flex;
  mask-image: url(../../../public/icons/m-IconOnlyPluse.svg);
  background-color: var(--color-black1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon_User3 {
  display: flex;
  mask-image: url(../../../public/icons/m_iconUser3.svg);
  background-color: var(--color-textOnlyWhite);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon_login2 {
  display: flex;
  mask-image: url(../../../public/icons/m-login2.svg);
  background-color: var(--color-blue);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.iconMessageQuestionYellow {
  display: flex;
  mask-image: url(../../../public/icons/m-IconMessageQuestionYellow.svg);
  background-color: var(--color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.iconMessageQuestionBlue {
  display: flex;
  mask-image: url(../../../public/icons/m-IconMessageQuestionBlue.svg);
  background-color: var(--color-blue18);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.iconDangerRed2 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconDangerRed2.svg);
  background-color: var(--color-txtwarning);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-Clock {
  display: flex;
  mask-image: url(../../../public/icons/m_clock.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-mobile {
  display: flex;
  mask-image: url(../../../public/icons/m-mobile.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-login3 {
  display: flex;
  mask-image: url(../../../public/icons/m_login3.svg);
  background-color: var(--color-textOnlyWhite);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 27px;
  height: 27px;
  padding: 6px;
}

.icon-Wallt15 {
  display: flex;
  mask-image: url(../../../public/icons/m-IconWallt15.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}


.icon-IconGraph {
  display: flex;
  mask-image: url(../../../public/icons/m_IconGraph.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconAddBell {
  display: flex;
  mask-image: url(../../../public/icons/m_IconAddBell.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconList {
  display: flex;
  mask-image: url(../../../public/icons/m_IconList.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconGift2 {
  display: flex;
  mask-image: url(../../../public/icons/m_IconGift2.svg);
  background-color: var(--color-title);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-smallArrow {
  display: flex;
  mask-image: url(../../../public/icons/smallArrow.svg);
  background-color: var(--color-newBlue);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 5px;
  height: 6px;
  padding: 6px;
}

.icon-treeDot {
  display: flex;
  mask-image: url(../../../public/icons/treeDot.svg);
  background-color: var(--color-text6);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-upVolume {
  display: flex;
  mask-image: url(../../../public/icons/upVolume.svg);
  background-color: var(--color-green);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 14px;
  height: 8px;
  padding: 6px;
}
.icon-downVolume {
  display: flex;
  mask-image: url(../../../public/icons/downVolume.svg);
  background-color: var(--color-red1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 14px;
  height: 8px;
  padding: 6px;
}

.icon-quoteSquare {
  display: flex;
  mask-image: url(../../../public/icons/quoteSquare.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-arrowCircle {
  display: flex;
  mask-image: url(../../../public/icons/arrowCircle.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
  padding: 6px;
}
.icon-deposit1 {
  display: flex;
  mask-image: url(../../../public/icons/deposit1.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-witdraw1 {
  display: flex;
  mask-image: url(../../../public/icons/witdraw1.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-IconRepeat {
  display: flex;
  mask-image: url(../../../public/icons/m-IconRepeat.svg);
  background-color: var(--txBody-color);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-logoGray {
  display: flex;
  mask-image: url(../../../public/icons/logoGray.svg);
  background-color: var(--color-graylogo);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18.5px;
  height: 16.5px;
  padding: 6px;
}

.icon-selectIcon {
  display: flex;
  mask-image: url(../../../public/icons/selectIcon.svg);
  background-color: var(--color-graylogo);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 8.5px;
  height: 16.5px;
  padding: 6px;
}

.icon-d_order {
  display: flex;
  mask-image: url(../../../public/icons/d_order.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}


.icon-d_pro {
  display: flex;
  mask-image: url(../../../public/icons/d_pro.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_fast {
  display: flex;
  mask-image: url(../../../public/icons/d_fast.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_depo {
  display: flex;
  mask-image: url(../../../public/icons/d_depo.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_withdraw {
  display: flex;
  mask-image: url(../../../public/icons/d_withdraw.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}
.icon-d_robat {
  display: flex;
  mask-image: url(../../../public/icons/d_robat.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}
.icon-d_change {
  display: flex;
  mask-image: url(../../../public/icons/d_change.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_security {
  display: flex;
  mask-image: url(../../../public/icons/d_security.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-d_wallet {
  display: flex;
  mask-image: url(../../../public/icons/d_wallet.svg);
  background-color: var( --color-blueDark);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 32px;
  height: 32px;
  padding: 6px;
}

.icon-close_circle {
  display: flex;
  mask-image: url(../../../public/icons/close_circle.svg);
  background-color: var( --color-red1);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-tick_circle {
  display: flex;
  mask-image: url(../../../public/icons/tick_circle.svg);
  background-color: var( --color-green);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}

.icon-24_support {
  display: flex;
  mask-image: url(../../../public/icons/24_support.svg);
  background-color: var( --color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-arrange_square {
  display: flex;
  mask-image: url(../../../public/icons/arrange_square.svg);
  background-color: var( --color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-receipt_text {
  display: flex;
  mask-image: url(../../../public/icons/receipt_text.svg);
  background-color: var( --color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-shield_security {
  display: flex;
  mask-image: url(../../../public/icons/shield_security.svg);
  background-color: var( --color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}
.icon-discount_circle {
  display: flex;
  mask-image: url(../../../public/icons/discount_circle.svg);
  background-color: var( --color-yellow5);
  mask-repeat: no-repeat;
  mask-position: center;
  width: 24px;
  height: 24px;
  padding: 6px;
}