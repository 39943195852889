main {
  background-image: url(../../../public/icons/m-bgMain.svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: top;
  background-position-x: 40px;
  max-width: 2000px;
  margin: 0 auto;
  min-height: 100vh;

  .bodyMain {
    display: flex;
    justify-content: flex-start;
    // align-items: flex-start;
    align-items: stretch;
    // gap: 10px;
    direction: rtl;
    width: 100%;
    position: relative;

  }
}

.divderCardBank {
  width: 85%;
  margin: 20px auto 5px auto;
  border-bottom: 1px dashed var(--color-brInput);
}

.signinOrLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--cBoxActive-coin);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border: 1px solid #00000000;

  &.btnLogin {
    background: var(--color-textOnlyWhite);
    border: 1px solid var(--color-blue);
    margin-left: 8px;

    h4 {
      color: var(--color-blue);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
    }

    span {
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
    }

    &:hover {
      background: #22c58b;
      border: 1px solid #22c58b;

      h4 {
        color: var(--color-textOnlyWhite);
      }

      span {
        background: var(--color-textOnlyWhite);
      }
    }
  }

  &:hover {
    background: #22c58b;
  }

  >img {
    height: 32px !important;
    height: auto;
  }

  >h4 {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-textOnlyWhite);
  }
}



.newContainer {
  // flex: 1 1 100%;
  margin-left: auto;
  padding: 0 20px;
  overflow: hidden;
  width: 100%;
  // background: var(--color2);
  // flex: 0 0 100%;
  // display: flex;
}

.footer {
  width: 100%;
  background-color: var(--color-white);

  .bodyBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    direction: rtl;
    padding: 25px 100px;
    padding-top: 70px;
    position: relative;
    margin-top: 100px;

    .blueBox {
      direction: rtl;
      width: 90%;
      padding: 17px 30px;
      background-color: var(--color-blue);
      background-image: url(../../../public/icons/m-bgFooter.svg);
      border-radius: 23px;
      -webkit-border-radius: 23px;
      -moz-border-radius: 23px;
      -ms-border-radius: 23px;
      -o-border-radius: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);

      .des {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .text {
          display: flex;
          justify-content: flex-start;
          align-items: self-start;
          flex-direction: column;
          gap: 5px;
          direction: rtl;


          h4 {

            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: var(--color-textOnlyWhite);
            text-align: right;
          }

          p {

            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-textOnlyWhite);
            text-align: right;
          }
        }

        .circle1 {
          background: var(--color-white32);
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          .circle2 {
            background: var(--color-textOnlyWhite);
            width: 62px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            img {
              width: 24px;
              height: 24px;
            }
          }

        }
      }

      .tel {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 150px;

        h3 {
          font-family: YekanBakh !important;
          font-size: 31.57px;
          font-weight: 600;
          line-height: 42.1px;
          color: var(--color-textOnlyWhite);
          direction: ltr;

          b {
            font-family: YekanBakh !important;
            font-weight: 400;
            color: var(--color-white38);
          }
        }
      }
    }


    .aboutSite {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      direction: rtl;
      width: 100%;
      gap: 20px;
      margin-top: 30px;

      .des {
        width: 40%;
        max-width: 40%;
        direction: rtl;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;

        img {
          width: 180px;
          height: auto;
        }

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          text-align: justify;
          color: var(--color-black);
        }
      }

      .timeCompany,
      .downloadApp {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 22%;
        max-width: 22%;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 3px;
          margin-bottom: 15px;

          h4 {

            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-align: justified;
            color: var(--color-black);
          }
        }

        p {

          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          text-align: justified;
          color: var(--color-black);
        }
      }

      .downloadApp {
        width: 38%;
        max-width: 38%;
        padding-bottom: 10px;

        .iconsDownload {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            width: 150px;
            max-width: 33%;
            height: 44px;
            object-fit: cover;
            max-width: 150px;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;

            &:hover {
              transform: translateX(20px) scale(1.2);
              -webkit-transform: translateX(20px) scale(1.2);
              -moz-transform: translateX(20px) scale(1.2);
              -ms-transform: translateX(20px) scale(1.2);
              -o-transform: translateX(20px) scale(1.2);
            }
          }

          .bazar {
            width: 113px;
            // max-width: 30%;
          }
        }

      }
    }

    .socialICons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      direction: rtl;
      width: 100%;
      gap: 20px;
      margin: 60px 0 40px 0;

      .tel {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          padding: 0 15px;
          direction: ltr;

          &:nth-child(2) {
            border-left: 1px solid var(--color-hoverNav);
            border-right: 1px solid var(--color-hoverNav);
          }

          b {
            color: var(--color-title);
            font-weight: 600;
          }
        }
      }

      .icons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 25px;

        img {
          width: 26.5px;
          height: 26.5px;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            transform: scale(1.5);
            -webkit-transform: scale(1.5);
            -moz-transform: scale(1.5);
            -ms-transform: scale(1.5);
            -o-transform: scale(1.5);
          }
        }
      }
    }

    .dividerVer {
      width: 100%;
      height: 0px;
      border: none;
      border-bottom: 1px solid var(--color-white33);
      margin: 0;
    }

    .copyright {
      text-align: center;
      width: 100%;
      margin-top: 20px;

      h4 {

        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;

        b {

          font-size: 15px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
          color: var(--color-title);
        }
      }
    }
  }
}

.newHeader {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  direction: rtl;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;

  .topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    width: 100%;
    flex: 0 0 100%;
    padding: 35px 15px;
    padding-bottom: 10px;

    .menuControl {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      direction: rtl;

      .wallet {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        p {
          font-size: 14px;

          font-weight: 400;
        }

        .MenuWallet {
          min-width: 226px;
        }

        &.hover {
          position: relative;

          .menuHover {
            position: absolute;
            display: none;
            z-index: 1080;
          }

          &:hover {
            .menuHover {
              display: unset !important;
              left: -22px;
              top: 25px;

              .menuPopup {
                background: var(--color-white);
                box-shadow: 0px 0px 52px 0px var(--color-blue4);
              }
            }
          }
        }
      }

      .dividerhorizontal {
        height: 20px;
        width: 0px !important;
        margin: 0 17px;
        border-left: 1px solid var(--color-gray17)
      }

    }

    .titlePageDate {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      h2 {

        font-size: 30px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }

      p {

        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        padding: 10px;
        background-color: var(--color-bgNo);
        text-align: right;
        direction: rtl;
        color: var(--color-text2);
      }

      .btnMenuDrawer {
        display: none;
        width: 33px;
        height: 33px;
        cursor: pointer;
      }

    }

    .noticeBtn {
      cursor: pointer;
    }
  }
}

.alertBoxAuth {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  direction: rtl;
  width: 100%;
  gap: 15px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    width: 100%;
    padding: 15px 40px;
    background: var(--color-white);
    border: 1px solid var(--color-red3);
    // border-image: linear-gradient(90deg, var(--color-red3) 0%, var(--color-red3)00 54%, var(--color-red3) 100%) 10;
    // border: 1px solid;
    // border-image-source: linear-gradient(90deg, var(--color-red3) 0%, var(--color-red3)00 54%, var(--color-red3) 100%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    // position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   inset: 0;
    //   padding: 1px;
    //   background: linear-gradient(90deg, var(--color-red3) 0%, var(--color-red3)00 54%, var(--color-red3) 100%);
    // -webkit-mask: linear-gradient(var(--color-white) 0 0) content-box, linear-gradient(var(--color-white) 0 0);
    //   -webkit-mask-composite: xor;
    //   mask-composite: exclude;
    //   border-radius: 10px;
    //   -webkit-border-radius: 10px;
    //   -moz-border-radius: 10px;
    //   -ms-border-radius: 10px;
    //   -o-border-radius: 10px;
    // }

    &.rejectLevel {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .icon-Clock {
        background: var(--color-red1);
      }
    }

    .textAut {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      direction: rtl;
      gap: 10px;

      img {
        width: 23px;
        height: 23px;
      }

      h5 {
        color: var(--color-red1);
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;

        text-align: left;
        padding: 0 10px;
        border-left: 1px solid var(--color-white39)
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;

        text-align: left;
        color: var(--color-black);
      }
    }

    .submit {
      padding: 5px 10px;
      background: var(--color-red1);
      border: none;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;

      text-align: left;
      color: var(--color-textOnlyWhite);
      min-width: 102px;
      text-align: center;
      cursor: pointer;
      z-index: 10;
    }
  }
}

.alertRule {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 22px;
  background: var(--color-bgwarning);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid var(--color-brwarning);
  position: relative;
  margin-bottom: 8px;

  span {
    flex: 0 0 auto;
    margin-top: 5px;
  }

  p {
    text-wrap: wrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    flex: 1;
    color: var(--color-txtwarning);
    margin-bottom: 10px;
  }

  .okWarningBtn {
    background: var(--color-txtwarning);
    border: none;
    color: var(--color-white);
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    flex: 0 0 auto;
    position: absolute;
    bottom: 18px;
    left: 22px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
}

.newNavbar {
  // height: calc(100vh - 30px);
  box-shadow: -5px 4px 46.3px 0px var(--color-black9);
  border-radius: 0 0 0 10px;
  -webkit-border-radius: 0 0 0 10px;
  -moz-border-radius: 0 0 0 10px;
  -ms-border-radius: 0 0 0 10px;
  -o-border-radius: 0 0 0 10px;
  position: sticky;
  // position: -webkit-sticky;
  top: 0;
  right: 0;
  // width: 333px;
  // min-width: 333px;
  padding: 45px 20px;
  padding-bottom: 100px;
  background: var(--color-white);
  align-self: stretch;

  .boxNav {
    //padding: 45px 20px;
    overflow: hidden;
    transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -ms-transition: 0.4s ease;
    -o-transition: 0.4s ease;

    .body {
      width: 300px;
      // min-width: 333px;
      // padding: 45px 20px;
      // width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      direction: rtl;
      background: var(--color-white);
      overflow-y: auto;
      border-radius: 0 0 0 10px;
      -webkit-border-radius: 0 0 0 10px;
      -moz-border-radius: 0 0 0 10px;
      -ms-border-radius: 0 0 0 10px;
      -o-border-radius: 0 0 0 10px;



      .logoTop {
        margin: 0 auto;
        padding-right: 10px;
        width: 180px;
        height: auto;
      }

      .btBackClose {
        position: absolute;
        left: -20px;
        top: 45px;
        z-index: 10;
        width: 42px;
        height: 42px;
        cursor: pointer;

      }

      .navControl {
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
        flex-direction: column;
        direction: rtl;
        width: 100%;
        gap: 5px;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          direction: rtl;
          width: 100%;
          padding: 12px 22px;
          padding-right: 10px;

          span {
            background-color: var(--txBody-color);
          }

          &.active {
            p {
              color: var(--color-blue) !important;
            }

            span {
              background-color: var(--color-title);
            }
          }

          &.upgradeAuth {
            background: var(--color-yellow5);
            gap: 12px;
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;
            cursor: pointer;

            p {
              color: var(--color-textOnlyWhite);
            }
          }

          >div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            direction: rtl;
            gap: 15px;
            position: relative;

            p {

              font-size: 15px;
              font-weight: 600;
              line-height: 24px;

              text-align: left;

            }
          }

          .textNumber {
            width: 24px;
            height: 24px;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            background: var(--color-blue17);

            font-size: 15px;
            font-weight: 600;
            line-height: 24px;

            text-align: center;
            color: var(--color-blue);

          }
        }

        .dividerVer {
          width: 100%;
          border: 1px solid var(--color-divider2)
        }

        .navMenu {
          display: flex;
          justify-content: flex-start;
          align-items: self-start;
          flex-direction: column;
          width: 100%;

          .titleMenu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            direction: rtl;
            width: 100%;
            padding: 12px 22px;
            padding-right: 10px;
            cursor: pointer;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            transition: 0.3s ease;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;

            span {
              background-color: var(--txBody-color);
            }

            &.active {
              p {
                color: var(--color-blue15);
              }

              span {
                background-color: var(--color-title);
              }
            }

            &:hover {
              opacity: 1;
              border-top: 1px solid var(--color-hoverNav);
              border-bottom: 1px solid var(--color-hoverNav);
              background: var(--color-hoverNav);
              background: linear-gradient(90deg, var(--color-bghoverNav) 0%, var(--color-NO) 100%);


              &.active {
                color: var(--color-blue);
              }

            }

            &.upgradeAuth {
              background: var(--color-yellow5);
              gap: 12px;
              border-radius: 12px;
              -webkit-border-radius: 12px;
              -moz-border-radius: 12px;
              -ms-border-radius: 12px;
              -o-border-radius: 12px;

              p {
                color: var(--color-white);
              }
            }

            >div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              direction: rtl;
              gap: 15px;
              position: relative;

              p {

                font-size: 15px;
                font-weight: 600;
                line-height: 24px;
                color: var(--txBody-color);
                text-align: left;

              }
            }

            .textNumber {
              width: 24px;
              height: 24px;
              border-radius: 6px;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              -ms-border-radius: 6px;
              -o-border-radius: 6px;
              background: var(--color-blue17);

              font-size: 15px;
              font-weight: 600;
              line-height: 24px;

              text-align: center;
              color: var(--color-blue);

            }
          }

          .subMenu {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            direction: rtl;
            padding-right: 20px;
            width: 100%;
            position: relative;
            overflow: hidden;
            transition: 0.3s;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;

            &::before {
              content: "";
              position: absolute;
              height: calc(100% - 33px);
              top: 0;
              border-right: 2.5px solid var(--color-white35);
            }

            .items {
              transition: all 3 ease;
              -webkit-transition: all 3 ease;
              -moz-transition: all 3 ease;
              -ms-transition: all 3 ease;
              -o-transition: all 3 ease;
              width: 100%;
              padding-right: 14px;
            }

            .items .linkItem {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;
              padding: 10px 0;
              margin: 2px 0;
              width: 100%;
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;
              cursor: pointer;
              // transition: 0.3s ease;
              // -webkit-transition: 0.3s ease;
              // -moz-transition: 0.3s ease;
              // -ms-transition: 0.3s ease;
              // -o-transition: 0.3s ease;

              // &::after {
              //   position: absolute;
              //   content: "";
              //   width: 4px;
              //   height: 0%;
              //   background-color: transparent;
              //   position: absolute;
              //   left: 0;
              //   transform: translate(0%, 0%);
              //   -webkit-transform: translate(0%, 0%);
              //   -moz-transform: translate(0%, 0%);
              //   -ms-transform: translate(0%, 0%);
              //   -o-transform: translate(0%, 0%);
              //   transition: 0.2s ease;
              //   -webkit-transition: 0.2s ease;
              //   -moz-transition: 0.2s ease;
              //   -ms-transition: 0.2s ease;
              //   -o-transition: 0.2s ease;
              // }
              // position: relative;

              .styleIsNew {
                position: relative;
                top: -8px;
                right: -10px;
                color: var(--color-textOnlyWhite);
                font-size: 12px;
                font-weight: 400;
                padding: 1px 4px;
                border-radius: 6px;
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                -ms-border-radius: 6px;
                -o-border-radius: 6px;
                background: var(--color-red1);
}

              &.active {
                background: var(--color-actvieNav);
                border-radius: 12px;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                -ms-border-radius: 12px;
                -o-border-radius: 12px;
                position: relative;

                &::after {
                  position: absolute;
                  content: "";
                  width: 4px;
                  height: 70%;
                  background-color: var(--color-title);
                  position: absolute;
                  left: 0;
                  transform: translate(0%, 0%);
                  -webkit-transform: translate(0%, 0%);
                  -moz-transform: translate(0%, 0%);
                  -ms-transform: translate(0%, 0%);
                  -o-transform: translate(0%, 0%);
                }

                p {
                  color: var(--color-title);
                }
              }

              img {
                position: relative;
                top: -4px;
                right: -14px;
              }

              p {

                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                color: var(--txBody-color);
                text-align: right
              }
            }
          }

        }

        .showDetailInNav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          padding: 12px;
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;
          background-color: var(--color-bgDone);
          width: 100%;

          p {
            color: var(--color-textDone);
            font-size: 14px;
            font-weight: 400;
          }

          &.level {
            background-color: var(--color-actvieNav);
            margin-bottom: 8px;
            margin-top: 3px;

            p {
              color: var(--color-title);
            }
          }
        }

        .menuSocial {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 16px;
          flex-direction: column;
          margin-top: 8px;

          p {
            font-size: 14px;
            font-weight: 400;
            color: var(--txBody-color);
          }

          .iconSocial {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 26px;
          }
        }
      }

    }
  }

}

.order {
  padding: 25px 44px;
  direction: rtl;
  text-align: right;
  width: 100%;
  max-width: 100%;
  background: var(--color-white);
  box-shadow: 0px -3px 36.1px 0px var(--color-black6);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  .btnChangeType {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 20px;
    gap: 10px;

    .dividerHor {
      width: 0px;
      height: 100%;
      background-color: var(--color-red3);
    }

    >button {
      border: none;

      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      color: var(--txBody-color);
      text-align: center;
      padding: 10px;
      padding-bottom: 13px;
      background: none;
      position: relative;
      cursor: pointer;

      border-bottom: 1px solid var(--color-NO);

      &.active {
        color: var(--color-title);
        border-bottom: 1px solid var(--color-title);

        &::before {
          content: "";
          width: 100%;
          height: 0;
          background-color: var(--color-title);
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, -50%);
        }

      }
    }
  }

  .coinsBox {
    display: flex;
    align-items: center;
    direction: rtl;
    gap: 20px;

    .item {
      // width: 125px;
      // height: 128px;
      max-width: 137px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15px 0;
      border: 1px solid transparent;
      border-radius: 26px;
      -webkit-border-radius: 26px;
      -moz-border-radius: 26px;
      -ms-border-radius: 26px;
      -o-border-radius: 26px;
      background: var(--cBox-coin);
      gap: 5px;
      cursor: pointer;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      -o-transition: 0.3s ease;

      &.active {
        background: var(--cBoxActive-coin);

        h4 {
          color: var(--color-textOnlyWhite);

          b {
            color: var(--color-textOnlyWhite);
          }
        }
      }

      &:hover {
        border: 1px solid var(--color-white14);
      }


      img {
        width: 42px;
        height: 42px;
      }

      h4 {

        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        direction: rtl;

        b {
          font-weight: 400;
          margin-left: 5px;
          color: var(--color-textCoin);
          margin-right: 5px;
        }
      }
    }

    .other {
      width: 117px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      background: var(--cBox-coin);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 7px 0;
      direction: rtl;
      cursor: pointer;
      border: 1px solid transparent;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      -o-transition: 0.3s ease;

      &:hover {
        border: 1px solid var(--color-white14);
      }

      h5 {
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        padding-right: 5px;
      }

      // img {
      // position: relative;
      // top: -2px;
      // }
    }
  }

  .choosingCoin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
    gap: 12px;
    margin-top: 35px;

    img {
      width: 42px;
      height: 42px;
    }

    h4 {

      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: var(--color-gray11);

      b {
        font-weight: 400;
        color: var(--txBody-color);
        margin: 0 3px;
        text-align: center;
        display: inline-block;
      }
    }
  }

  .btnChangeOrder {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    button {
      width: 50%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: var(--color-white);
      border: 1px solid var(--color-br1);
      direction: rtl;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      cursor: pointer;

      p {
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;

        text-align: center;
        color: var(--color-white);
      }
    }

    .buy {
      p {
        color: var(--color-green);
      }

      &.active {
        background-color: var(--color-green);
        border: 1px solid var(--color-green);

        p {
          color: var(--color-textOnlyWhite);
        }

        span {
          background-color: var(--color-textOnlyWhite);
        }
      }
    }

    .sell {
      p {
        color: var(--color-red1);
      }

      &.active {
        background-color: var(--color-red1);
        border: 1px solid var(--color-red1);

        p {
          color: var(--color-textOnlyWhite);
        }

        span {
          background-color: var(--color-textOnlyWhite);
        }
      }
    }
  }

  .bodyFastOrder,
  .bodyOrderCurrency {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    direction: rtl;

    .left {
      max-width: 25%;
      width: 25%;
      padding: 10px 20px;
      background: var(--color-bgDone);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      h4 {
        font-size: 14px;
        font-weight: 400;
        text-wrap: nowrap;
        white-space: nowrap;
        color: var(--color-textDone);
      }
    }

    .right {
      max-width: 75%;
      width: 75%;

      .enterAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 10px;
        border: 1px solid var(--color-white40);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        padding: 5px 10px;
        margin-top: 25px;

        .imgInput {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex: 1 0 auto;
          gap: 14px;
          border: none;
          background: transparent;


          input {
            flex: 1 0 auto;
            border: none;
            background: transparent;

            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: right;


            &::placeholder {
              color: var(--color-gray11);
              font-size: 14px;
              font-weight: 600;
            }
          }

          img {
            width: 31px;
            height: 31px;
          }
        }

        .btnExchange {
          padding: 10px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          margin: 0 12px;
          border: 1px solid var(--color-black11);
          background: var(--color-textOnlyWhite);
          cursor: pointer;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }

      .wrningText {
        color: var(--color-red1);
        font-size: 12px;
        font-weight: 600;
        line-height: 18.6px;
        text-align: right;
        margin-top: 15px;

      }

      .priceNow {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 18px;
        direction: rtl;
        margin-bottom: 15px;

        h4 {
          // width: 240px;
          color: var(--color-black);
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          text-align: right;

          b {
            font-weight: 400;
            font-size: 14px;
          }
        }

        .dollarPriceShow {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }
      }

      .selectWalletBuy {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        flex-direction: column;
        margin-bottom: 25px;
        width: 100%;

        .MuiButtonBase-root {
          color: var(--txBody-color);

          &.Mui-checked {
            color: var(--cBoxActive-coin);
          }
        }

        .t1 {
          font-size: 15px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        .MuiFormGroup-root {
          gap: 20px;

          .MuiFormControlLabel-root {
            margin: 0;

            .MuiFormControlLabel-label {
              font-size: 14px;
            }
          }
        }

        .adressBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;


          .maxWidth {
            max-width: 200px;
            min-width: 200px;
          }

          .customInputSelect .textViewBox {
            justify-content: space-between;

            h4 {
              padding: 0;
              font-size: 13px;
              font-weight: 400;

              b {
                font-weight: 400;
              }
            }
          }

          .addCard {
            border: 1px solid var(--color-br1);
            padding: 12px 10px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            cursor: pointer;
          }



        }

        .feeReceiveBuy {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          p {
            font-size: 13px;
            font-weight: 400;
            color: var(--txBody-color);
          }
        }

      }

      .vouCodeBox {
        width: 100%;
        margin-bottom: 30px;

        .customInput {
          border-color: var(--color-white14);
        }
      }
    }

  }

  .bodyFixedPriceOrder {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    direction: rtl;
    gap: 10px;

    .bodyBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      gap: 30px;
      direction: rtl;

      .map {
        max-width: 70%;
        width: 70%;
      }

      .right {
        // flex: 0 0 260px;
        max-width: 30%;
        width: 30%;
        min-width: 260px;

        .btnChangeOrder {
          button {
            gap: 5px;

            p {

              font-size: 11.26px;
              font-weight: 600;
              line-height: 18.02px;

              text-align: left;

            }

            span {
              width: 18px;
              height: 18px;
            }
          }

        }

        .bestAmount {
          font-size: 11px;
          font-weight: 400;
          line-height: 24px;

          text-align: right;

          margin: 5px 0;

          b {
            font-size: 10px;
            font-weight: 400;
            margin-right: 4px;
          }

        }

        .inputBox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          gap: 1px;
          direction: rtl;
          margin-top: 10px;

          .operator {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 1px;
            direction: rtl;

            .plus,
            .minus {
              border: 0.81px solid var(--color-white21);
              border-radius: 0 3px 0 0;
              -webkit-border-radius: 0 3px 0 0;
              -moz-border-radius: 0 3px 0 0;
              -ms-border-radius: 0 3px 0 0;
              -o-border-radius: 0 3px 0 0;
              padding: 0 4px;
              text-align: center;
              line-height: 17px;
              cursor: pointer;
              transition: 0.3s ease;
              -webkit-transition: 0.3s ease;
              -moz-transition: 0.3s ease;
              -ms-transition: 0.3s ease;
              -o-transition: 0.3s ease;

              &:hover {
                background-color: var(--color-hoverNav);
              }

              span {
                font-size: 18px;
                position: relative;
                top: 2px;
                text-align: center;
              }
            }

            .minus {
              border-radius: 0 0 3px 0;
              -webkit-border-radius: 0 0 3px 0;
              -moz-border-radius: 0 0 3px 0;
              -ms-border-radius: 0 0 3px 0;
              -o-border-radius: 0 0 3px 0;
            }
          }

          .enterAmount {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 0.81px solid var(--color-white18);
            padding: 7px;
            border-radius: 3px 0 0 3px;
            -webkit-border-radius: 3px 0 0 3px;
            -moz-border-radius: 3px 0 0 3px;
            -ms-border-radius: 3px 0 0 3px;
            -o-border-radius: 3px 0 0 3px;
            flex: 1 1 100%;
            gap: 5px;

            input {
              flex: 1 0 auto;
              border: none;
              background: transparent;
            }

            p {
              color: var(--txBody-color);
              font-size: 12.09px;
              font-weight: 600;

              text-align: center;
            }
          }
        }

        .detileOrder {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 0.81px solid var(--color-white18);
          padding: 7px;
          height: 40px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          flex: 1 1 100%;
          gap: 5px;
          margin-bottom: 10px;

          &.final {
            border: 0.81px solid var(--color-green)
          }

          h4 {
            flex: 1;
            border: none;
            background: transparent;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            // max-width: 124px;
            overflow: auto;
          }

          p {
            color: var(--txBody-color);
            font-size: 12.09px;
            font-weight: 600;
            text-align: right;
            width: 70px;

            &.symbol {
              width: 40px;
              text-align: center;
            }

            &.gray {
              color: var(--color-gray17);
            }

            &.green {
              color: var(--color-green);
            }
          }
        }

        .textIcon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }

      }
    }


    .btnConfirm {
      max-width: 35%;
      width: 35%;
      min-width: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-green);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 8.5px 9.5px;
      gap: 5px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      -o-transition: 0.3s ease;

      &.buy {
        background-color: var(--color-green);

        &:hover {
          background-color: var(--color-green7);
        }

        button {
          color: var(--color-green);
        }
      }

      &.sell {
        background-color: var(--color-red1);

        &:hover {
          background-color: var(--color-red4);
        }

        button {
          color: var(--color-red1);
        }
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-wrap: nowrap;
        white-space: nowrap;
        text-align: right;
        color: var(--color-textOnlyWhite);
        letter-spacing: -0.3px;
        width: 260px;
      }

      hr {
        border-color: var(--color-textOnlyWhite);
      }

      button {
        width: 115px;
        height: 38px;
        padding: 7px 15px;
        background-color: var(--color-textOnlyWhite);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        font-size: 18px;
        font-weight: 600;
        line-height: 24px;

        text-align: center;
        border: none;
        color: var(--color-green);
        cursor: pointer;
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }

  .bodyOrderCurrency {
    // flex-direction: column-reverse;
    // justify-content: space-between;
    // align-items: center;

    // // .right,
    // // .left {
    // //   width: 100%;
    // //   max-width: 100%;
    // // }



    .left {
      // display: flex;
      // justify-content: flex-start;
      // gap: 20px;
      // align-items: flex-start;
      // flex-direction: column-reverse;

      .amoutSite {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-hoverNav);
        gap: 10px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        padding: 10px 20px;
        direction: rtl;

        p {
          font-weight: 400;
          font-size: 14px;


          b {
            font-weight: 400;
            margin-right: 5px;
          }
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .right {
      .titleAddress {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;

      }

      .enterAmount {
        border-color: var(--color-white14);
      }

      .customInput {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }

      .priceNow {
        flex-wrap: wrap;

        h4 {
          width: 44%;
          max-width: 44%;
        }
      }
    }



  }

  .addressOrderCurrency {
    margin-bottom: 45px;
    max-width: calc(75% - 15px);
  }

  .btnConfirm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-green);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 16px 45px;
    gap: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s ease background;
    -webkit-transition: 0.3s ease background;
    -moz-transition: 0.3s ease background;
    -ms-transition: 0.3s ease background;
    -o-transition: 0.3s ease background;

    &.w75 {
      max-width: calc(75% - 15px);
    }

    &.buy {
      background-color: var(--color-green);

      &:hover {
        background-color: var(--color-green7);
      }

      button {
        color: var(--color-green);
      }
    }

    &.sell {
      background-color: var(--color-red1);

      &:hover {
        background-color: var(--color-red4);
      }

      button {
        color: var(--color-red1);
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
      color: var(--color-textOnlyWhite);
      width: 262px;
    }

    hr {
      border-color: var(--color-textOnlyWhite);
    }

    button {
      width: 115px;
      height: 38px;
      padding: 7px 15px;
      background-color: var(--color-textOnlyWhite);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;

      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      border: none;
      color: var(--color-green);
      cursor: pointer;
      text-wrap: nowrap;
      white-space: nowrap;
    }
  }

  .loadingCircularOrder {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-green);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 10px;


    &.w75 {
      max-width: calc(75% - 15px);
    }

    &.buy {
      background-color: var(--color-green);
    }

    &.sell {
      background-color: var(--color-red1);
    }
  }

  .sliderRage {
    padding: 0 9px 0 5px;

    span {
      font-family: YekanBakh !important;
    }

    .MuiSlider-markLabel {
      color: var(--color-textSlider);
      direction: rtl;

      &:nth-child(4) {
        padding-left: 21px;
      }
    }

    .MuiSlider-markLabelActive {
      color: var(--color-title) !important;
    }
  }
}


.stepOrder {
  width: 100%;
  direction: rtl;
  margin: 21px 0;

  >p {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: var(--color-gray11);
    padding-right: 30px;
    margin-top: 8px;
  }

  >div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
    gap: 10px;

    .dividerVer {
      flex: 1 0 auto;
      border-bottom: 1px solid var(--color-divider);
    }

    h4 {
      margin-left: 10px;
      text-wrap: nowrap;
      white-space: nowrap;

      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      text-align: right;

      b {
        font-weight: 700;
        color: var(--color-title);
        margin-right: 5px;
      }
    }
  }

}


.newTable {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  direction: rtl;
  // overflow: auto;

  .headT .box,
  .bodyT .box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
    padding: 10px 0;
    gap: 10px;
    min-width: 680px;

    >div {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-wrap: nowrap;
      white-space: nowrap;
      // font-family: YekanBakh !important;
      text-align: center;
      color: var(--color-black7);

      b {
        color: var(--color-black10);
        font-weight: 400;
      }
    }

    >div:nth-child(1) {
      max-width: 81px;
      flex: 0 0 81px;
    }

    >div:nth-child(2) {
      max-width: 100px;
      flex: 0 0 100px;
    }

    >div:nth-child(3) {
      max-width: 47px;
      flex: 0 0 47px;
    }

    >div:nth-child(4) {
      max-width: 95px;
      flex: 0 0 95px;
    }

    >div:nth-child(5) {
      max-width: 60px;
      flex: 0 0 60px;
    }

    >div:nth-child(6) {
      max-width: 60px;
      flex: 0 0 60px;
    }

    >div:nth-child(7) {
      max-width: 120px;
      flex: 0 0 120px;
    }

    >div:nth-child(8) {
      max-width: 60px;
      flex: 0 0 60px;
    }

    >div:nth-child(9) {
      max-width: 100px;
      flex: 0 0 100px;
    }

    #name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;

      img {
        width: 20px;
        height: 20px;
      }

      h5 {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;

        text-align: right;
        color: var(--color-black7);
      }

      p {
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;

        text-align: right;
        color: var(--color-gray11);
      }
    }

    #cancleOrder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      direction: rtl;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
        color: var(--color-black7);
      }
    }

    #confirm {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;

      text-align: center;
      color: var(--color-white);
      background: var(--color-green);
      padding: 3px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

  }

  .bodyT {
    height: auto;
    min-height: 300px;
  }

}

.newHistoryOrder {
  padding: 25px 48px 24px 47px;
  direction: rtl;
  text-align: right;
  width: 100%;
  max-width: 100%;
  background: var(--color-white);
  box-shadow: 0px -3px 36.1px 0px var(--color-black6);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 25px;

  .btnFilter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;

    .item {
      padding: 5px 16px;
      background: var(--color-bg1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 4px;

      &.active {
        background: var(--color-blue);

        p {
          color: var(--color-textOnlyWhite);
        }

        span {
          background: var(--color-textOnlyWhite);
        }
      }

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;

        text-align: center;

      }

      img {
        width: 24px;
        height: 24px;
      }
    }

  }

  .tb_OrderFast {
    max-height: 400px;
    min-height: 300px;

    table {
      // min-width: 1053px;

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;

          /////////////
          // &:nth-child(1) {
          //   padding-right: 0;
          //   min-width: 102px;
          // }

          &:nth-child(1) {
            min-width: 135px;
          }

          &:nth-child(2) {
            min-width: 65px;
          }


          &:nth-child(3) {
            min-width: 90px;
          }

          &:nth-child(4) {
            min-width: 160px;
            direction: ltr;

            b {
              font-weight: 400;
              margin-left: 2px;
            }
          }

          &:nth-child(5) {
            min-width: 135px;
            direction: ltr;

            b {
              font-weight: 400;
              margin-left: 2px;
            }
          }

          // &:nth-child(7) {
          //   min-width: 135px;
          //   direction: ltr;

          //   b {
          //     font-weight: 400;
          //     margin-left: 2px;
          //   }
          // }
          &:nth-child(6) {
            min-width: 145px;
            direction: ltr;

            b {
              font-weight: 400;
              margin-left: 2px;
            }
          }

          &:nth-child(7) {
            min-width: 120px;

          }

          &:nth-child(8) {
            min-width: 90px;
          }

          &:nth-child(9) {
            min-width: 50px;
          }
        }

        #name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            width: 20px;
            height: 20px;
          }

          .imgPair {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            width: 27px;

            img {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            .coin {
              position: absolute;
              top: -5px;
              right: 0;
            }
          }


          h5 {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            text-align: right;
            color: var(--color-text);
          }

          p {
            font-size: 12px;
            font-weight: 300;
            line-height: 24px;

            text-align: right;
            color: var(--color-gray11);
          }
        }

        #cancleOrder {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          direction: rtl;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          border: 1px solid #00000000;
          padding: 4px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;

          &:hover {
            opacity: 0.7;
            border: 1px solid var(--color-red7);
          }

          img {
            width: 18px;
            height: 18px;
          }

          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;

            text-align: right;
            color: var(--color-red7);
          }
        }

        #confirm {
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;

          text-align: center;
          color: var(--color-white);
          background: var(--color-green);
          padding: 3px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }
      }

      #complete,
      #pending,
      #reject,
      #waiting,
      #cancel,
      #checking {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: var(--color-textOnlyWhite);
        background: var(--color-green);
        padding: 3px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }

      #pending {
        background-color: var(--color-yellow7);
      }

      #waiting {
        color: var(--color-textOnlyWhite) !important;
        background-color: var(--color-yellow7) !important;
      }

      #checking {
        background: var(--color-white14);
      }

      #cancel,
      #reject {
        background: var(--color-red1);
      }

      #process,
      #checking {
        background: var(--color-orange1) !important;
        color: var(--color-textOnlyWhite) !important;
      }
    }
  }

}


.otherCoinsView {
  padding: 5px 0;
  width: 950px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    width: 100%;

    >img {
      width: 38px;
      height: 38px;
      cursor: pointer;
    }

    >div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: right;
      padding: 10px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      background: var(--color-green);


      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: YekanBakh !important;
        text-align: center;
        color: var(--color-textOnlyWhite);

      }

    }
  }

  .body {
    width: 100%;
    margin-top: 20px;
    direction: rtl;
    overflow: auto;

    .controller {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: 100%;
      direction: rtl;

      .searchBox {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 50%;
        width: 50%;
        gap: 10px;
        background: var(--color-bg1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 10px;


        input {
          flex: 1 0 auto;
          border: none;
          background-color: transparent;

          font-size: 14px;
          font-weight: 600;
          line-height: 24px;

          text-align: right;


        }

        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .sortBox {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 50%;
        width: 50%;
        gap: 10px;
        background: var(--color-bg1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 2px 10px;
        padding-left: 0;

        .sortSelect {
          direction: rtl;
          padding: 9px 14px;
          padding-right: 8px;
          border: none !important;
          background: transparent !important;
        }

        .icon-IconCandle {
          width: 24px;
          min-width: 24px;
        }
      }
    }

    .selectPair {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-top: 25px;

      p {
        font-size: 12px;
        margin-left: 5px;
        color: var(--txBody-color);
      }

      button {
        border: none;
        background: var(--color-bg1);
        padding: 6px 15px;
        font-size: 13px;
        color: var(--txBody-color);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        cursor: pointer;

        &.active {
          background: var(--color-Boxblue1);
        }
      }
    }

    .newTable {
      margin-top: 15px;

      #nameCoin {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        img {
          width: 28px;
          height: 28px;
        }

        >div {
          display: flex;
          justify-content: flex-start;
          align-items: self-start;
          flex-direction: column;

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18.6px;
            text-wrap: nowrap;
            white-space: nowrap;
            text-align: right;
            line-height: 15px;
          }
        }
      }

      #orders {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .sell,
        .buy {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          opacity: 0.7;
          transition: 0.3s ease;
          -webkit-transition: 0.3s ease;
          -moz-transition: 0.3s ease;
          -ms-transition: 0.3s ease;
          -o-transition: 0.3s ease;

          &:hover {
            opacity: 1;
          }

          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 18.6px;

            text-align: right;

          }
        }
      }

      .bodyT,
      .headT {
        width: 100%;
      }

      .headT .box,
      .bodyT .box {
        gap: 10px;
        justify-content: space-between;

        >div {
          color: var(--txBody-color);
        }

        >div:nth-child(1) {
          max-width: 125px;
          flex: 0 0 125px;
        }

        >div:nth-child(2) {
          max-width: 110px;
          flex: 0 0 110px;
        }

        >div:nth-child(3) {
          max-width: 102px;
          flex: 0 0 102px;
          direction: ltr;
        }

        >div:nth-child(4),
        >div:nth-child(5) {
          max-width: 100px;
          flex: 0 0 100px;
        }

        >div:nth-child(6) {
          max-width: 142px;
          flex: 0 0 142px;
        }
      }

      .bodyT .box {
        &:hover {
          background-color: var(--color-bghoverNav);
        }

        >div:nth-child(1) {
          padding-right: 20px;
        }
      }

      .headT .box {
        div {
          color: var(--color-gray11);
        }
      }

      .bodyT {
        height: 100px;
        overflow: auto;
        padding-left: 10px;

        &::-webkit-scrollbar-thumb {
          background: var(--color-green);
        }
      }

      .bursStyle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
      }
    }

  }
}

.detailTicket {
  max-width: 400px;
  width: 400px;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);

  .headTitle {
    @extend%flexCenter;
    justify-content: space-between;
    direction: rtl;
    color: var(--color-white);
    gap: 5px;
    font-size: 14px;
    text-align: right;
    cursor: unset;
    width: 100%;
    background: var(--color-blue1);
    background: linear-gradient(90deg, var(--color-blue1) 0%, var(--color-blue) 100%);
    padding: 18px 15px;
    border-radius: 15px 15px 0 0;
    -webkit-border-radius: 15px 15px 0 0;
    -moz-border-radius: 15px 15px 0 0;
    -ms-border-radius: 15px 15px 0 0;
    -o-border-radius: 15px 15px 0 0;

    div {
      @extend%flexCenter;
      justify-content: space-between;
      direction: rtl;
      color: var(--color-white);
      gap: 5px;
      font-size: 14px;
      text-align: right;
    }

    img {
      width: 28px;
      height: 28px;
    }

    p {
      font-size: 14px;
      color: var(--color-white);
    }
  }

  .body {
    text-align: right;
    background: var(--color-white);
    padding-bottom: 16px;
    direction: rtl;
    border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    -ms-border-radius: 0 0 15px 15px;
    -o-border-radius: 0 0 15px 15px;

    .item {
      @extend%flexCenter;
      justify-content: flex-start;
      direction: rtl;
      padding: 15px 20px;
      gap: 5px;

      .imgborder {
        padding: 2px;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        img {
          width: 46px;
          height: 46px;
        }
      }

      .text {
        @extend%flexStart;
        flex-direction: column;
        gap: 3px;

        h4 {
          font-size: 12px;
          color: var(--color-gray6);
        }

        p {
          color: var(--color-gray3);
          font-weight: 600;
          font-size: 14px;
          display: block;
        }
      }
    }
  }
}

.walletUser {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;


  .bodyBox {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 9px;
    max-width: 100%;
    width: 100%;
    direction: rtl;
    // margin-top: 25px;

    .walletAmount {
      width: 60%;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: var(--color-white);
      direction: rtl;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 50px 35px 30px 35px;
      box-shadow: 0px -3px 36.1px 0px var(--color-black6);

      .topText {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 21px;

        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          h4 {
            color: var(--color-black5);
            font-size: 20px;
            font-weight: 600;
            text-align: right;
          }

          img {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }

        .btnText {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;

          p {
            color: var(--color-black5);
            font-size: 14px;
            font-weight: 600;
          }

          img {
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
        }
      }

      .totalAmount {
        width: 100%;
        display: flex;
        height: 24px;
        justify-content: flex-start;
        align-items: center;
        gap: 9px;
        margin-left: auto;

        h4 {
          font-size: 22px;
          font-weight: 400;

          font-family: YekanBakh !important;

          b {
            font-size: 20px;
            font-weight: 400;
            color: var(--color-gray19);
            margin-right: 3px;
          }
        }

        img {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }

      .divider {
        width: 100%;
        background: var(--color-white34);
        margin: 21px 0;
      }

      .profitPast {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 21px;
        width: 100%;

        p {
          color: var(--color-black5);
          font-size: 14px;
          font-weight: 600;
        }

        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;

          img {
            cursor: pointer;
          }
        }
      }

      .profitAmount {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 21px;

        p {
          font-size: 14px;
          font-weight: 600;

          font-family: YekanBakh !important;

          b {
            font-size: 14px;
            font-weight: 600;
            color: var(--color-gray19);
            margin-right: 3px;
          }
        }

        .profitPercent {
          background: var(--color-bgDone);
          padding: 4px 13px 0px 13px;
          text-align: center;
          color: var(--color-textDone);
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          text-align: center;
          font-family: VazirmatnMedium !important;
          letter-spacing: 1px;
        }
      }

      .btnControl {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .btnWidthdraw,
        .btnDeposit {
          width: 50%;
          max-width: 50%;
          padding: 10px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          border: none;
          color: var(--color-textOnlyWhite);
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
          text-align: center;
          height: 44px;
          transition: 0.3s ease;
          -webkit-transition: 0.3s ease;
          -moz-transition: 0.3s ease;
          -ms-transition: 0.3s ease;
          -o-transition: 0.3s ease;
        }

        .btnDeposit {
          background-color: var(--color-green);

          &:hover {
            background-color: var(--color-green9);
          }
        }

        .btnWidthdraw {
          background-color: var(--color-red1);

          &:hover {
            background-color: var(--color-red6);
          }
        }
      }

    }

    .walletProgerss {
      width: 40%;
      max-width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background-color: var(--color-white);
      direction: rtl;
      gap: 10px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      padding: 34px 68px 28px 68px;

      .donatChart {
        width: 190px;
        height: 190px;
      }

      .legendChart {
        width: 289px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        direction: rtl;
        flex-wrap: wrap;
        margin-top: 21px;
        row-gap: 15px;
        margin-right: 85px;

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: auto;
          gap: 7px;
          direction: rtl;
          flex: 1 0 calc(50% - 7px);

          // width: 120px;
          // width: 33%;
          // max-width: 33%;
          // &:nth-child(even) {
          //   background-color:var(--color-red);
          // }

          .dot {
            width: 8px;
            height: 8px;
            display: inline-block;
            background: var(--color-blue);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            text-align: right;
            color: var(--color-black5);
            font-family: YekanBakh !important;
          }
        }
      }
    }
  }

  .historyBox {
    padding: 20px;
    direction: rtl;
    max-width: 100%;
    width: 100%;
    direction: rtl;
    box-shadow: 0px 0px 52px 0px var(--color-blue10);
    background-color: var(--color-white);
    padding: 32px 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;

    .controler {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 27px 30px;
      padding-top: 5px;

      .searchBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 216px;
        height: 36px;
        padding: 10px;
        gap: 15px;
        border: 1.69px solid var(--color-white23);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        img {
          cursor: pointer;
          width: 15px;
          height: 15px;
        }

        input {
          flex: 1 0 auto;
          border: none;
          background: transparent;
          font-size: 14px;

          font-weight: 400;

          &::placeholder {
            color: var(--color-text1);
            font-size: 13px;
            font-weight: 400;
          }
        }
      }

      .oprator {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

        .hideMinAmount {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .changeMinAmount {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--cBoxActive-coin);

          p {
            font-size: 13px;
            font-weight: 400;
            color: var(--cBoxActive-coin);
          }
        }
      }
    }


    .tb_Wallet {
      max-height: 600px;
      min-height: 350px;

      table {
        // min-width: 870px;

        thead {
          background: var(--color-bg1);
        }

        tr:first-child {
          td {
            padding-top: 20px;
          }
        }

        tr {

          th,
          td {
            text-align: right;
            text-wrap: nowrap;
            white-space: nowrap;

            b {
              color: gray;
            }

            &:nth-child(1) {
              min-width: 220px;
              width: 220px;
              padding-right: 27px;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              min-width: 190px;
              width: 190px;
            }

            &:nth-child(5) {
              min-width: 220px;
              max-width: 220px;
              width: 220px;
              padding-left: 27px;
            }
          }

          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;

            >div {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
            }

            img {
              width: 24px;
              height: 24px;
            }

            h4 {
              font-size: 12px;
              font-weight: 400;

            }

            p {
              font-size: 12px;
              color: var(--color-gray);
              font-weight: 400;
            }
          }

          .priceItem {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start !important;
            flex-direction: column;
            direction: rtl;

            p {
              color: var(--color-gray12);
              font-size: 14px;
              font-weight: 400;
              text-align: center;
            }

            h4 {
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              direction: ltr;
              color: var(--txBody-color);
            }
          }

          .operator {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .opItem {
              width: 54px;
              padding: 5px 10px;
              text-align: center;
              border: none;
              color: var(--color-textOnlyWhite);
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 !important;
              font-size: 12px;
              cursor: pointer;

              &.receive {
                background-color: var(--color-green6);
              }

              &.send {
                background-color: var(--color-red1);
              }

              &.order {
                border: 1px solid var(--color-white23);
                background-color: var(--color-white);
                color: var(--txBody-color);
                position: relative;

                p {
                  color: var(--txBody-color);
                }

                .hoverBox {
                  position: absolute;
                  display: none;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 5px;
                  flex-direction: column;
                  background: var(--cBox-coin);
                  // padding: 10px 5px;
                  border-radius: 5px;
                  -webkit-border-radius: 5px;
                  -moz-border-radius: 5px;
                  -ms-border-radius: 5px;
                  -o-border-radius: 5px;
                  top: 20px;
                  z-index: 10;
                  transition: 0.4s ease;
                  -webkit-transition: 0.4s ease;
                  -moz-transition: 0.4s ease;
                  -ms-transition: 0.4s ease;
                  -o-transition: 0.4s ease;
                  box-shadow: 0px 0px 5px var(--color-textSlider);

                  a {
                    padding: 5px 6px;
                    text-align: center;
                    cursor: pointer;
                    opacity: 0.8;
                    width: 100%;
                    border: 1px solid var(--color-NO);
                    color: var(--txBody-color);
                    font-size: 11px;
                    font-weight: 400;

                    &:hover {
                      opacity: 1;
                      border-top: 1px solid var(--color-hoverNav);
                      border-bottom: 1px solid var(--color-hoverNav);
                      background: var(--color-hoverNav);
                      background: linear-gradient(90deg, var(--color-bghoverNav) 0%, var(--color-NO) 100%);
                    }
                  }
                }

                &:hover {
                  .hoverBox {
                    display: flex;
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}

.AuthPage {
  .container {
    padding: 0;
  }

  .authPage .auth {
    margin: 0 !important;
    max-width: 100%;

    .authBody .top {
      display: none;
    }

    .logoHeader {
      display: none;
    }

    .stepByStep {
      display: none;
    }
  }


}

.tb_View {
  width: 100%;
  overflow: auto;

  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;

    thead {
      display: table-header-group;

      tr {
        th>h5 {
          display: inline-block;
          font-size: 13px;
          font-weight: 400;

          // background-color: var(--color-hoverNav);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding: 3px 5px;
        }
      }
    }

    tbody {
      display: table-row-group;

      tr {
        border-bottom: 1px solid var(--color-br2);

        &:hover {
          background: var(--color-hoverNav);
          // cursor: pointer;
        }
      }

    }

    thead,
    tbody {
      tr {
        display: table-row;
        vertical-align: middle;

        th,
        td {
          display: table-cell;
          text-align: center;
          // padding: 10px;
          padding: 10px 5px;
          font-size: 13px;
          // padding-right: 15px;
          color: var(--color-gray6);
        }

      }
    }

  }
}

.googleAuth {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  direction: rtl;
  height: calc(100% - 109px);

  .top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    direction: rtl;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    padding: 19px 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    .textItemBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .rightEle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;

        .text {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          h4 {
            color: var(--color-black5);
            font-size: 15px;
            font-weight: 500;
            text-align: right;
            direction: rtl;
          }

          p {
            color: var(--color-black2);
            font-size: 14px;
            font-weight: 400;
            line-height: 21.7px;
            text-align: right;
            direction: rtl;
          }
        }

        img {
          max-width: 28px;
          max-height: 28px;
        }
      }

      .leftEle {
        a {
          padding: 10px 24px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;

          border: 1px solid var(--color-white18);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          cursor: pointer;
          transition: 0.3s ease;
          -webkit-transition: 0.3s ease;
          -moz-transition: 0.3s ease;
          -ms-transition: 0.3s ease;
          -o-transition: 0.3s ease;

          &:hover {
            background: var(--color-blue);
            color: var(--color-white);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            border: 1px solid var(--color-blue);
          }
        }

        .loadingCircular {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
        }
      }
    }
  }

  .center {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    direction: rtl;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    padding: 40px 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

.activeGA-st1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 19px 30px;
  direction: rtl;
  background: var(--color-white);
  box-shadow: 0px -3px 36.1px 0px var(--color-black6);
  padding: 40px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  p {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .listApps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;

    >img {
      width: 150px;
      height: 44.44px;
      cursor: pointer;
    }

  }

  .submitStep {
    background: var(--color-Boxblue1);
    color: var(--color-title);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 10px 24px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    width: 40%;
    cursor: pointer;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;

    &:hover {
      background: var(--color-blue16);
      color: var(--color-blue15);
    }
  }
}

.activeGA-st2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  direction: rtl;

  .warningTitle {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    gap: 7px;
    width: 100%;
    direction: rtl;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    h5 {
      color: var(--color-red1);
      font-size: 12px;
      font-weight: 600;
      text-align: right;

      b {
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          border-bottom: 1px solid var(--color-red1);
        }
      }
    }

    p {
      max-width: 741px;
      color: var(--color-black5);

      font-size: 12px;
      font-weight: 400;
      text-align: right;

    }
  }

  .body {
    padding: 30px;
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 16px;
    width: 100%;
    direction: rtl;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;


    h5 {
      max-width: 467px;
      color: var(--color-black5);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .imgQr {
      width: 139px;
      height: 139px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      direction: rtl;
      padding: 7px 10px 6px 6px;
      background: var(--cBox-coin);
      box-shadow: 0px 0px 0.5px 0px var(--color-black4);
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      width: 401px;

      p {
        color: var(--color-black5);

        font-size: 12px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        overflow: auto;
      }

      img {
        cursor: pointer;
      }
    }

    .submitStep {
      background: var(--color-Boxblue1);
      color: var(--color-title);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 10px 24px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border: none;
      width: 40%;
      cursor: pointer;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      -o-transition: 0.3s ease;

      &:hover {
        background: var(--color-blue16);
        color: var(--color-blue15);
      }
    }
  }

}

.activeGA-st3 {
  .bodyConfirm {
    padding: 30px;
    padding-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 16px;
    width: 100%;
    direction: rtl;
    background: var(--color-white);
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    h5 {
      color: var(--color-black5);
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      max-width: 467px;

      b {
        font-weight: 400;
        color: var(--color-green);
      }
    }

    .imgLock {
      width: 130px;
      height: 130px;
    }

    .imgSubmit {
      width: 100px;
      height: 100px;
    }

    .verCode {
      max-width: 40%;
      width: 40%;
      direction: ltr;
      min-width: 305px;
    }

    .submit {
      max-width: 40%;
      width: 40%;
      min-width: 305px;
      background-color: var(--color-green);
      color: var(--color-textOnlyWhite);
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      padding: 10px 24px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -ms-transition: 0.3s ease;
      -o-transition: 0.3s ease;

      &:hover {
        background-color: var(--color-green10);
      }
    }

    .loadingCircular {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40%;
      width: 40%;
      min-width: 305px;
      height: 41px;
      background-color: var(--color-green);
      text-align: center;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
  }

  .txtDeposit {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    color: var(--txBody-color);
  }
}

.detailLevelPage {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 39px;

  .cards {
    // display: flex;
    // justify-content: flex-start;
    // align-items: stretch;
    width: 100%;
    // gap: 30px;
    padding: 0 30px;

    .slick-slide>div {
      margin: 5px 10px;
      direction: rtl;
    }

    .slick-prev:before,
    .slick-next:before {
      color: var(--txBody-color) !important;
      font-size: 30px !important;
    }

    .cardItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      // width: calc(33% - 12px);
      flex: 1;
      // max-width: calc(33% - 12px);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border: 1px solid var(--color-No);
      background-color: var(--color-white);
      box-shadow: 0px 0px 10px 1px var(--color-gray21);

      &.level {

        &-old {
          border: 1px solid var(--color-green12);

          .headTop {
            background-color: var(--color-green12);

            h4 {
              color: var(--color-green12);
            }
          }

          .bodyDetail {
            .desDetail {
              h5 {
                color: var(--color-green12);
              }
            }
          }

          .desDetail {
            .horzDivider {
              background: var(--color-green12);
            }
          }

          .iconOk {
            display: unset !important;
          }

          .iconNot {
            display: none !important;
          }

          .iconReject {
            display: none !important;
          }
        }

        &-now {
          border: 1px solid var(--color-blue);

          .headTop {
            background-color: var(--color-blue);

            h4 {
              color: var(--color-blue);
            }
          }

          .bodyDetail {
            .desDetail {
              h5 {
                color: var(--color-green12);
              }
            }
          }

          .desDetail {
            .horzDivider {
              background: var(--color-green12) !important;
            }
          }

          .iconOk {
            display: unset !important;
          }

          .iconNot {
            display: none !important;
          }

          .iconReject {
            display: none !important;
          }

        }

        &-next {
          .headTop {
            background-color: var(--color-bg5);

            h4 {
              display: none;
            }

            h2 {
              color: var(--color-text4);
            }
          }

          .iconReject {
            display: none !important;
          }
        }

        &-reject {
          border: 1px solid var(--color-red);

          .headTop {
            background-color: var(--color-red);

            h4 {
              color: var(--color-red);
            }
          }

          .bodyDetail {
            .desDetail {
              h5 {
                color: var(--color-red);
              }
            }

            .rejectBtn {
              background: var(--color-red) !important;
              box-shadow: none !important;

              &:hover {
                background: var(--color-red3);
              }
            }
          }

          .desDetail {
            .horzDivider {
              background: var(--color-red) !important;
            }
          }

          .iconOk {
            display: none !important;
          }

          .iconNot {
            display: none !important;
          }

          .iconReject {
            display: unset !important;
          }

        }
      }

      .iconOk {
        display: none;
      }

      .headTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-area: 10px;
        padding: 29px 16px 35px 16px;
        border-radius: 8px 8px 0 0;
        -webkit-border-radius: 8px 8px 0 0;
        -moz-border-radius: 8px 8px 0 0;
        -ms-border-radius: 8px 8px 0 0;
        -o-border-radius: 8px 8px 0 0;
        background-color: var(--color-blue);
        margin-bottom: 7px;
        width: 100%;
        background-image: url(../../../public/icons/m-IconLogoHalfHide.svg);
        background-repeat: no-repeat;
        background-position-y: 22px;
        background-position-x: 96px;

        h2 {
          font-size: 20px;
          line-height: 24px;
          color: var(--color-textOnlyWhite);
          font-weight: 400;
          font-family: YekanBakh !important;
        }

        h4 {
          background-color: #fff;
          color: var(--color-blue);
          font-size: 12px;
          font-weight: 400;
          padding: 1px 10px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
        }
      }

      .bodyDetail {
        padding: 16px;
        width: 100%;

        .desDetail,
        .deslevel {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          padding-bottom: 18px;
          margin-bottom: 18px;
          border-bottom: 1px solid var(--color-grayLine2);

          h3 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
            color: var(--txBody-color);
          }

          h5 {
            font-size: 12px;
            font-weight: 600;
            color: var(--txBody-color);
            text-align: end;
            font-family: YekanBakh !important;
          }
        }

        .desDetail {
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
          }

          .horzDivider {
            height: 23px;
            width: 1px;
            background: var(--color-gray22);
            margin-right: 11px;
          }
        }

        .deslevel {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            >div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;

              h5 {
                color: var(--color-text3);
                text-align: start;
                font-weight: 500;
              }
            }
          }
        }

        .requsetLevel {
          p {
            font-size: 12px;
            font-weight: 400;
            color: var(--color-text3);
          }
        }

      }
    }
  }

  .needUpgradeMore {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-red1);
    }

    a {
      background: var(--color-red1);
      color: var(--color-textOnlyWhite);
      font-size: 16px;
      font-weight: 400;
      padding: 7px 10px;
      border: none;
      cursor: pointer;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      transition: 0.3s ease;

      &:hover {
        background: var(--color-red);
      }
    }
  }
}

.upgradeLevel {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  direction: rtl;
  height: calc(100% - 109px);

  &.loadingPage {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    video {
      width: 100%;
      height: auto;
    }
  }

  .steps {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    min-width: 88px;
    max-width: 88px;
    padding: 0 19px;
    direction: rtl;

    .item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      direction: rtl;
      position: relative;

      p {
        color: var(--color-text3);
        font-size: 13px;
        font-weight: 600;
        line-height: 21.13px;
        text-align: center;
      }

      .img {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-white);
        box-shadow: 0px 0px 25px 0px var(--color-black9);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }

      &.checkTick {


        &::before {
          content: "";
          position: absolute;
          top: -8px;
          right: -2px;
          height: 14px;
          width: 6px;
          border-bottom: 1px solid green;
          border-right: 1px solid green;
          transform: rotate(45deg);
        }

        .img {
          background: var(--color-green13);
        }

        p {
          color: var(--color-green12);
        }
      }


    }
  }

  .addAddressBox {
    flex: 1 1 auto;
    background: var(--color-white);
    padding: 26px 50px 79px 50px;
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    .nTitle {

      font-size: 15px;
      font-weight: 600;
      text-align: right;
      margin-bottom: 16px;
    }

    .sortSelect {
      max-width: 280px;
      margin-bottom: 20px;
      border: 1px solid var(--color-white23);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      .showBanck {
        justify-content: flex-start;
      }
    }

    .textarea {
      width: 100% !important;
      height: 170px !important;
      resize: none;
      padding: 11px 15px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21.64px;
      text-align: right;
      background: var(--color-white);
      color: var(--txBody-color);
      border: 1px solid var(--color-white23);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        line-height: 21.64px;
        text-align: right;
        color: var(--color-black2);
      }
    }

    .finalCheckBtn {
      margin-top: 20px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }
  }

  .uploadDoc {
    flex: 1 1 auto;
    background: var(--color-white);
    padding: 26px 50px 79px 50px;
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    direction: rtl;

    .warningTxt {
      font-size: 15px;

      font-weight: 400;
      margin-bottom: 30px;

      b {
        font-weight: 400;
        color: var(--color-red1);
      }
    }

    .ntitle {
      font-size: 15px;

      font-weight: 400;
    }

    .sortSelect {
      max-width: 280px;
      border: 1px solid var(--color-white23);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      .showBanck {
        justify-content: flex-start;
      }
    }

    .trueTxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 13px;
      direction: rtl;
      margin-top: 20px;
      margin-bottom: 12px;
    }

    .selectFile {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      gap: 10px;

      .sampleImg {
        width: 243px;
        height: 132px;
        object-fit: fill;
      }

      .selectBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 222px;
        height: 131px;
        cursor: pointer;

        p {
          color: var(--color-black2);
          text-align: center;
          font-size: 8.902px;
          font-style: normal;
          font-weight: 600;
          line-height: 13.759px;
        }
      }

      .fileDoc {
        display: none;
      }
    }

    .finalCheckBtn {
      margin-top: 20px;
    }

    .loadingCircular {
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-blue);
      border-radius: 13px;
      -webkit-border-radius: 13px;
      -moz-border-radius: 13px;
      -ms-border-radius: 13px;
      -o-border-radius: 13px;
      margin-top: 19px;
    }

    .rightItem {
      width: 222px;
      height: 131px;

      .drag {
        color: var(--color-gray3);
        overflow: hidden;
        width: 100%;
        border: 0.636px dashed var(--color-gray23);
        background: var(--color-gray24);
        text-align: center;
        position: relative;
        @extend%flexCenter;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;

        .fileUploader {
          width: 100%;
          height: 100%;

          label {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
            justify-content: center;
            align-items: center;
          }
        }

        .captions {
          margin: 15px 0;
          display: block;
        }

        button {
          background: var(--color-txt4);
          box-shadow: 0px 6px 37px 0px var(--color-gray21);
          color: var(--color-white);
          border: none;
          padding: 15px 10px;
          min-width: 300px;
          border-radius: 10px;
          transition: 0.3s ease;
          cursor: pointer;

          &:hover {
            background: var(--color-green);
          }
        }
      }

      .errorIamge {
        .erroring {
          border-radius: 50%;
          z-index: 2;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 30px;
          display: block;
          padding: 24px;
          transition: 0.3s ease;
          color: var(--color-white);
          cursor: pointer;
          background: var(--color-white42);
          width: 80px;
          height: 80px;

          &:hover {
            background: var(--color-white) !important;
            color: var(--color-orange1) !important;
          }
        }
      }

      .erroring {
        display: none;
      }

      .deleteimg {
        display: none;

        &:hover {
          background: var(--color-white) !important;
          color: var(--color-orange1) !important;
        }
      }

      .imguploaded {
        display: none;
        opacity: 0.5;
      }

      .img-fluid {
        width: 222px;
        height: 131px;
        object-fit: fill;
      }

      img {
        vertical-align: middle;
        border-style: none;
      }

      .uploaded .deleteimg {
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 30px;
        display: block;
        padding: 24px;
        transition: 0.3s ease;
        color: var(--color-white);
        cursor: pointer;
        background: var(--color-white42);
        width: 80px;
        height: 80px;
      }

      .uploading .showProgress {
        opacity: 1 !important;
        display: flex !important;
      }

      .showProgress {
        display: block;
        position: absolute;
        opacity: 0;
        display: none;

        &.textProgress {
          font-size: 17px;
          font-weight: 600;
          color: var(--color-white);
        }
      }

      .uploading,
      .uploaded,
      .errorIamge {
        transition: 0.3s ease;
        padding: 0 !important;
        border: none;
        background: var(--color-black);

        .imguploaded {
          display: block;
          opacity: 0.5;
        }

        .fileUploader {
          display: none;
        }
      }

    }
  }

  .roleUpgrade {
    flex: 1 1 auto;
    background: var(--color-white);
    padding: 40px 50px 79px 50px;
    box-shadow: 0px -3px 36.1px 0px var(--color-black6);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    direction: rtl;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .title1 {
      color: var(--color-black);
      text-align: right;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 16px;
    }


    .text {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      p {
        color: var(--txBody-color);
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        text-wrap: wrap;
        margin-bottom: 50px;
        line-height: 29px;
      }
    }

    .title2 {
      color: var(--txBody-color);
      text-align: right;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .inputBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 360px;

      button {
        background: var(--color-green);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        color: var(--color-textOnlyWhite);
        padding: 10.819px 14.426px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21.638px;
        cursor: pointer;
        border: none;
        height: 50px;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;

        &:hover {
          background: var(--color-blue);
        }
      }

      .loadingCircular {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-green);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        height: 50px;
        width: 81.84px;
      }

      input {
        height: 50px;
        flex: 1;
        padding: 10px;
        border: 1px solid #E6E8EC;
        background: var(--color-white);
        color: var(--txBody-color);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        // text-align: left;
        direction: ltr;
      }
    }
  }
}

.transactionHistory {
  @extend %flexStart;
  flex-direction: column;
  max-width: 100%;
  flex: 0 0 100%;
  margin: 16px auto;
  margin-bottom: 0;
  background: var(--color-white);
  padding: 32px 37px 32px 43px;
  border-radius: 13px;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);
  min-height: calc(100% - 109px);
  overflow: auto;

  .changeNavStyle {
    margin-bottom: 30px;
  }

  .tb_OrderPro {
    max-height: 600px;
    min-height: 300px;

    table {
      // min-width: 1180px;

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;

          b {
            font-weight: 400;
            font-size: 13px;
          }

          // &:nth-child(1) {
          //   padding-right: 0;
          //   min-width: 102px;
          // }

          &:nth-child(1) {
            min-width: 135px;
          }

          &:nth-child(2) {
            min-width: 120px;
          }

          &:nth-child(3) {
            min-width: 97px;
          }

          &:nth-child(4) {
            min-width: 67px;
          }

          &:nth-child(5) {
            min-width: 155px;
            direction: ltr;

            b {
              margin-left: 2px;
            }
          }

          &:nth-child(6) {
            min-width: 135px;
            direction: ltr;

            b {
              margin-left: 2px;
            }
          }

          // &:nth-child(8) {
          //   min-width: 135px;
          // }
          &:nth-child(7) {
            min-width: 145px;
            direction: ltr;

            b {
              margin-left: 2px;
            }
          }

          &:nth-child(8) {
            min-width: 85px;
          }

          &:nth-child(9) {
            min-width: 70px;
          }

          .btnDetail {
            min-width: 50px;
            text-align: center;
          }

        }

        #name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            width: 20px;
            height: 20px;
          }

          .imgPair {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            width: 27px;

            img {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            .coin {
              position: absolute;
              top: -5px;
              right: 0;
            }
          }


          h5 {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            text-align: right;
            color: var(--color-text);
          }

          p {
            font-size: 12px;
            font-weight: 300;
            line-height: 24px;

            text-align: right;
            color: var(--color-gray11);
          }
        }

        #cancleOrder {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          direction: rtl;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          border: 1px solid #00000000;
          padding: 4px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;

          &:hover {
            opacity: 0.7;
            border: 1px solid var(--color-red7);
          }

          img {
            width: 18px;
            height: 18px;
          }

          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;

            text-align: right;
            color: var(--color-red7);
          }
        }

        #complete,
        #pending,
        #reject,
        #waiting,
        #cancel,
        #checking {
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          color: var(--color-textOnlyWhite);
          background: var(--color-green);
          padding: 3px 7px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }

        #pending {
          // background: var(--color-yellow);
          background: var(--color-yellow7) !important;
        }

        #waiting {
          color: var(--color-textOnlyWhite) !important;
          background: var(--color-yellow7) !important;
        }

        #checking {
          background: var(--color-white14);
        }

        #reject,
        #cancel {
          background: var(--color-red1);
        }
      }
    }

  }

  .filter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;

    .btnSearchFilter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      color: var(--color-textOnlyWhite);
      background: var(--color-blue);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;

      &:hover {
        background: var(--color-green);
      }
    }

    .iconClose {
      cursor: pointer;
      background: var(--color-red);
    }

    .customInput {
      width: 170px;
      padding: 5px 10px;

      .textViewBox {
        .showCoinSearch {
          padding: 0;
        }
      }

      .listCoinPopup .item>p {
        display: none;
      }
    }

    .rmdp-input {
      padding: 5px 10px;
      background-color: var(--color-white);
      border: 1px solid var(--color-brInput);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      height: 34px;
      margin: 0;
      width: 170px;
    }

    .styleBankSelect .textViewBox h4 {
      font-size: 12px;
      color: var(--txBody-color);
      text-align: right;
    }

    .showCoin {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      gap: 15px;

      img {
        width: 24px;
        height: 24px;
      }

      h4 {
        padding: 0;
        flex: 0;
        font-size: 14px;
        font-weight: 400;

        position: relative;
        top: 2px;
        flex: 1 0 auto;
        text-align: right;

        b {
          color: var(--color-black2);
          font-weight: 400;
        }
      }
    }
  }

  .historyBoxAll .tb_Receive,
  .historyBoxAll .tb_Send {
    max-height: 600px;
  }

}


//////////////////// Dialog confirmUpgrade
.confirmUpgrade {
  width: 400px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  img {
    width: 185px;
    height: 185px;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    color: var(--txBody-color);
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: var(--txBody-color);
  }

  .voucherCode {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-bg2);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 10px 0;
    padding: 10px 15px;
  }
}

.needLogin {
  padding: 0px 40px 40px 40px !important;
  width: 440px;
  gap: 12.5px;
  background-image: url(../../../public/icons/bg_needLogin.svg);
  background-repeat: no-repeat;

  .divider {
    width: 100px;
    height: 1px;
    background: var(--color-white39);
  }

  img {
    margin-top: 30px;
    width: 291px;
    height: 331px;
  }

  h4 {
    font-size: 16px;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-text5);
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
    background: var(--color-blue);
    cursor: pointer;
    padding: 11px 16px;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border: none;
    margin-top: 5px;

    p {
      color: var(--color-textOnlyWhite);
      font-size: 16px;
      font-weight: 400;
    }

    &:hover {
      background: var(--color-green);
    }
  }
}

///////////////// Dilaog verifyRole
.verifyRole {
  width: 500px;
  padding: 30px 16px;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 25px;
    direction: rtl;

    p {
      color: var(--txBody-color);
      font-size: 14px;
      font-weight: 400;
    }
  }

  h5 {
    color: var(--txBody-color);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.638px;
    text-wrap: wrap;
    margin-bottom: 16px;
  }

  .codeNumberBox {
    margin-bottom: 16px;
    padding-left: 58px;
    padding-right: 58px;

    .finalCheckBtn {
      background-color: var(--color-green);
      height: 40px;
      padding: 0;
    }

    .progress-btn {
      margin-top: 20px;
    }
  }

}

////////////////// Dialog fullScreenMap
.fullScreenMap {
  width: calc(100vw - 104px);
  height: calc(100vh - 100px);
  padding: 10px;

}


///////////////////// Dialog Add GoogleAuth
.addGoogleAuth {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 385px;
  padding: 30px 40px;
  background: var(--color-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  direction: rtl;

  h5 {
    color: var(--txBody-color);
    font-size: 14px;
    font-weight: 500;
    text-align: right;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: var(--txBody-color);

    b {
      color: var(--color-title);
      font-weight: 400;
    }

  }

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }

  .verCode {
    width: 100%;
    direction: ltr;
  }

  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: flex-end;
    color: var(--txBody-color);

    p {
      font-family: YekanBakh !important;

    }
  }

  .submit {
    width: 100%;
    height: 40px;
    padding: 10px 24px;
    background: var(--color-green);
    color: var(--color-textOnlyWhite);

    font-size: 14px;
    font-weight: 400;
    text-align: center;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
  }
}


/* ///////////// Dialog Style Public */

.titleCloseDialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  direction: rtl;
  background-color: var(--cBox-coin);

  >h3 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--color-black);
  }
}

/* ///////////dialog AddPhone */
.contentDetailDialog {
  display: flex;
  flex-direction: column;
  gap: 16px;
  direction: rtl;
  width: 400px;


  .textInfoDialog {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .textInfoDialog span {
    padding: 10px;
    align-self: flex-start;
    position: relative;
    top: 2px;
    background-color: var(--color-orange2);
  }

  .textInfoDialog h6 {
    font-size: 12px;
    color: var(--color-text2);
    font-weight: 400;
  }

  button {
    width: 100%;
    color: var(--color-textOnlyWhite);
    background-color: var(--color-blue);
    font-size: 14px;
    margin-top: 16px;
    font-weight: 400;
    padding: 7px 0 9px 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    border: none;
  }

  .loadingCircular {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 37px;
    background-color: var(--color-blue);
    margin-top: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

}

/* //////////Dialog Add Email */
.dialogAddEmail {
  height: 100%;
  justify-content: space-between;
}


/* //////////DialogWarning */

.dialogWarningTitel {
  padding: 16px !important;
  flex: unset;
  direction: rtl;
  background-color: var(--cBox-coin);

  .titleCloseDialog {
    padding: 0;
  }

  .textWarning {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    h4 {
      font-size: 15px;
      font-weight: 400;
      color: var(--txBody-color);
    }
  }
}

.dialogWarning {
  width: 500px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 30px;
  direction: rtl;

  p {
    color: var(--txBody-color);
    text-align: right;
    font-size: 15px;
    line-height: 29px;

  }

  .btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .btns button {
    flex: 1;
    color: var(--color-title);
    border: 1px solid var(--color-blue);
    background-color: var(--color-white);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 7px 0 9px 0;
    cursor: pointer;
  }

  .btns button:hover {
    background-color: var(--color-textCoin);
  }

  .btns .btOk:hover {
    background-color: var(--color-blue14);
  }

  .btns .btOk {
    color: var(--color-textOnlyWhite);
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
  }

  .loadingCircular {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    height: 40px;
    width: 100%;
  }
}

/* /////////////Dialod detailWithdraw */
.detailWithdrawLogoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: var(--color-blue);
  width: 100%;
  padding: 12px 15px !important;
  height: 170px !important;
  flex: 1 1 170px !important;
  overflow: unset !important;

  // &.newStyle {
  //   background-color: var(--color-bg6);

  //   .iconClose {
  //     background-color: var(--color-black);
  //   }
  // }

  img {
    width: 155px;
    height: 148px;
  }

  .iconClose {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    background-color: var(--color-white);
  }
}

.detailWithdraw {
  width: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  direction: rtl;

  .warningOrderFast {
    width: 100%;
    margin: 5px 0 10px 0;

    p {
      font-size: 13px;
      font-weight: 400;
      color: var(--color-orange1);
      position: relative;
      padding-right: 15px;

      &::before {
        position: absolute;
        top: 0;
        right: 0px;
        content: "*";
        color: var(--color-red);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .btnAddDialog {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: var(--color-blue);
      color: var(--color-textOnlyWhite);
      font-size: 13px;
      font-weight: 400;
      cursor: pointer;
      transition: 0.3s ease;
      border: none;
      padding: 5px 10px;
      margin: 5px 0 10px 0;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;

      &:hover {
        background: var(--color-green);
      }
    }
  }

  .addListWithdraw {
    width: 400px;

    .stepDepWith {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      p {
        width: 25px;
        height: 27px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-Boxblue1);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;

        span {
          font-size: 15px;
          font-weight: 400;
          color: var(--color-title);
          font-family: YekanBakh !important;
        }
      }

      h5 {
        color: var(--color-black5);
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
      }
    }

    .customInputSelect .textViewBox {
      justify-content: space-between !important;

      h4 {
        color: var(--color-black2);
        font-weight: 400;
      }
    }

    .finalCheckBtn {
      margin-top: 0;
    }

    .loadingCircular {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 61px;
      margin-top: 15px;
      border-radius: 13px;
      background: var(--color-blue);
    }
  }

  .tb_ListWithdraw {
    padding-bottom: 20px;
    min-width: 630px;
    height: 300px;

    table {
      min-width: 530px;

      td,
      tr {
        color: var(--txBody-color);
      }

      .iconClose {
        background: red;
        width: 10px;
        height: 10px;

        &:hover {
          background-color: #ff6d6d;
        }
      }
    }

    .selectItem {
      color: var(--color-green);
      cursor: pointer;
      border: 1px solid var(--color-brInput);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      padding: 3px 10px !important;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;

      &:hover {
        color: var(--color-green10);
        border: 1px solid var(--color-green10);
      }
    }
  }

  .body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px !important;
    direction: rtl;
    width: 100%;
    // height: 895px;
    // overflow: auto;

    .step {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 14px;
      position: relative;

      &.confirm {
        .textDate h4 {
          color: var(--color-green);
        }
      }


      .circlePoint {
        width: 26px;
        height: 26px;
        border: 2px solid var(--color-blue);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.confirm,
        &.complete {
          border: 2px solid var(--color-green);

          span {
            background-color: var(--color-green);
          }
        }

        &.reject {
          border: 2px solid var(--color-red1);

          span {
            background-color: var(--color-red1);
          }
        }

        &.pending {
          border: 2px solid var(--color-yellow1);

          span {
            background-color: var(--color-yellow1);
          }
        }


        &.waiting {
          border: 2px solid var(--color-yellow1);

          span {
            background-color: var(--color-yellow1);
          }
        }


        &.checking {
          border: 2px solid var(--color-gray20);

          span {
            background-color: var(--color-gray20);
          }
        }


        &.process,
        &.checking {
          border: 2px solid var(--color-orange1);

          span {
            background-color: var(--color-orange1);
          }
        }


        span {
          display: block;
          background-color: var(--color-blue);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }

      .textDate {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3px;
        flex-direction: column;

        h4 {
          color: var(--color-black5);
          font-size: 14px;
          font-weight: 700;
          text-align: right;
        }

        p {
          color: var(--color-black2);
          font-size: 14px;
          font-weight: 600;
          line-height: 21.7px;
          text-align: right;
        }
      }

      .linerStep {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 60%;
        right: 12px;

        img {
          height: 20px;
        }
      }
    }

    .textDec {
      font-size: 14px;
      font-weight: 600;
      line-height: 21.7px;
      text-align: right;
      color: var(--txBody-color);
      margin-right: 42px;
      margin-top: 9px;
      margin-bottom: 20px;
      max-width: 323px;

      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 21.7px;
        text-align: right;
        color: var(--color-title);
        text-decoration: underline;
        margin: 0 5px;
      }

    }

    .reportWarning {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;

      p {
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        color: var(--color-red7);
      }
    }

    .divder {
      width: 100%;
      background-color: var(--color-divider2);
      margin-top: 21px;
      margin-bottom: 27px;
    }

    .dec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 17px;

      &.vouBox {
        position: relative;
        padding: 10px 0;

        .brBox {
          position: absolute;
          width: calc(100% + 30px);
          right: -15px;
          height: 100%;
          // border: 1px solid var(--color-divider2);
          box-shadow: 0px 1px 2px 1px var(--color-shadow2);
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
        }
      }

      >div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
      }

      p {
        color: var(--color-black5);
        font-size: 14px;
        // font-weight: 500;
        font-weight: 400;


        b {
          font-weight: 400;
          font-size: 12px;
        }
      }

      .typeCoin {
        img {
          width: 22px;
          height: 22px;
        }
      }

      .shaba {
        p {
          font-size: 12px;
          max-width: 270px;
          overflow: auto;
        }
      }

      .status {
        p {
          color: var(--color-green);
          font-size: 14px;
          font-weight: 700;
          text-align: right;
        }
      }
    }

    .warningWithdraw {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 2px;

      h5 {
        font-size: 11px;
        font-weight: 400;
        word-spacing: -0.5px;
        color: var(--txBody-color);
        position: relative;
        padding-right: 10px;
        line-height: 28px;

        &::before {
          position: absolute;
          right: -4px;
          top: -1px;
          content: "*";
          font-size: 16px;
          color: red;
        }
      }
    }

    .addCard {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 25px;

      h3 {
        font-size: 14px;
        color: var(--txBody-color);
        margin-bottom: 15px;
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;

      .finalCheckBtn {
        padding: 10px;
      }

      .btnCancel2 {
        background: var(--color-gray);
        // border: 1px solid var(--color-blue);
        color: var(--color-textOnlyWhite);

        &:hover {
          background: var(--color-red3) !important;
        }
      }

      .btnCancel {
        background: var(--color-gray);

        &:hover {
          background: var(--color-red3) !important;
        }
      }
    }

    .loadingCircular {
      width: 100%;
      min-width: 186px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 61px;
      margin-top: 15px;
      border-radius: 13px;
      background: var(--color-blue);

      &.h41 {
        height: 41px;
      }
    }
  }

  .divider {
    width: 100%;
    margin: 10px 0;
    height: 1px;
    background: var(--color-divider2);
  }

  .checkBoxDialog {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    margin-bottom: 15px;

    label {
      font-size: 13px;
      color: var(--txBody-color);
      font-weight: 400;
      cursor: pointer;
    }
  }

}

.verfiyEmailUser {
  width: 450px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 52px 0px var(--color-blue4);
  padding: 30px;

  .textvr {
    font-size: $fontsize2;
    color: var(--color-gray);
    font-weight: 400;
    margin-bottom: 20px;
    display: block;
    text-align: right;
    direction: rtl;
  }

  .loadingCircular {
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-blue);
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
  }
}

.topUpgradePage {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 0 15px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--txBody-color);
  }

  .force {
    &::before {
      content: "*";
      color: red;
      padding-left: 2px;
    }
  }

  .textareaBox {
    width: 100%;
    height: 193px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background: var(--color-white);
    padding: 12px;
    margin-bottom: 26px;

    textarea {
      resize: none;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 14px;
      font-weight: 400;
      color: var(--txBody-color);
      background: transparent;

      &::placeholder {
        color: var(--color-text4);
      }
    }
  }

  .fileUploadBox {
    width: 278px;
    height: 278px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    padding: 59px 36px;

    h4 {
      background: var(--color-bgDone);
      color: var(--color-textDone);
      font-size: 14px;
      font-weight: 400;
      padding: 8px 16px;
      border-radius: 32px;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;
      -ms-border-radius: 32px;
      -o-border-radius: 32px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .finalCheckBtn {
    background: var(--color-green);
    padding: 17px;
    width: 286px;
    margin-right: auto;
    margin-top: 20px;
  }


  .loadingCircular {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-green);
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
    height: 55px;
    width: 286px;
    margin-right: auto;
    margin-top: 20px;
  }
}

.checkUpgradePage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  align-self: flex-start;
  padding: 50px;
  background: var(--color-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  .finalCheckBtn {
    background: var(--color-green);
    padding: 10px;
    width: 304px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
  }

}

.learning {
  background: var(--color-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 55px 63px;

  .divider {
    border-bottom: 1px solid var(--color-grayLine2);
    width: 100%;
    flex: 1;
  }

  .firstPage {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 71px;

    .titleSearchBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      flex-direction: column;
      align-self: center;

      h3 {
        font-size: 18px;
        color: var(--txBody-color);
        font-weight: 400;
      }

      .itemSearch {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 480px;
        position: relative;

        button {
          border: none;
          font-size: 12px;
          font-weight: 400;
          padding: 11px 10px;
          background: var(--cBoxActive-coin);
          color: var(--color-textOnlyWhite);
          border-radius: 8px 0 0 8px;
          -webkit-border-radius: 8px 0 0 8px;
          -moz-border-radius: 8px 0 0 8px;
          -ms-border-radius: 8px 0 0 8px;
          -o-border-radius: 8px 0 0 8px;
          height: 46px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-green);
          }
        }

        .inputBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          flex: 1;
          border-radius: 0 8px 8px 0;
          -webkit-border-radius: 0 8px 8px 0;
          -moz-border-radius: 0 8px 8px 0;
          -ms-border-radius: 0 8px 8px 0;
          -o-border-radius: 0 8px 8px 0;
          background: var(--color-bg1);
          padding: 11px 10px;
          height: 46px;

          input {
            background: transparent;
            border: none;
            flex: 1;
          }
        }

        .listSearchBox {
          position: absolute;
          top: 55px;
          right: 0;
          z-index: 1001;
          background: var(--color-bg1);
          width: 100%;
          border-radius: 0 0 10px 10px;
          -webkit-border-radius: 0 0 10px 10px;
          -moz-border-radius: 0 0 10px 10px;
          -ms-border-radius: 0 0 10px 10px;
          -o-border-radius: 0 0 10px 10px;
          max-height: 200px;
          overflow-y: auto;
          padding: 10px;
          display: none;

          &.show {
            display: unset;
          }

          .list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;
            width: 100%;


            .item {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 5px;
              cursor: pointer;
              width: 100%;
              padding: 10px 25px;
              background: var(--color-white);
              border: 1px solid var(--color-NO);
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;

              &:hover {
                border: 1px solid var(--color-bghoverNav);
                box-shadow: 0 0 10px 1px var(--color-bghoverNav);
              }

              h4 {
                font-size: 12px;
                font-weight: 500;
              }

              h5 {
                font-size: 11px;
                color: var(--color-text2);
              }
            }

          }
        }
      }

      .textDefault {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        p {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-black2);

          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &.onHover:hover {
            color: var(--color-black5);
            cursor: pointer;
          }
        }
      }
    }

    .btnsHelp {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 50px;
      row-gap: 32px;
      width: 100%;
      direction: ltr;

      .item {
        width: calc(25% - 50px);
        // width: 25%;
        min-width: 178px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        cursor: pointer;

        &:hover {
          .imgBox {
            border-color: var(--color-br1);
          }
        }

        h4 {
          font-size: 16px;
          font-weight: 400;
          color: var(--txBody-color);
        }

        .imgBox {
          padding: 27px 59px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border: 1px solid var(--color-bg2);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
        }
      }
    }

    .defaultQustion {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 72px;
      width: 100%;

      .accordionList {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;

        h3 {
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: 400;
          color: var(--txBody-color);
        }

        h4 {
          font-size: 16px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        h5 {
          font-size: 14px;
          font-weight: 300;
          color: var(--txBody-color);
        }

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 46px;
          padding: 16px;
          flex: 1;
          width: 100%;

          &:hover {
            box-shadow: 0 0 10px 1px var(--color-bghoverNav);
            background: var(--color-bghoverNav);
          }

          &.show {
            background: var(--color-bgQuestion);
          }

          .textAccord {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 8px;

            .detail {
              transition: 0.6s ease-in-out;
              -webkit-transition: 0.6s ease-in-out;
              -moz-transition: 0.6s ease-in-out;
              -ms-transition: 0.6s ease-in-out;
              -o-transition: 0.6s ease-in-out;
              overflow: hidden;
            }
          }

          .selectIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            background: var(--color-bgPlus);
            cursor: pointer;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            span {
              transition: 0.1s ease-in-out;
              -webkit-transition: 0.1s ease-in-out;
              -moz-transition: 0.1s ease-in-out;
              -ms-transition: 0.1s ease-in-out;
              -o-transition: 0.1s ease-in-out;
            }

            &.show {
              background: var(--color-blue);

              span {
                background: var(--color-textOnlyWhite);
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }

  .authLearningPage {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;

    .titleBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      h3 {
        font-size: 18px;
        font-weight: 600;
      }

      .back {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-black);
        cursor: pointer;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;

        &:hover {
          background: var(--color-Boxblue1);
          border-color: var(--color-Boxblue1);
        }
      }
    }



    .textQuesBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 71px;
      width: 100%;
      row-gap: 16px;
      flex-wrap: wrap;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 16px;
        flex: 0 0 auto;
        cursor: pointer;

        h4 {
          font-size: 16px;
          font-weight: 400;
          font-family: YekanBakh !important;
        }

        &:hover {
          background: var(--color-bgQuestion2);

          h4 {
            color: var(--color-title);
          }
        }
      }

    }
  }

  .signUpLearningPage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;


    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      font-family: YekanBakh !important;

      b {
        font-weight: 400;
        color: var(--cBoxActive-coin);
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      font-family: YekanBakh !important;
      color: var(--cBoxActive-coin);
    }
  }
}

.notifLsit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background: var(--color-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 55px 30px;
  height: 100%;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-txt4);
    text-align: center;
    align-self: center;
  }

  .btnsControl {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    margin-bottom: 20px;

    button {
      border: none;
      padding: 7px 10px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      background: var(--color-bg6);
      color: var(--txBody-color);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.active {
        background: var(--color-yellow5);
        color: var(--color-textOnlyWhite);
      }
    }
  }

  .itemBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 33px;
    border-bottom: 1px solid var(--color-bg6);
    width: 100%;

    &.unRead {
      background: var(--color-bg1);

      h4 {
        margin-right: -15px;
      }

      h4::before {
        content: "⚈";
        color: var(--color-red);
        padding-left: 5px;
        position: relative;
        top: 3px;
      }
    }


    .item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;




      h4 {
        font-size: 15px;
        font-weight: 500;
      }

      h5 {
        font-size: 400;
        font-weight: 400;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: var(--color-txt4);
      }
    }
  }
}

.detailsUser {
  width: 100% !important;

  .auth {
    width: 100% !important;
    height: 100%;
    margin: 0 !important;
  }
}

.verifyWithdrawCoin {
  .body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 430px;
    // direction: rtl;

    .upTitle {
      text-align: center;
      color: var(--txBody-color);

      h5 {
        margin-bottom: 7px;
        color: var(--txBody-color);
      }
    }

    .inputs {
      width: 100%;
      color: var(--txBody-color);

      .text-right {
        text-align: right;
      }

      .codeNumberBox {
        margin-top: 7px;
      }
    }
  }
}

.noticeList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--color-white);
  direction: rtl;
  height: 100%;

  .head {
    width: 100%;
    position: fixed;

    .top {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding: 15px 20px;
      background: var(--color-bg1);
      z-index: 1001;

      p {
        font-size: 13px;
        color: var(--txBody-color);
      }

      .iconClose {
        width: 15px;
        height: 15px;
      }
    }
  }

  .body {
    background: var(--color-white);
    padding: 14px 15px;
    width: 100%;
    margin-top: 50px;

    .listItem {
      width: 100%;
      background: var(--color-white);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      flex-direction: column;

      .item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        // border-bottom: 1px solid var(--color-bg2);
        // margin-bottom: 20px;

        p {
          font-size: 12px;
          color: var(--color-text2);
          direction: rtl;
          font-family: YekanBakh !important;
          // position: relative;


          &.isRead {
            padding-right: 7px;
          }

          &.isRead::before {
            content: "⚈";
            font-size: 9px;
            color: red;
            margin-left: 7px;
            // position: absolute;
            // right: 0;
            // bottom: 0;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          justify-content: flex-start;
          align-items: flex-start;
          background: var(--color-bg1);
          padding: 10px 9px;
          border-radius: 7px;
          -webkit-border-radius: 7px;
          -moz-border-radius: 7px;
          -ms-border-radius: 7px;
          -o-border-radius: 7px;
          width: 100%;

          h4 {
            font-size: 13px;
            font-weight: 500;
            color: var(--txBody-color);
          }

          h5 {
            font-size: 12px;
            font-weight: 400;
            color: var(--txBody-color);
            text-align: justify;
          }
        }


      }
    }
  }
}

.alertBody {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 18px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    p {
      font-size: 16px;
      color: var(--color-title);
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--color-br3);
  }

  .p1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-black5);
    margin-bottom: -8px;
  }

  .coinAlert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 24px;
    gap: 16px;
    width: 40%;
    max-width: 40%;
    min-width: 40%;
    background: var(--color-white);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 10px var(--color-shadow);

    .customInputSelect .textViewBox {
      justify-content: space-between !important;
    }

    .customInput {
      border-color: var(--color-br3) !important;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

    .btnsPairs {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .btn {
        min-width: 55px;
        text-align: center;
        background: none;
        border: 1px solid var(--color-br3);
        padding: 4px 10px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        color: var(--txBody-color);

        &:hover {
          border: 1px solid var(--color-blue);
        }

        &.active {
          border: 1px solid var(--color-blue);
          background: var(--color-blue);
          color: var(--color-textOnlyWhite);
        }
      }
    }

    .priceNow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      width: 100%;
      padding: 5px;

      &::before {
        content: "⚈";
        font-size: 12px;
        color: var(--color-red1);
        position: relative;
        top: 1px;
      }

      .tx {
        font-size: 14px;
        font-weight: 400;
        color: var(--txBody-color);
      }

      .price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        margin-right: auto;

        .t1 {
          opacity: 0.7;
          font-size: 13px;
          position: relative;
          top: 2px;
        }
      }
    }

  }

  .priceAlert {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    width: calc(60% - 20px);
    max-width: calc(60% - 20px);
    min-width: calc(60% - 20px);

    .chartCoin {
      width: 100%;
      height: 383px;
      min-height: 383px;
      padding: 28px;
      background: var(--color-white);
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -ms-border-radius: 16px;
      -o-border-radius: 16px;
      box-shadow: 0 0 10px var(--color-shadow);
      display: flex;
      justify-content: center;
      align-items: center;

      .iframeTradingViewWidget {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: none;
        border: none;
        outline: none;
      }
    }

    .priceBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 20px 24px;
      gap: 16px;
      background: var(--color-white);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      box-shadow: 0 0 10px var(--color-shadow);


      .inputBox {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid var(--color-br3);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        input {
          width: 100%;
          background: transparent;
          border: none;
        }
      }

      .customInput {
        padding: 8px 16px;
        border: 1px solid var(--color-br3);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        .inputNumeric::placeholder {
          text-align: right;
        }
      }

      .subBtn {
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        color: var(--color-textOnlyWhite);
        background: var(--color-green);
        padding: 12px;
        border: none;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;

        &:hover {
          background: var(--color-blue);
        }
      }

      .loadingCircular {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45.6px;
        text-align: center;
        font-size: 14px;
        color: var(--color-textOnlyWhite);
        background: var(--color-green);
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
    }
  }

}

.historyAlert {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px 24px;
  background: var(--color-white);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  box-shadow: 0 0 10px var(--color-shadow);
  margin-top: 32px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    p {
      font-size: 16px;
      color: var(--color-title);
    }
  }

  .listBox {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;
    min-height: 195px;
    width: 100%;

    .divider {
      width: 100%;
      height: 1px;
      background: var(--color-br3);
    }

    .fetchListLoad .emptyLoad {
      flex-direction: row;
    }

    .fetchListLoad .emptyLoad:has(p) {
      flex-direction: column;
    }

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      min-width: 250px;
      flex: 1 1 auto;
      max-width: 50%;
      border: 1px solid var(--color-br3);

      .deleteIcon {
        cursor: pointer;
        align-self: flex-end;
      }

      .topTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .infoBox {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          cursor: pointer;

          .t1 {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }

      .itemTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 6px;

        .t1 {
          font-weight: 500;
          font-size: 14px;
          padding: 4px 6px;
          font-family: YekanBakh !important;
          color: var(--txBody-color);

          &.t2 {
            opacity: 0.7;
            font-weight: 400;
          }
        }

        .txBox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          gap: 4px;
        }
      }

      .txtBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;

        .coinIcon {
          width: 24px;
          height: 24px;
        }

        .txtCode {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .icon-IconCopy16 {
            position: relative;
            top: -4px;
          }
        }

        .p1 {
          font-weight: 400;
          font-size: 14px;
          padding: 4px 6px;
          font-family: YekanBakh !important;
          color: var(--txBody-color);

          &.red {
            color: var(--color-red1);
          }

          &.gray {
            color: var(--color-blackLight);
          }
        }

        .coin {
          background: var(--color-green14);
          color: var(--color-green);
          font-weight: 500;
          padding-top: 6px;
        }
      }
    }
  }
}


.giftBody {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  gap: 20px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    p {
      font-size: 16px;
      color: var(--color-title);
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--color-br3);
  }

  .p1 {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-black5);
    margin-bottom: -8px;
  }

  .p2 {
    color: var(--color-red1);
  }

  .useGift {
    .imgBox {
      img {
        max-width: 306px !important;
      }
    }
  }

  .creatGift {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 24px;
    gap: 10px;
    width: 100%;
    background: var(--color-white);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 0 0 10px var(--color-shadow);

    .customInputSelect .textViewBox {
      justify-content: space-between !important;
    }

    .customInput {
      border-color: var(--color-br3) !important;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

    .eleBox {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      width: calc(60% - 15px);
      max-width: calc(60% - 15px);
      min-width: calc(60% - 15px);

      .priceBox {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px 24px;
        gap: 20px;

        .inputBox {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid var(--color-br3);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;

          .tel::placeholder {
            text-align: right;
          }

          input {
            width: 100%;
            background: transparent;
            border: none;
          }

          >div {
            width: 100%;
          }
        }

        .subBtn {
          width: 100%;
          text-align: center;
          cursor: pointer;
          font-size: 14px;
          color: var(--color-textOnlyWhite);
          background: var(--color-green);
          padding: 12px;
          border: none;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-blue);
          }
        }

        .loadingCircular {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45.6px;
          text-align: center;
          font-size: 14px;
          color: var(--color-textOnlyWhite);
          background: var(--color-green);
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
        }
      }
    }

    .imgBox {
      width: 40%;
      max-width: 40%;
      min-width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-width: 202px;
      }
    }
  }

  .customSelectGift {
    .btnPopUp {
      .icon-Arrow2 {
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;

        &.rotate {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
    }

    .textViewBox {
      height: auto;
    }

    .showCoinsSelect {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      flex-wrap: wrap;

      .def {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-text4);
      }

      .itemSelect {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: fit-content;
        padding: 4px 6px;
        background: var(--color-bg2);

        p {
          font-size: 14px;
          font-weight: 400;
          color: var(--txBody-color);
        }


        >svg {
          margin-right: 3px;
        }
      }
    }

    .listCoinGift {

      .item {
        min-height: 65px;
        height: 65px;

        .balance {
          text-align: center;

          p {
            font-size: 12px;
            color: var(--txBody-color);
            font-weight: 400;
          }
        }

        .btnAdd {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1px;
          border: 1px solid var(--color-green);
          color: var(--color-green);
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          font-size: 13px;
          padding: 4px 6px;


          &:hover {
            border: 1px solid var(--color-orange2);
            color: var(--color-orange2);

            .icon_Onlyplus {
              background-color: var(--color-orange1);
            }
          }

          .icon_Onlyplus {
            background-color: var(--color-green);
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
          }
        }

        &:hover {
          background: unset !important;
        }

        &:focus-within {
          .btnAdd {
            display: flex;
          }
        }

        .name {
          height: 100%;
          flex: 1 1 auto;

          .inputNumeric {
            flex: 1 1 auto;
            margin: 0 8px;
            padding-inline: 10px;
            height: 100%;
            // background: var(--color-bg1);
            border: 1px solid var(--color-br1);
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
          }
        }

      }

      .emptyListGift {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding: 20px 10px;

        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
        }

        p {
          font-size: 13px;
          font-weight: 400;
          text-align: center;
        }
      }
    }

  }

}

.invitationBody {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;

  .txTop {
    font-size: 15px;
    font-weight: 600;
    color: var(--txBody-color);
    position: relative;
    padding-bottom: 3px;
    margin-bottom: 11px;

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 63px;
      bottom: 0;
      right: 0;
      background: var(--color-title);
    }
  }

  .txDetail {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-blackLight);
    margin-bottom: 33px;
  }

  .titleBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    .s1 {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-Boxblue1);
      color: var(--color-title);
      font-size: 15px;
      font-weight: 500;
      padding: 2px 10px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }

    .s2 {
      font-size: 15px;
      font-weight: 600;
      color: var(--txBody-color);
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--color-br3);
    margin: 25.2px 0 29px 0;
  }


  .rigth {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    padding: 30px 38px 34px 42px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

    .txtBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      margin-bottom: 8px;

      .t1 {
        font-size: 14px;
        font-weight: 500;
        color: var(--txBody-color);
        display: flex;
        justify-content: flex-start;
        align-self: center;

        &::before {
          content: "";
          display: flex;
          mask-image: url(../../../public/icons/m_IconDiscount-shape.svg);
          background-color: var(--txBody-color);
          mask-repeat: no-repeat;
          mask-position: center;
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }

      .t2 {
        font-size: 14px;
        font-weight: 500;
        color: var(--txBody-color);
        direction: ltr;

        b {
          font-size: 14px;
          font-weight: 500;
          color: var(--color-blackLight);
        }
      }

    }

    .inputBox {
      width: 100%;
      padding: 8px 16px;
      border: 1px solid var(--color-br3);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      margin-bottom: 25px;

      .tel::placeholder {
        text-align: right;
      }

      input {
        width: 100%;
        background: transparent;
        border: none;
      }

      >div {
        width: 100%;
      }
    }


    .btnBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 11px;
      margin-top: 25px;

      .pairBtn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;

        .btn {
          background: var(--color-bg1);
          min-width: 80px;
          max-width: 80px;
          border: none;
          font-size: 14px;
          font-weight: 400;
          color: var(--txBody-color);
          padding: 14px 21.5px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          transition: 0.3s ease-in;
          -webkit-transition: 0.3s ease-in;
          -moz-transition: 0.3s ease-in;
          -ms-transition: 0.3s ease-in;
          -o-transition: 0.3s ease-in;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.active {
            background: var(--color-Boxblue1);
            color: var(--color-title);
          }
        }
      }

      .submit {
        flex: 1 1 auto;
        background: var(--color-green);
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: var(--color-textOnlyWhite);
        padding: 14px 21.5px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        transition: 0.3s ease-in;
        -webkit-transition: 0.3s ease-in;
        -moz-transition: 0.3s ease-in;
        -ms-transition: 0.3s ease-in;
        -o-transition: 0.3s ease-in;
        cursor: pointer;

        &:hover {
          background: var(--color-blue);
        }

      }
    }

    .sliderRage .MuiSlider-markLabel {
      color: var(--color-textSlider);
      direction: rtl;
    }
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 19px;
    width: 414px;
    flex: 0 0 414px;

    .amar {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      gap: 10px;
      width: 100%;

      .boxTxt {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
        flex-direction: column;
        padding: 18px 15px 19px 15px;
        background: var(--color-white);
        box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        gap: 4px;

        >span {
          margin-bottom: 23px;
        }

        .t1 {
          font-size: 16px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        .t2 {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-text1);
        }
      }
    }

    .defaultCode {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-direction: column;
      padding: 30px 38px 34px 42px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

      .textBox {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 14px 17px;
        border: 1px solid var(--color-br3);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        >span {
          cursor: pointer;
        }

        .tx1 {
          flex: 1 1 auto;
          font-size: 14px;
          font-weight: 400;
          color: var(--txBody-color);
          text-align: left;
          direction: ltr;
        }
      }
    }
  }
}

.historyInvitation {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 24px 31px 34px 44px;
  background: var(--color-white);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
  margin-top: 29px;

  .tb_Invitation {
    max-height: 400px;
    min-height: 300px;

    * {
      text-wrap: nowrap;
      white-space: nowrap;
    }

    .name {
      display: flex;
      justify-self: center;
      align-items: center;
      gap: 4px;

      .t1 {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .btn {
      display: flex;
      justify-self: center;
      align-items: center;
      gap: 4px;
      background: var(--color-bg7);
      padding: 7px 10px;
      cursor: pointer;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      transition: 0.3s ease-in;
      -webkit-transition: 0.3s ease-in;
      -moz-transition: 0.3s ease-in;
      -ms-transition: 0.3s ease-in;
      -o-transition: 0.3s ease-in;
      color: var(--color-textOnlyWhite);

      >span {
        background: var(--color-textOnlyWhite);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}



.historyGift .listBox .item {
  width: 330px;
}

/* /////////////////////////////////////////////// SupportWidget */


.support-header .t1 {
  color: #ffffff;
  font-weight: 500;
}


.support-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
}

.support-button {
  background: #2424da;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 22px 22px 20px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease;
  -webkit-border-radius: 22px 22px 20px 20px;
  -moz-border-radius: 22px 22px 20px 20px;
  -ms-border-radius: 22px 22px 20px 20px;
  -o-border-radius: 22px 22px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.support-button:hover {
  background: #0056b3;
}

.support-popup {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: #fff;
  box-shadow: var(--shadow16);
  border-radius: 10px;
  border: 1px solid var(--color-br2);
  display: flex;
  flex-direction: column;
  // width: 390px;
  min-width: 340px;
  /* عرض استاندارد آیفون 15 پرو مکس */
  height: 730px;
  max-height: calc(100% - 40px);
  /* ارتفاع استاندارد آیفون 15 پرو مکس */
}

.support-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #36353b;
  padding: 20px 10px;
  font-weight: bold;
  border-radius: 7px 7px 0 0;
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  -ms-border-radius: 7px 7px 0 0;
  -o-border-radius: 7px 7px 0 0;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.support-iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}

///////////////////dashboard
.dashboard {
  // background: var(--color-white);
  // padding: 55px 63px;

  .cartDetail {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 28px 22px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .boxAuth {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 21px;
      width: 100%;

      .authChart {
        width: 92px;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .boxText {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
        flex: 1 1;
        width: 100%;

        .t1 {
          font-size: 16px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        .t2 {
          font-size: 15px;
          font-weight: 400;
          color: var(--color-text6);
        }

        .boxBtn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          flex: 1 1;
          width: 100%;

          .txts {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .p1 {
              font-size: 13px;
              font-weight: 300;
              color: var(--color-text7);

              &::before {
                content: "⚈";
                color: var(--color-green);
                padding-left: 5px;
                position: relative;
                top: 3px;
              }
            }

            .p2 {
              font-size: 13px;
              font-weight: 300;
              color: var(--color-text7);

              &::before {
                content: "⚈";
                color: var(--color-gray);
                padding-left: 5px;
                position: relative;
                top: 3px;
              }
            }
          }

          .startOrder {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            .btn {
              padding: 11px 10px;
              border: 1px solid var(--color-blue);
              background: var(--color-blue);
              color: var(--color-textOnlyWhite);
              font-size: 12px;
              font-weight: 500;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              -webkit-transition: 0.3s ease-in-out;
              -moz-transition: 0.3s ease-in-out;
              -ms-transition: 0.3s ease-in-out;
              -o-transition: 0.3s ease-in-out;

              &:hover {
                background: var(--color-green);
                border: 1px solid var(--color-green);
                color: var(--color-textOnlyWhite);
              }
            }

            .btn2 {
              padding: 11px 10px;
              border: 1px solid var(--color-title);
              background: var(--color-white);
              color: var(--color-title);
              font-size: 12px;
              font-weight: 500;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              -webkit-transition: 0.3s ease-in-out;
              -moz-transition: 0.3s ease-in-out;
              -ms-transition: 0.3s ease-in-out;
              -o-transition: 0.3s ease-in-out;

              &:hover {
                background: var(--color-green);
                border: 1px solid var(--color-green);
                color: var(--color-textOnlyWhite);
              }
            }
          }

          // .btn {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   gap: 5px;

          //   .icon-Arrow2 {
          //     transform: rotate(90deg);
          //     -webkit-transform: rotate(90deg);
          //     -moz-transform: rotate(90deg);
          //     -ms-transform: rotate(90deg);
          //     -o-transform: rotate(90deg);
          //     background: var(--cBoxActive-coin);
          //   }


          //   .p1 {
          //     font-size: 13px;
          //     font-weight: 400;
          //     color: var( --color-title2);
          //     cursor: pointer;
          //   }

          // }
        }
      }
    }

    .divider {
      width: 100%;
      background-color: var(--color-divider2);
      margin-top: 28px;
      margin-bottom: 21px;
      height: 1px;
    }

    .boxDetail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;

        .userIcon {
          width: 66px;
          height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border: 1px solid var(--color-br4);

          img {
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }

        .userName {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          .t1 {
            font-weight: 500;
            font-size: 16px;
            text-align: right;
            color: var(--txBody-color);

          }

          .t2 {
            font-weight: 400;
            font-size: 14px;
            text-align: right;
            color: var(--color-text6);
          }
        }
      }

      .left {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 70px;

        .item {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;

          .t1 {
            font-weight: 400;
            font-size: 13px;
            text-align: right;
            color: var(--color-text6);
          }

          .t2 {
            font-weight: 500;
            font-size: 15px;
            text-align: right;
            color: var(--txBody-color);
          }
        }
      }

    }

    .boxSumOrders {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-top: 2px;
      margin-right: 75px;

      .t1 {
        font-size: 14px;
        color: var(--color-txt4);
        font-weight: 400;

        b {
          font-size: 14px;
          color: var(--txBody-color);
          font-weight: 500;
        }
      }
    }

  }

  .cartWallet {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 28px;
    padding: 28px 22px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 23px;
      flex: 0 auto;

      .chartWallet {
        width: 186px;
        height: 186px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .detailWallet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 23px;

        .box1 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0px;

          .tx1 {
            font-weight: 500;
            font-size: 16px;
            text-align: right;
            color: var(--color-text7);
          }

          .tx2 {
            font-weight: 500;
            font-size: 24px;
            color: var(--txBody-color);

            b {
              font-weight: 400;
              font-size: 14px;
              color: var(--color-text6);

            }
          }

          .tx3 {
            font-weight: 400;
            font-size: 14px;
            color: var(--color-text6);

            b {
              font-weight: 500;
              font-size: 16px;
              color: var(--color-text6);
              direction: rtl;
            }
          }
        }

        .box2 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 28px;

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 7px;
          }

          .prog {
            width: 33px;
            height: 33px;
            position: relative;

            .icon-smallArrow {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              -webkit-transform: translate(50%, -50%);
              -moz-transform: translate(50%, -50%);
              -ms-transform: translate(50%, -50%);
              -o-transform: translate(50%, -50%);
            }
          }

          .t1 {
            font-weight: 400;
            font-size: 12px;
            color: var(--color-text6);
          }

          .t2 {
            font-weight: 500;
            font-size: 15px;
            color: var(--color-green);

            &.t2tmn {
              color: var(--color-title2);
            }

            b {
              font-weight: 300;
              font-size: 10px;
              color: var(--color-green);
            }
          }
        }
      }

    }

    .left {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      gap: 40px;
      // width: 100%;
      flex: 1 1;

      .balance {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 21px;
        width: 100%;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          padding-right: 10px;

          .boxTxt {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 1px;

            .t1 {
              font-size: 15px;
              font-weight: 500;
              color: var(--color-text6);
              position: relative;
              margin-bottom: 4px;

              &::before {
                content: "⚈";
                color: var(--color-green);
                position: absolute;
                right: -17px;
                top: 0;
              }

              &.orangeBefore::before {
                color: var(--color-yellow5);
              }
            }

            .t2 {
              font-size: 20px;
              font-weight: 500;
              color: var(--txBody-color);
            }

            .t3 {
              font-size: 12px;
              font-weight: 400;
              color: var(--color-text6);
            }
          }


          .boxProg {
            width: 65px;
            height: 65px;
          }
        }

        .hordivider {
          height: 100%;
          width: 1px;
          background-color: var(--color-divider2);
        }
      }

      .activityUser {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 21px;
        width: 100%;

        .hordivider {
          height: 100%;
          width: 1px;
          background-color: var(--color-divider2);
        }

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          .icon {
            height: 25px;
            width: 30px;
          }

          .boxTxt {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 0px;

            .t1 {
              font-size: 16px;
              font-weight: 500;
              color: var(--txBody-color);
            }

            .t2 {
              font-size: 14px;
              font-weight: 400;
              color: var(--color-text6);
            }
          }
        }
      }
    }
  }

  .newsBox {
    width: 100%;
    height: 244px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 13px;
    margin-bottom: 19px;

    .cardNewCoin {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;
      padding: 28px 22px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
      width: calc(50% - 7px);

      .boxTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .t1 {
          font-size: 15px;
          font-weight: 500;
          columns: var(--txBody-color);
        }
      }

      .listNewCoin {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        padding-left: 25px;
        height: 140px;
        overflow: auto;
        width: 100%;
        padding-bottom: 2px;

        &::-webkit-scrollbar-thumb {
          background: var(--color-green);
        }

        .item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 11px;
          border: 1px solid var(--color-br4);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-hoverNav2);
          }


          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            .icon {
              width: 36px;
              height: 36px;
              object-fit: cover;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            .boxText {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              gap: 0;

              .nameCoin {
                font-size: 14px;
                font-weight: 500;
                color: var(--txBody-color);
              }

              .textBox {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                .t1 {
                  font-size: 13px;
                  font-weight: 400;
                  color: var(--color-text7);
                }

                .volBox {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;

                  .t2 {
                    font-size: 13px;
                    font-weight: 400;
                    color: var(--color-green);
                  }
                }
              }
            }
          }

          .left {
            width: 111px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

    }

    .cartSilder {
      // flex: 1 1;
      width: calc(50% - 7px);
      // height: 240px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

      .itemImg {
        width: 100%;
        height: 244px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 5px;
      }

      .slider-container {
        width: 100%;
        margin: auto;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
      }

      .slide-image {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: fill;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }

      .custom-dots {
        position: absolute;
        bottom: 17px;
        width: 100%;
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        // margin-bottom: 10px;
      }

      .custom-dots li {
        margin: 0 3px;
      }

      .custom-dots li button {
        width: 20px;
        height: 3px;
        border-radius: 10px;
        background-color: #ddd;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease, width 0.3s ease;
        -webkit-transition: background-color 0.3s ease, width 0.3s ease;
        -moz-transition: background-color 0.3s ease, width 0.3s ease;
        -ms-transition: background-color 0.3s ease, width 0.3s ease;
        -o-transition: background-color 0.3s ease, width 0.3s ease;
      }

      .custom-dots li.slick-active button {
        background-color: white;
      }

      .slick-dots li button:before {
        display: none;
      }

    }
  }

  .cardReadMore {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 28px;
    padding: 22px 24px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .t1 {
        font-size: 13px;
        font-weight: 500;
      }
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;

      .t1 {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .tableTra_NotBox {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 13px;
    margin-bottom: 19px;

    .cardTableTrans {
      width: calc(50% - 7px);
      min-height: 360px;
      ;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 21px;
      padding: 31px 22px 27px 21px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

      .boxtabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: 100%;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 28%;
          max-width: 123px;
          gap: 5px;
          padding: 4px 5px;
          background: var(--color-bg1);
          border: none;
          color: var(--txBody-color);
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
          transition: 0.3s ease-in;
          -webkit-transition: 0.3s ease-in;
          -moz-transition: 0.3s ease-in;
          -ms-transition: 0.3s ease-in;
          -o-transition: 0.3s ease-in;
          // border: 1px solid var(--color-NO);

          span {
            transition: 0.3s ease-in;
            -webkit-transition: 0.3s ease-in;
            -moz-transition: 0.3s ease-in;
            -ms-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
          }

          &:hover {
            opacity: 0.7;
            // border: 1px solid var(--color-blue);
            // background: var(--color-blue);
            // color: var(--color-textOnlyWhite);

            // span {
            //   background: var(--color-textOnlyWhite);
            // }
          }

          &.active {
            background: var(--color-blue);
            color: var(--color-textOnlyWhite);

            span {
              background: var(--color-textOnlyWhite);
            }
          }
        }
      }

      .tb_listTrans {
        table {
          thead tr th {
            h5 {
              font-size: 13px;
              font-weight: 500;
              color: var(--txBody-color);

            }
          }

          tbody tr td {
            font-size: 12px;
            font-weight: 400;
            color: var(--txBody-color);
            text-align: center;
          }

          .nameCoin {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3px;
            min-width: 150px;
            max-width: 150px;

            .iconCoin {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }

            .t1 {
              font-size: 12px;
              font-weight: 500;
              color: var(--txBody-color);

              b {
                font-weight: 300;
                font-size: 12px;
                color: var(--color-text1);
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .status {

            &.confirm,
            &.complete {
              color: var(--color-green6);
            }

            &.process {
              color: var(--color-orange1);
            }

            &.reject,
            &.cancel {
              color: var(--color-red1);
            }

            &.waiting,
            &.checking,
            &.pending {
              color: var(--color-yellow);
            }
          }



          tbody tr td:nth-child(1),
          thead tr th:nth-child(1) {
            min-width: 125px;
            // max-width: 125px;
            text-align: right;
          }

          tbody tr td:nth-child(2),
          thead tr th:nth-child(2) {
            min-width: 130px;
            // max-width: 130px;
          }

          tbody tr td:nth-child(3),
          thead tr th:nth-child(3) {
            min-width: 110px;
            // max-width: 110px;
          }

          tbody tr td:nth-child(4),
          thead tr th:nth-child(4) {
            min-width: 70px;
            // max-width: 70px;
          }

        }
      }

      // .listTrans {
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: flex-start;
      //   flex-direction: column;
      //   gap: 14px;
      //   width: 100%;

      //   .itemHead {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 5px;
      //     padding-bottom: 12px;
      //     border-bottom: 1px solid var(--color-br5);
      //     width: 100%;

      //     p {
      //       font-size: 12px;
      //       font-weight: 500;
      //       color: var(--txBody-color);
      //       text-align: center;
      //     }

      //   }

      //   .itemHead,
      //   .itemBody {
      //     .nameCoin {
      //       text-align: right;
      //       min-width: 150px;
      //       max-width: 150px;
      //     }

      //     .amount {
      //       min-width: 100px;
      //       max-width: 100px;
      //     }

      //     .date {
      //       min-width: 100px;
      //       max-width: 100px;
      //     }

      //     .status {
      //       min-width: 70px;
      //       max-width: 70px;
      //     }
      //   }

      //   .itemBody {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     gap: 5px;
      //     padding-bottom: 14px;
      //     border-bottom: 1px solid var(--color-br5);
      //     width: 100%;

      //     .nameCoin {
      //       display: flex;
      //       justify-content: flex-start;
      //       align-items: center;
      //       gap: 3px;

      //       .iconCoin {
      //         width: 20px;
      //         height: 20px;
      //         object-fit: cover;
      //       }

      //       .t1 {
      //         font-size: 12px;
      //         font-weight: 500;
      //         color: var(--txBody-color);

      //         b {
      //           font-weight: 300;
      //           font-size: 12px;
      //           color: var(--color-text1);
      //           white-space: nowrap;
      //           text-overflow: ellipsis;
      //         }
      //       }
      //     }

      //     >p {
      //       font-size: 12px;
      //       font-weight: 500;
      //       color: var(--txBody-color);
      //       text-align: center;
      //     }
      //   }
      // }

    }

    .cardTableNotif {
      width: calc(50% - 7px);
      min-height: 360px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 22px;
      padding: 27px 21px 27px 22px;
      background: var(--color-white);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      box-shadow: 0px -3px 36.1px 0px var(--color-shadow);

      .boxTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .t1 {
          font-size: 14px;
          font-weight: 500;
          color: var(--txBody-color);
        }

        .t2 {
          font-size: 13px;
          font-weight: 300;
          color: var(--color-text1);
        }
      }

      .boxtabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: 100%;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 28%;
          max-width: 123px;
          gap: 5px;
          padding: 4px 5px;
          background: var(--color-bg1);
          border: none;
          color: var(--txBody-color);
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
          transition: 0.3s ease-in;
          -webkit-transition: 0.3s ease-in;
          -moz-transition: 0.3s ease-in;
          -ms-transition: 0.3s ease-in;
          -o-transition: 0.3s ease-in;
          // border: 1px solid var(--color-NO);

          span {
            transition: 0.3s ease-in;
            -webkit-transition: 0.3s ease-in;
            -moz-transition: 0.3s ease-in;
            -ms-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
          }

          &:hover {
            opacity: 0.7;
            // border: 1px solid var(--color-blue);
            // background: var(--color-blue);
            // color: var(--color-textOnlyWhite);

            // span {
            //   background: var(--color-textOnlyWhite);
            // }
          }

          &.active {
            background: var(--color-blue);
            color: var(--color-textOnlyWhite);

            span {
              background: var(--color-textOnlyWhite);
            }
          }
        }
      }

      .listNotif {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        height: 100%;

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          gap: 11px;
          padding: 5px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          &:hover {
            background: var(--color-hoverNav2);
          }

          .iconLogo {
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-bg1);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          .boxDetail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
            padding-bottom: 9px;
            border-bottom: 1px solid var(--color-br5);

            .txtBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 4px;
              flex-direction: column;

              .t1 {
                font-size: 12px;
                font-weight: 500;
                color: var(--txBody-color);
              }

              .t2 {
                font-size: 12px;
                font-weight: 300;
                color: var(--color-text1);
              }
            }

            .icon-ArrowLeft {
              background: var(--color-text1);
            }
          }
        }

        .emptyLoad {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 15px;
          width: 100%;

          p {
            font-size: 14px;
            font-weight: 500;
            color: var(--txBody-color);
          }

          img {
            width: 75px;
            height: 70px;
          }
        }
      }
    }

  }

  .cardInvite {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 21px;
    padding: 31px 22px 27px 21px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;

    .title {
      font-size: 15px;
      font-weight: 500;
      color: var(--txBody-color);
    }

    .tb_Invited {
      table {
        thead tr th {
          text-align: right;
          padding-bottom: 23px;

          h5 {
            font-size: 13px;
            font-weight: 500;
            color: var(--txBody-color);

          }
        }

        tbody tr td {
          text-align: right;
          padding: 15px 5px;

          h5 {
            font-size: 13px;
            font-weight: 500;
            color: var(--txBody-color);

          }
        }
      }
    }
  }

  .cardChartChange {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 28px;
    padding: 28px 22px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;


    .topTxt {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding-bottom: 23px;
      border-bottom: 1px solid var(--color-br5);

      .t1 {
        font-size: 15px;
        font-weight: 500;
        color: var(--txBody-color);
      }

      .left {
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 7px;

        .searchBox {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 9px 18px;
          border: 1px solid var(--color-txt4);
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;

          input {
            border: none;
            background: transparent;
          }
        }

        .selectDay {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          border-radius: 8px;
          padding: 6px 18px;
          cursor: pointer;
          gap: 3px;
          border: 1px solid var(--color-txt4);
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;

          .customSelect {
            width: 80px;
            border: none;
            background: transparent;
            font-size: 16px;
            text-align: center;
            color: var(--color-txt4);
            appearance: none;
            cursor: pointer;
            text-align: center;
            margin-right: -10px;
            // margin-left: -12px;

            option {
              background: var(--color-white);
            }
          }

          .icon-selectIcon {
            margin-right: -8px;
          }
        }
      }
    }

    .boxCoin {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding-bottom: 17px;
      border-bottom: 1px solid var(--color-br5);
      width: 100%;
      overflow: auto;
      min-height: 82px;


      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        min-width: 220px;
        // width: fit-content;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        padding: 6px 5px;

        &:hover {
          background: var(--color-bghoverNav);
        }

        .icon {
          width: 43px;
          height: 43px;
          object-fit: cover;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }

        .boxText {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 0;

          .nameCoin {
            font-size: 16px;
            font-weight: 500;
            color: var(--txBody-color);
          }

          .textBox {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            .t1 {
              font-size: 15px;
              font-weight: 400;
              color: var(--color-text7);
            }

            .volBox {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;

              .t2 {
                font-size: 15px;
                font-weight: 400;
                color: var(--color-green);
              }
            }
          }
        }
      }
    }
  }

  .cartBtnsNav {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // gap: 10px;
    padding: 0px 17px 0px 21px;
    background: var(--color-white);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px -3px 36.1px 0px var(--color-shadow);
    margin-bottom: 19px;
    width: 100%;


    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 11px;
      padding: 20px 30px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;

      &:hover {
        background: var(--color-hoverNav2);
      }

      .t1 {
        color: var(--color-txt5);
        font-size: 15px;
        font-weight: 400;
      }

      .cir1 {
        width: 58px;
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background: var(--color-bgCir1);

        .cir2 {
          width: 46px;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          background: var(--color-bgCir2);
        }
      }
    }

    .herDivider {
      width: 1px;
      height: 100%;
      min-height: 130px;
      background: var(--color-div1);
    }

  }
}




////////////////////orderGold

.orderGold {
  padding: 30px 32px;

  .stepOrder {
    margin: 25px 0;
  }

  .coinsBox .item.active {
    background: none;
    border: 1px solid var(--color-yellow5);
    position: relative;

    h4 {
      color: var(--txBody-color);

      b {
        color: var(--txBody-color);
      }
    }

    &::before {
      content: "";
      background-image: url(../../../public/images/shamshGold.svg);
      background-size: cover;
      width: 62px;
      height: 62px;
      position: absolute;
      left: -25px;
      top: -20px;
    }
  }

  .bannerGold {
    width: 100%;
    height: 182px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../public/images/bgGold.svg);
    background-color: #161B27;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 4px;

    .txtBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      .p1 {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-yellow5);
      }

      .p2 {
        font-size: 16px;
        font-weight: 400;
        color: var(--color-textOnlyWhite);
      }
    }
  }

  .bodyGold {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 12px;
    width: 100%;
    margin-top: 24px;

    .chartGolg {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
      max-width: calc(55% - 4px);
      width: calc(55% - 4px);
      // height: 403px;


      .tit1 {
        font-size: 16px;
        font-weight: 400;
        color: var(--txBody-color);
      }

      .boxChart {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border: 1px solid var(--color-br6);
        height: 446px;

        .tradChart {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          position: relative;

          .loadingChart {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            -moz-transform: translate(50%, -50%);
            -ms-transform: translate(50%, -50%);
            -o-transform: translate(50%, -50%);
          }

          .iframeTradingViewWidget {
            width: 102%;
            height: 102%;
            margin-left: -3px;
            margin-right: -3px;
            border: none;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }

      }
    }

    .calculaGold {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
      max-width: calc(45% - 8px);
      width: calc(45% - 8px);
      height: 100%;

      .tit1 {
        font-size: 16px;
        font-weight: 400;
        color: var(--txBody-color);
      }

      .boxDetail {
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border: 1px solid var(--color-br6);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 19px;
        padding: 25px 12.6px;

        .t1 {
          font-size: 14px;
          font-weight: 400;
          color: var(--txBody-color);
          text-align: right;
        }

        .t2 {
          font-size: 12px;
          font-weight: 400;
          color: var(--txBody-color);
          text-align: right;
        }

        .numBox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 7px;
          background: var(--cBox-coin);
          padding: 7px 11px;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          margin-top: -4px;
          height: 44px;
        }

        .numBox>input {
          flex: 1 1 auto;
          background: transparent;
          font-size: 12px;
          color: var(--color-text6);
          font-weight: 400;
          background: transparent;
          border: none;
        }

        .numBox .s1 {
          flex: 0 0 auto;
          font-size: 12px;
          color: var(--color-text6);
          font-weight: 400;

        }

        .priceNow {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }

        .priceNow .txBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          .icon-dotGreen {
            background: var(--color-green);
            width: 9px;
            height: 10px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }

        .btns {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          width: 100%;
          // margin-top: 7px;

          .btnBuy {
            text-align: center;
            width: 100%;
            flex: 1 1;
            color: var(--color-green);
            background: var(--color-greenLight);
            padding: 7.7px;
            border-radius: 5.5px;
            -webkit-border-radius: 5.5px;
            -moz-border-radius: 5.5px;
            -ms-border-radius: 5.5px;
            -o-border-radius: 5.5px;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            cursor: pointer;
            border: none;
            padding: 13px;

            // &:hover ,
            &.active {
              background: var(--color-green);
              color: var(--color-textOnlyWhite);
            }
          }

          .btnSell {
            text-align: center;
            width: 100%;
            flex: 1 1;
            color: var(--color-red1);
            background: var(--color-redLight);
            padding: 7.7px;
            border-radius: 5.5px;
            -webkit-border-radius: 5.5px;
            -moz-border-radius: 5.5px;
            -ms-border-radius: 5.5px;
            -o-border-radius: 5.5px;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            cursor: pointer;
            border: none;
            padding: 13px;

            // &:hover,
            &.active {
              background: var(--color-red1);
              color: var(--color-textOnlyWhite);
            }

          }
        }

        .balanceNum {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .p1 {
            font-size: 14px;
            font-weight: 400;
            color: var(--txBody-color);
          }

          h4 {
            font-size: 14px;
            font-weight: 400;
            color: var(--txBody-color);
          }

          >a {
            margin-right: auto;
          }
        }

        .submitBtn {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 7px;

          .loadingCircularOrder {
            width: 200px;
            height: 46px;
            border-radius: 5.5px;
            -webkit-border-radius: 5.5px;
            -moz-border-radius: 5.5px;
            -ms-border-radius: 5.5px;
            -o-border-radius: 5.5px;
            cursor: pointer;
            border: none;
            padding: 13px;
          }

          .btnn {
            width: 200px;
            color: var(--color-textOnlyWhite);
            background: var(--color-green);
            padding: 7.7px;
            border-radius: 5.5px;
            -webkit-border-radius: 5.5px;
            -moz-border-radius: 5.5px;
            -ms-border-radius: 5.5px;
            -o-border-radius: 5.5px;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            cursor: pointer;
            border: none;
            padding: 13px;

            &:hover {
              transform: scale(1.1);
              -webkit-transform: scale(1.1);
              -moz-transform: scale(1.1);
              -ms-transform: scale(1.1);
              -o-transform: scale(1.1);
            }
          }
        }


      }
    }
  }

  .tableDetail {
    width: 100%;
    display: flex;
    justify-content: center;
    // padding: 20px;
    margin-top: 24px;


    table {
      width: 100%;
      border-collapse: collapse;
      // background-color: #121212;
      color: var(--txBody-color);
      border-radius: 10px;
      overflow: hidden;
    }

    .box1 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    td {
      padding: 19px;
      text-align: center;
      border: 1px solid var(--color-br6);

      .boxItem {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }

    th,
    td {
      &:has(.col1) {
        max-width: 320px;
        width: 320px;
      }

      .col1 {
        justify-content: flex-start !important;
        text-align: right;
        padding-right: 10px;
      }
    }


    th {
      // background-color: #1e1e1e;
      font-weight: bold;
      text-align: center;
      padding: 20px 0;

      &.col1 {
        text-align: right;
        padding-right: 24px;
      }
    }
  }
}

//////////////////////phishingWarning

.phishingWarning {
  width: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  direction: rtl;

  .body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px !important;
    direction: rtl;
    width: 100%;
    // height: 895px;
    // overflow: auto;

    .topTxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .t1 {
        font-size: 14px;
        color: var(--txBody-color);
        font-weight: 400;
      }
    }


    .divider {
      width: 100%;
      background-color: var(--color-divider2);
      margin-top: 21px;
      margin-bottom: 27px;
      height: 1px;
    }

    .warningText {
      font-size: 13px;
      font-weight: 400;
      color: var(--color-black5);
      margin-bottom: 27px;
    }

    .btnsTxt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      direction: ltr;
      margin-top: 10px;

      .txtbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .t2 {
          font-size: 13px;
          color: var(--txBody-color);
          font-weight: 400;
          direction: rtl;
        }
      }


      .finalCheckBtn {
        padding: 10px 15px;
        width: unset;
        margin-top: 0;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        font-size: 13px;
        direction: rtl;
      }

      .btnCancel2 {
        background: var(--color-gray);
        // border: 1px solid var(--color-blue);
        color: var(--color-textOnlyWhite);

        &:hover {
          background: var(--color-red3) !important;
        }
      }

    }

  }

}



//color DetailsUser selectBox

label#delectBox-label,
.menuItemSelect,
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: var(--txBody-color) !important;
}