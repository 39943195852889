* {
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  // direction: ltr;
  margin: 0;
  padding: 0;
}

// Font size:
$fontsize1: 16px;
$fontsize2: 14px;
$fontsize3: 12px;
$fontsize4: 11px;
$fontsize5: 10px;


//font:
@font-face {
  font-family: 'OCRAStdNew';
  font-style: normal;
  font-weight: normal;
  src: local('OCRAStdNew'), url('../../../public/fonts/OCRAStdNew.woff') format('woff');
}

//font:
@font-face {
  font-family: CardNew;
  src: url(../../../public/fonts/cardNew.ttf);
}

//////////////////////////////

// @font-face {
//   font-family: YekanBakh;
//   font-style: normal;
//   font-weight: 400;
//   src: local('IRANYekanXVF'), url('../../../public/fonts/IRANYekanXVF.woff2') format('woff');
// }

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: local('IRANYekanXVF'), url('../../../public/fonts/IRANYekanXVF.woff2') format('woff');
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: local('YekanBakh'), url('../../../public/fonts/new/YekanBakh-Regular.5764935.woff2') format('woff');
  unicode-range: U+0600-06FF, U+FB50-FDFF, U+06F0-06F9, U+0660-0669, U+200C, U+200D, U+060C, U+061B, U+061F, U+066A-066F;
}



// @font-face {
//   font-family: YekanBakh;
//   font-style: normal;
//   font-weight: 400;
//   src: local('YekanBakh'), url('../../../public/fonts/new/YekanBakh-Regular.5764935.woff2') format('woff');
// }
// @font-face {
//   font-family: YekanBakh;
//   font-style: normal;
//   font-weight: 500;
//   src: local('YekanBakh'), url('../../../public/fonts/new/YekanBakh-SemiBold.4040dd6.woff2') format('woff');
// }
// @font-face {
//   font-family: YekanBakh;
//   font-style: normal;
//   font-weight: 600;
//   src: local('YekanBakh'), url('../../../public/fonts/new/YekanBakh-Bold.7817543.woff2') format('woff');
// }



@font-face {
  font-family: Card;
  src: url(../../../public/fonts/card.ttf);
}

* {
  font-family: YekanBakh !important;
  // font-family: Vazir-EnNum !important;
  letter-spacing: 0;
  word-spacing: 0;
  // color: var(--txBody-color);
}

html,
body {
  // font-family: Vazir-EnNum;
  font-weight: 400 !important;
  overflow: unset;
  margin: 0;
  // padding: 0 !important;
  padding: 0;
  background: var(--bgBody-color);
  color: var(--txBody-color);
}

.App {
  background: var(--bgBody-color);
  color: var(--txBody-color);
}

a {
  color: var(--txBody-color);

  &:active {
    color: unset;
  }
}

button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input,
textarea {
  font-family: YekanBakh !important;
  color: var(--txBody-color);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-transition: background-color 0s 600000s, color 0s 600000s !important;
  -moz-transition: background-color 0s 600000s, color 0s 600000s !important;
  -ms-transition: background-color 0s 600000s, color 0s 600000s !important;
  -o-transition: background-color 0s 600000s, color 0s 600000s !important;
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

img {
  vertical-align: middle;
  border-style: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}


/* Track */
::-webkit-scrollbar-track {
  background: var(--color-br1);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  margin: 6px 0;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-blue8);
  -webkit-border-radius: 70rem;
  -moz-border-radius: 70rem;
  -ms-border-radius: 70rem;
  -o-border-radius: 70rem;
  border-radius: 70rem;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray14);
  border-radius: 70rem;
}

/* Chrome, Safari, Edge, Opera */
/* Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

// button,
// input,
// optgroup,
// select,
// textarea {
//   margin: 0;
//   font-family: inherit;
//   font-size: inherit;
//   line-height: inherit;
// }

.fontYekanBakh {
  font-family: YekanBakh !important;
}

.fontBold {
  font-weight: 600 !important;
}

.fontCard {
  // font-family: Card !important;
  font-family: OCRAStdNew !important;
}

.overflowHidden_X {
  overflow-x: hidden !important;
}

.fontCard2 {
  // font-family: Card !important;
}

.rtl {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

.justifyStart {
  justify-content: flex-start !important;
}

.textRight {
  text-align: right !important;
}

.textleft {
  text-align: left !important;
}

.minWidth {
  &_450 {
    min-width: 450px !important;
  }
}

.btnDisable {
  background: var(--color-brInput) !important;
  color: var(--color-text4) !important;
  cursor: default !important;
}

.none,
.rv2 {
  display: none !important;
}


.opacity0 {
  opacity: 0 !important;
}

.gap {
  &-0 {
    gap: 0px !important;
  }

  &-2 {
    gap: 2px !important;
  }
}

.opacity1 {
  opacity: 1 !important;
}

.opacity07 {
  opacity: 0.7 !important;
}

.opacityHalf {
  opacity: 0.5 !important;
}

.cPointer {
  cursor: pointer !important;
}

.h100 {
  height: 100% !important;
}

.h99 {
  height: 99% !important;
}

.listCoinPopupV2 {
  max-height: 300px !important;
  gap: 2px !important;
}

.imgFluid {
  max-width: 100%;
  height: auto;
}

.width {
  &_100d {
    width: 100% !important;
  }

  &_50 {
    width: 50px !important;
    min-width: 50px !important;
  }

  &_60 {
    width: 60px !important;
    min-width: 60px !important;
  }

  &_70 {
    width: 70px !important;
    min-width: 70px !important;
  }

  &_80 {
    width: 80px !important;
    min-width: 80px !important;
  }

  &_90 {
    width: 90px !important;
    min-width: 90px !important;
  }

  &_100 {
    width: 100px !important;
    min-width: 100px !important;
  }

  &_110 {
    width: 110px !important;
    min-width: 110px !important;
  }

  &_120 {
    width: 120px !important;
    min-width: 120px !important;
  }

  &_130 {
    width: 130px !important;
    min-width: 130px !important;
  }

  &_430 {
    width: 430px;
  }
}

.shadowNone {
  box-shadow: none !important;
}

.circularMin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin: 15px 0;
}

.userImg {
  padding: 2px;
  width: 50px;
  height: 50px;
  border: 2px solid var(--color-white3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;

  span {
    background: transparent;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    margin-top: 20px;
    margin-left: -13px;
    padding: 1px;

    img {
      background: var(--color-white);
      border: 2px solid var(--color-white3);
      border-radius: 50%;
      padding: 3px;
    }
  }
}

.ringdrop {
  color: var(--color-white);
  text-decoration: none;
  font-size: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  width: 38px;
  cursor: pointer;



  .w3-count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px;
    background: var(--color-red3);
    color: var(--color-white);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    direction: ltr;
    top: -8px;
    right: 2px;
    min-width: 12px;
    font-size: 9px;
    z-index: 1;
    text-align: center;
    font-family: YekanBakh !important;

    // animation: w3-alert-red 1.6s infinite;
    // -webkit-animation: w3-alert-red 1.6s infinite;
    &.anim {
      animation: w3-alert-red 1.6s infinite;
      -webkit-animation: w3-alert-red 1.6s infinite;
    }
  }

  .icon-bell {
    width: 20px;
    height: 20px;
    position: relative;
    background-position: center center;

    // animation: ring 1.6s linear infinite;
    // -webkit-animation: ring 1.6s linear infinite;
    &.anim {
      animation: ring 1.6s linear infinite;
      -webkit-animation: ring 1.6s linear infinite;
    }
  }
}

.divideTicket {
  background-color: var(--color-white);
  width: 20px;
  @extend %flexCenter;
  position: relative;
  @extend %flexCenter;

  div {
    height: 90%;
    border-left: 1px dashed var(--color-white6);
  }

  span {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--color-white12);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
  }

  .top {
    top: -28px;
  }

  .bottom {
    bottom: -28px;
  }
}

.dividedHorizon {
  margin: 16px 0;
  border: 0;
  border-top: 1px solid var(--color-black4);
  height: 0;
}

.stausMultiSelect:hover .multiListStatus {
  display: flex !important;
}


.buySellBox {
  // max-width: 49%;
  // flex: 0 0 48%;

  .balanceBox {
    @extend %flexCenter;
    justify-content: flex-start !important;
    gap: 10px;
    direction: rtl;
    margin-bottom: 32px;

    img {
      width: 46px;
      height: 46px;
    }

    .name,
    .balance {
      @extend %flexStart;
      flex-direction: column;
      gap: 5px;
      text-align: right;
      direction: rtl;

      h5 {
        font-size: 13px;
        font-weight: 400;
        direction: rtl;
      }

      h6 {
        font-size: 12px;
        font-weight: 400;
        direction: rtl;
      }
    }

    .balance {
      align-items: flex-end;
      text-align: left;
      margin-right: 20px;
      margin-right: auto;

      h5,
      h6 {
        text-align: left
      }
    }
  }

  .inputBuyBox {
    @extend%flexCenter;
    justify-content: space-between;
    gap: 5px;
    direction: rtl;
    padding: 10px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: 1px solid var(--color-white14);

    input {
      flex: 1;
      text-align: center;
      font-size: 20px;
      font-weight: 500;

      background: transparent;
      border: none;
    }

    h5 {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-txt4);
    }

    .des {
      @extend %flexCenter;
      direction: rtl;
      justify-content: flex-start;
      display: inline-flex;
      gap: 5px;
      direction: rtl;
      max-width: 74px;
      width: 74px;

      img {
        width: 30px;
        height: 30px;
      }

      >div {
        @extend %flexCenter;
        flex-direction: column;

        h5 {
          font-size: 13px;
        }
      }
    }
  }

  .sliderRage {
    padding: 10px;
  }

  .detailFee {
    @extend %flexCenter;
    width: 100%;
    gap: 20px;

    .text {
      @extend%flexCenter;
      justify-content: space-between;
      background: var(--color-white3);
      padding: 5px 10px;
      width: 50%;
      max-width: 50%;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      p {
        font-size: 13px;

        font-weight: 400;
      }

      h5 {
        font-size: 13px;
        color: var(--color-blue2);
        font-weight: 500;
      }

      >div {
        @extend %flexCenter;
      }
    }
  }

  .btnOrder {
    width: 100%;
    padding: 20px;
    @extend%flexCenter;
    gap: 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background: var(--color-green);
    color: var(--color-white);
    margin-top: 30px;
    cursor: pointer;

    &.sell {
      background: var(--color-orange1);

      &:hover {
        background: var(--color-red4);
      }
    }

    &:hover {
      background: var(--color-green7);
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-white);
    }

    p {
      font-size: 13px;
      font-weight: 400;
      color: var(--color-white);
    }
  }
}

.buySellChangeBtn {
  font-size: $fontsize2;
  background: var(--color-white3);
  border-radius: 10px;
  padding: 10px;
  @extend %flexCenter;


  &.btnChangeOrder {
    .active.buy {
      background: var(--color-green);
      color: var(--color-white);
      box-shadow: none !important;
    }

    .active.sell {
      background: var(--color-orange1);
      color: var(--color-white);
      box-shadow: none !important;
    }
  }

  >div {
    // width: 49%;
    flex: 0 0 50%;
    // max-width: 50%;

    button {
      width: 100%;
      cursor: pointer;
      border: none;
      background: transparent;
      width: 100%;
      padding: 8px 20px;
      direction: ltr;
      @extend %flexCenter;
      gap: 6px;
      box-shadow: 0px 10px 19px 1px var(--color-NO);
      border: 1px solid var(--color-NO);

      img {
        margin-top: -3px;
        width: 18px !important;
        height: 18px !important;
      }

      &.active {
        background: var(--color-white);
        border-radius: 7px;
        box-shadow: 0px 10px 19px 1px var(--color-blue11) !important;
        border: 1px solid var(--color-white3);

        >span {
          background-color: var(--color-white) !important;
        }
      }

    }
  }
}

.loadingList,
.emptyList {
  margin: 20px auto;
  right: 50%;
  top: 0%;
  gap: 10px;
  direction: rtl;
  position: absolute;
  border: none !important;

  td {
    display: flex !important;
    @extend%flexCenter;
    direction: rtl;
    gap: 10px
  }

  h4 {
    font-size: 14px !important;
    color: var(--color-text4) !important;
    font-weight: 400 !important;
    margin: 0 0 !important;
  }

  img {
    width: 110px;
    height: 130px;
  }
}

.emptyList {
  top: 30px;
  right: 41%;
}

.tb_opratorBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  direction: rtl;
  width: 100%;
  margin-bottom: 30px;

  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: var(--color-black);

  }

  .refreshAndInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 35px;

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
        color: var(--color-black);
      }

      img {
        cursor: pointer;
      }
    }

    .refresh {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 7px 10px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      background: var(--color-blue);
      cursor: pointer;
      border: none;

      &:hover {
        background: var(--color-blue3);
      }

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: var(--color-textOnlyWhite);
      }
    }

  }

}


.levelStyleShow {
  @extend %flexCenter;
  margin-top: -2px;
  margin-bottom: 13px;
  direction: rtl;

  img {
    width: 14px;
    height: 14px;

  }

  p {
    color: var(--color-txt4);
    font-size: $fontsize3;
  }
}

.textCenter {
  text-align: center !important;
}

.justifyCenter {
  justify-content: center !important;
}

.letSpacing0 {
  letter-spacing: 0 !important;
}

.close {
  cursor: pointer;
  color: var(--color-orange1) !important;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w100 {
  width: 100% !important;
}

.w50 {
  width: 50% !important;
}

.p {
  &-0 {
    padding: 0 !important;
  }

  &-10 {
    padding: 10px !important;
  }

  &-35 {
    padding: 35px !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }

  &-5 {
    margin-top: 5px !important;
  }

  &-10 {
    margin-top: 10px !important;
  }

  &-15 {
    margin-top: 15px !important;
  }

  &-14 {
    margin-top: 14px !important;
  }

  &-24 {
    margin-top: 24px !important;
  }

  &-48 {
    margin-top: 48px !important;
  }
}

.ml {
  &-0 {
    margin-left: 0px !important;
  }

  &-5 {
    margin-left: 5px !important;
  }
}

.mr {
  &-auto {
    margin-right: auto !important;
  }

  &-0 {
    margin-right: 0px !important;
  }

  &-2 {
    margin-right: 2px !important;
  }

  &-3 {
    margin-right: 3px !important;
  }

  &-5 {
    margin-right: 5px !important;
  }
}


.mb {
  &-0 {
    margin-bottom: 0 !important;
  }

  &-8 {
    margin-bottom: 8px !important;
  }

  &-10 {
    margin-bottom: 10px !important;
  }

  &-12 {
    margin-bottom: 12px !important;
  }


  &-15 {
    margin-bottom: 15px !important;
  }

  &-16 {
    margin-bottom: 16px !important;
  }

  &-17 {
    margin-bottom: 17px !important;
  }

  &-24 {
    margin-bottom: 24px !important;
  }

  &-28 {
    margin-bottom: 28px !important;
  }

  &-32 {
    margin-bottom: 32px !important;
  }

  &-60 {
    margin-bottom: 60px !important;
  }

  &-70 {
    margin-bottom: 70px !important;
  }

  &-77 {
    margin-bottom: 77px !important;
  }
}

.my {
  &-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  &-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  &-11 {
    margin-top: 11px !important;
    margin-bottom: 11px !important;
  }

  &-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  &-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  &-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
}

.mx {
  &-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  &-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  &-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

.px {
  &-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  &-14 {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

.py {
  &-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  &-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}

.pb {
  &-30 {
    padding-bottom: 30px !important;
  }
}

.font {
  &-10 {
    font-size: 10px !important;
  }

  &-12 {
    font-size: 12px !important;
  }

  &-14 {
    font-size: 14px !important;
  }

  &-17 {
    font-size: 17px !important;
  }
}

.relative {
  position: relative !important;
}

%flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

%divider {
  height: 0;
  margin: 16px 0;
  border: 0;
  border-top: 1px solid var(--color-black4);
}

.visiblePass {
  @extend %flexCenter;
}

.logoHeader {
  background: var(--color-blue);
  text-align: center;
  padding: 40px 0;
  height: 240px;
  overflow: hidden;

  img {
    width: 180px;
    height: auto;
  }
}

.textAlignL {
  text-align: left !important;
}

.textAlignR {
  text-align: right !important;
}

.errorText {
  text-align: right;
  font-size: 12px;
  // display: none;
  opacity: 0;
  margin-bottom: 12px;
  color: var(--color-orange1);
}

.color-cashout,
.color-sell,
.color-use {
  color: var(--color-orange1) !important;
}

.color-deposit,
.color-buy,
.color-create,
.color-complete,
.color-confirm {
  color: var(--color-green) !important;
}

.colorGreen {
  color: var(--color-green) !important;
}

.colorRed {
  color: var(--color-orange1) !important;
}

.colorBlue {
  color: var(--color-blue) !important;
}

.colorGray {
  color: var(--color-txt4) !important;
}
.colorGold {
  color: var(--color-yellow5) !important;
}

.brRed {
  border: 1px solid var(--color-orange1) !important;
}

.brGold {
  border: 1px solid var(--color-yellow2) !important;
}

.brNone {
  border: none !important;
}


.bgRed {
  background: var(--color-orange1) !important;
}
.bgRed1 {
  background: var(--color-red1) !important;
}


.bgBlue {
  background: var(--color-blue) !important;
}

.bgGreen {
  background: var(--color-green3) !important;
}

.bgGreen2 {
  background: var(--color-green3) !important;
}

.order-1 {
  order: 1 !important;
}

.radioTypeSendCode {
  direction: rtl;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 12px;

  p {
    font-size: 12px;

    font-weight: 400;
  }

  h4 {
    font-size: 14px;

    font-weight: 500;
  }

  .riadoImg {
    width: 12px;
    height: 12px;
  }
}

.styleBankSelect {
  .showBanck {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 auto;

    p {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-txt4);
    }

    img {
      width: 24px;
      height: 24px;
      position: relative;
      top: -1px;
      margin-right: 32px;
    }

    h4 {
      flex: unset;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-black);
      padding: 0 !important;
      letter-spacing: 1.5px;
    }

    .defaultText {
      padding: 0;
      flex: 0;
      font-size: 14px;
      font-weight: 400;

      position: relative;
      top: 2px;
      flex: 1 0 auto;
      text-align: right;

      b {
        color: var(--color-black2);
        font-weight: 400;
      }
    }
  }

  .textViewBox {
    justify-content: space-between !important;
  }
}

.BackdropStyle {
  background-color: var(--color-white27) !important;
  opacity: 90% !important;
  color: var(--color-green) !important;
}

.updateDialogNEW {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--dialog-bg);
  color: var(--dialog-text);
  box-shadow: 0px 0px 10px #6565655f;
  padding: 16px;
  z-index: 1000;
  width: 300px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  span {
    font-size: 12px;
    color: var(--txBody-color);
  }
}

.DialogStyle {
  // .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: var(--color-black14);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

}

.MuiSkeleton-root {
  background: var(--color-Skeleton) !important;
}

.fetchListLoad {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;

  .emptyLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--txBody-color);
    }

    img {
      width: 75px;
      height: 60px;
    }
  }

}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: var(--color-bghoverOrderBook);
    font-size: 13px;
    text-align: center;
    color: var(--color-white);
  }

  .MuiTooltip-arrow {
    color: var(--color-bghoverOrderBook);
  }
}

.customInputSelect {
  position: relative;

  .textViewBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
    cursor: pointer;
    height: 21px;

    h4 {
      // flex: 1 0 auto;
      padding: 0px 15px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;

    }

  }

  .btnPopUp {
    @extend%flexCenter ;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .listMenuPopup,
  .listCoinPopup,
  .listBankPopup,
  .listTextPopup,
  .listNetworkPopup {
    @extend%flexStart;
    //justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    position: absolute;
    bottom: -1px;
    left: 50%;
    background-color: var(--color-whiteOld);
    width: 100%;
    z-index: 10;
    transform: translate(-50%, 100%);
    -webkit-transform: translate(-50%, 100%);
    -moz-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    -o-transform: translate(-50%, 100%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 3px 12.3px 0px var(--color-black8);
    max-height: 170px;
    overflow: auto;
    padding: 10px 0;

    .item {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 21px 8px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;

      &:hover {
        background: var(--color-hoverSubMenu);

      }

      p {
        color: var(--txBody-color);
      }

      .symbolFee {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;

        .fee {
          font-size: 12px;
          color: var(--color-text4);
        }
      }
    }

  }

  .listTextPopup {
    .item p {
      color: var(--txBody-color);
      min-width: 60px;
    }
  }


  .listCoinPopup,
  .listBankPopup {
    .item {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      gap: 10px;

      .name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          font-size: 13px;
          font-weight: 400;
        }

        .p2 {
          font-size: 12px;
        }


        .checkSelect {
          position: relative;
          cursor: pointer;
          width: 17px;
          height: 17px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 17px;
            height: 17px;
            top: 0;
            left: 0;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            background: var(--color-white);
            border: 1px solid var(--color-br1);
          }

          &:checked:before {
            content: "";
            display: block;
            position: absolute;
            width: 17px;
            height: 17px;
            top: 0;
            left: 0;
            background-color: var(--color-blue);
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
          }

          &:checked:after {
            content: "";
            display: block;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
          }

        }
      }

    }

    .add {
      padding-bottom: 20px;

      p {
        padding-right: 7px;
        color: var(--color-title);
      }
    }
  }

  .listNetworkPopup {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      p {
        font-size: 14px;
        font-weight: 400;
        direction: rtl;

        &.isActive {
          padding: 4px 8px;
          background: var(--color-green11);
          color: var(--color-green);
          border-radius: 20px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;

          &.falseActive {
            background: var(--color-red5);
            color: var(--color-red1);
          }
        }
      }

    }

  }


  .listCoinCheckBox {
    overflow-x: hidden !important;

    .item {
      padding-right: 8px;

      .p1 {
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }

    .searchBox {
      padding: 2px 4px;

      input {
        background: transparent;
        width: 100%;
        border: 1px solid var(--color-br1);
        padding: 4px 8px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
      }
    }
  }
}

.multiSelectBoxSearch {
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  // gap: 5px;

  .textViewBox {
    gap: 0px !important;
  }

  .nameBoxSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .nameoinSelect {
    font-size: 13px;
    color: var(--txBody-color);
    font-weight: 400;

  }
}


.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.formControlLogin {
  width: 100%;
  direction: ltr !important;

  fieldset {
    border-color: var(--color-white3) !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;

    span {
      position: absolute !important;
      right: 0 !important;
      left: unset !important;
    }
  }

  &.divideNone {
    input {
      border: none !important;
    }
  }

  &.redBorder {
    fieldset {
      border-color: var(--color-orange1) !important;
    }
  }

  >label {
    right: 30px !important;
    left: unset !important;
    padding: 0 10px !important;
    background-color: var(--color-white) !important;
    transform-origin: top right !important;
    color: var(--txBody-color);
  }

  input {
    background-color: var(--color-white) !important;
    padding: 3px 8px !important;
    margin: 12px !important;
    border-left: 1px solid var(--color-white3) !important;
    color: var(--txBody-color);
  }

  .iconInput {
    @extend %flexCenter;
    direction: rtl !important;
    gap: 7px !important;

    &.cityCode {
      cursor: pointer;
      transition: 0.3s ease !important;
      opacity: 0.8 !important;

      &:hover {
        opacity: 1;
      }
    }

    p {
      direction: initial !important;
      color: var(--txBody-color);
    }
  }
}

.textareaStyle {
  margin: 32px 0;

  .textRight {
    text-align: right !important;

    .oninput {
      font-size: 14px;
      color: var(--color-txt4);
      font-weight: 400;
      background: var(--color-white);
      padding: 0px 8px;
      text-align: right;
      position: absolute;
      transition: 0.4s ease;
      margin-top: -13px;
      display: inline;
      margin-right: 18px;
      z-index: 1;
    }
  }

  .inputGroup {
    margin: 0 !important;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid var(--color-white14);
    transition: 0.4s ease;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    text-align: right !important;

    textarea {
      font-size: 13px;
      border: none;
      position: relative;
      flex: 1 1 auto;
      width: 100%;
      margin-bottom: 0;
      min-height: 40px;
      background-color: var(--color-white);
    }
  }
}

.customInput {
  width: 100%;
  flex: 0 1 auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-brInput);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 14px;
  gap: 10px;
  position: relative;
  direction: rtl;

  &.ltr {
    .startEle {
      border: none;
      padding: 0;
      padding-right: 10px;
      border-right: 1px solid var(--color-white14);
    }

    .endEle {
      border: none;
      padding: 0;
      padding-left: 10px;
      border-left: 1px solid var(--color-white14);
    }
  }

  .labelInput {
    position: absolute;
    top: -12px;
    right: 18px;
    background-color: var(--color-white);
    padding: 4px;
    z-index: 5;
    font-size: 11px;
    color: var(--txBody-color)
  }

  .startEle {
    @extend %flexCenter;
    padding-left: 10px;
    border-left: 1px solid var(--color-white14);
    flex: 0 0 auto;
  }

  .endEle {
    @extend %flexCenter;
    padding-right: 10px;
    border-right: 1px solid var(--color-white14);
    flex: 0 0 auto;
  }

  .inputNumeric {
    width: 100%;
    flex: 0 1 auto;
    font-size: 16px;

    border: none;
    background: transparent;
  }

}

.allTable {
  direction: rtl;
  overflow-x: auto;
  width: 100%;

  .headerTabel,
  .bodyTabel {
    min-width: 750px;
    font-size: 14px;
    background: var(--color-white16);
    border-radius: 10px;
    padding: 16px 30px;
    direction: ltr;
    width: 100%;

    .tRow {
      >div {
        text-align: end;
        padding: 0 2px;
      }

    }
  }

  .bodyTabel {
    background: var(--color-white);
    padding-top: 4px;
    height: 290px;
    overflow-y: auto !important;
    color: var(--color-txt4);

    .tRow {
      padding: 20px 0;
      border-bottom: 1px solid var(--color-white3);
    }
  }

  .tRow {
    @extend %flexCenter;
    justify-content: flex-end;
    text-align: center;

  }

}

.finalCheckBtn {
  width: 100%;
  border: none;
  margin-top: 15px;
  color: var(--color-textOnlyWhite);
  transition: 0.3s ease;
  border-radius: 13px;
  padding: 20px;
  background: var(--color-blue);
  box-shadow: 0px 0px 52px -12px var(--color-blue5);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    box-shadow: none;
    background: var(--color-green) !important;
  }

  .twoText {
    @extend %flexCenter;
    justify-content: space-between;
    direction: rtl;
    text-align: center;

    h4 {
      width: 50%;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      width: 50%;
      font-size: 11px;
    }

  }

}

.imgArrowCircle {
  width: 22px;
  height: 22px;
  @extend%flexCenter;
  background-color: var(--color-white);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.inputTextField {
  @extend %flexCenter;
  justify-content: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-br2) !important;
  width: 100%;
  gap: 10px;
  direction: rtl;

  .iconImg {
    opacity: 0.5;
    margin-right: 33px;
    margin-bottom: 10px;
    width: 24px;
    height: 24px;
  }

  >div {
    @extend %flexStart;
    flex-direction: column;

    label {
      opacity: 0.7;
      margin-bottom: 0;
      font-size: 12px;
      text-align: right;
    }

    input {
      font-size: 15px;
      border: none;
      border-radius: 0;
      padding: 0px 0 5px 5px;
      font-weight: 600;
      border: none;
      background: transparent;

      direction: rtl;

      &::placeholder {
        font-size: 15px;

        opacity: 0.7;
      }
    }
  }
}

.SrarchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 15px;
  font-size: 12px;

  .searchModel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px 10px;
    background: var(--color-white3);
    width: 220px;
    border-radius: 8px;


    img {
      width: 17px;
    }

    input {
      border: none;
      background: transparent;
      direction: rtl;
      width: 100%;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
}

.customSelectBox {
  .select {
    >fieldset {
      border-color: var(--color-white3) !important;
      border-radius: 10px !important;
      -webkit-border-radius: 10px !important;
      -moz-border-radius: 10px !important;
      -ms-border-radius: 10px !important;
      -o-border-radius: 10px !important;
    }

    // >div {
    // padding-top: 10px !important;
    // padding-bottom: 10px !important;
    // }
  }

  .error {
    >fieldset {
      border-color: var(--color-orange1) !important;
      border-radius: 10px !important;
      -webkit-border-radius: 10px !important;
      -moz-border-radius: 10px !important;
      -ms-border-radius: 10px !important;
      -o-border-radius: 10px !important;
    }
  }
}

.changeNavStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 58px;

  .item {
    background: var(--color-bg1);
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;

    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 2px;
      right: 10%;
      width: 80%;
      height: 1px;
      background-color: var(--color-NO);
    }

    &.active {
      color: var(--color-title);

      &::before {
        content: '';
        position: absolute;
        bottom: 2px;
        right: 10%;
        width: 80%;
        height: 1px;
        background-color: var(--color-title);
      }
    }
  }

  .itemWithNew {
    position: relative;

    .txtNew {
      position: absolute;
      left: 0;
      top: -17px;
      color: var(--color-textOnlyWhite);
      font-size: 12px;
      font-weight: 400;
      padding: 3px 6px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background: var(--color-red1);
    }
  }
}

.qrCodeGeneratedID {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.historyBoxAll {
  width: 100%;
  @extend %flexStart;
  flex-direction: column;
  background: var(--color-white);
  border-radius: 10px;
  padding: 25px;
  direction: rtl;
  box-shadow: 0px 0px 52px 0px var(--color-blue10);

  &.allHistoryStyle {
    padding: 0;
    box-shadow: none;
    background: unset;

    .tb_opratorBox {
      display: none !important;
    }
  }


  .tb_Receive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    direction: rtl;
    width: 100%;

    .head .tRow {
      >div>h5 {
        font-size: 12px;
        font-weight: 400;

        background-color: var(--color-white3);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 3px 5px;
      }

      >div:nth-child(2) {
        padding-right: 30px;
      }
    }

    .body {
      min-height: 300px;
      height: auto;
      max-height: 800px;
      overflow: auto;

      .tRow {
        padding: 10px 0;
        border-bottom: 1px solid var(--color-white14);
      }

    }

    .head,
    .body {
      width: 100%;

      .tRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        direction: rtl;
        width: 100%;
        gap: 12px;


        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          text-align: center;
          font-size: 13px;

          font-weight: 400;

          b {
            color: gray;
          }
        }

        >div:nth-child(1) {
          flex: 0 0 85px;
        }

        >div:nth-child(2) {
          justify-content: flex-start;
          flex: 0 0 145px;
        }

        >div:nth-child(3) {
          flex: 0 0 120px;
          ;
        }

        >div:nth-child(4) {
          flex: 0 0 160px;
        }

        >div:nth-child(5) {
          flex: 0 0 90px;
        }

        >div:nth-child(6) {
          flex: 0 0 120px;

        }




      }
    }

  }

  .tb_Receive {
    max-height: 400px;
    min-height: 300px;

    table {
      // min-width: 900px;

      // tbody {

      //   tr {
      //     cursor: pointer;
      //   }
      // }

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;

          &:nth-child(1) {
            // min-width: 120px;
            padding-right: 0;
          }

          // &:nth-child(2) {
          //   min-width: 90px;
          // }

          &:nth-child(3) {
            justify-content: flex-start;
            // min-width: 100px;
          }

          // &:nth-child(4) {
          //   min-width: 150px;
          // }

          // &:nth-child(5) {
          //   min-width: 75px;
          // }

          // &:nth-child(6) {
          //   min-width: 120px;
          // }
          &:last-child {
            min-width: 80px;
            text-align: right;
          }

          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-align: center;
            font-size: 13px;

            font-weight: 400;

            b {
              color: gray;
            }

            img {
              width: 24px;
              height: 24px;
            }

            h4 {
              font-size: 12px;
              font-weight: 400;

            }

            p {
              font-size: 12px;
              color: var(--color-txt4);
              font-weight: 400;
            }
          }

          .idLink {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-align: center;
            font-size: 13px;

            font-weight: 400;
            direction: rtl;

            b {
              color: gray;
            }

            gap: 4px;

            img {
              width: 14px;
              height: 14px;
              cursor: pointer;
            }

            p {
              font-size: 13px;
              font-weight: 400;

            }
          }

          .status {
            color: var(--color-textOnlyWhite);
            background-color: var(--color-green6);
            padding: 1px 4px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            text-align: center;
            width: 49px;
            display: inline-block;

            h5 {
              font-size: 13px;
              font-weight: 400;

            }

            &.confirm {
              background-color: var(--color-green6);
            }

            &.process {
              background-color: var(--color-orange1);
            }

            &.reject {
              background-color: var(--color-red1);
            }

            &.pending {
              background-color: var(--color-yellow);
            }

            &.waiting {
              background-color: var(--color-yellow);
            }

            &.checking {
              background-color: var(--color-yellow);
            }

          }

          .time {
            font-family: YekanBakh !important;
          }
        }

      }
    }
  }

  .tb_Send {
    max-height: 400px;
    min-height: 300px;

    table {
      // min-width: 890px;

      // tbody {

      //   tr {
      //     cursor: pointer;
      //   }
      // }

      tr {

        th,
        td {
          text-align: center;
          text-wrap: nowrap;
          white-space: nowrap;


          &:nth-child(1) {
            // min-width: 105px;
            padding-right: 0;
          }


          &:nth-child(4) {
            // min-width: 130px;
            direction: ltr;

            b {
              font-weight: 400;
            }
          }

          &:last-child {
            min-width: 80px;
            text-align: right;
          }

          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-align: center;
            font-size: 13px;
            font-weight: 400;

            &.right {
              justify-content: flex-start;
            }

            b {
              color: gray;
            }

            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            h4 {
              font-size: 12px;
              font-weight: 400;

            }

            p {
              font-size: 12px;
              color: var(--color-txt4);
              font-weight: 400;
            }
          }

          .idLink {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-align: center;
            font-size: 13px;

            font-weight: 400;
            direction: rtl;

            b {
              color: gray;
            }

            gap: 4px;

            img {
              width: 14px;
              height: 14px;
              cursor: pointer;
            }

            p {
              font-size: 13px;
              font-weight: 400;

            }
          }

          .status {
            color: var(--color-white);
            background-color: var(--color-green6);
            padding: 1px 4px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            text-align: center;
            width: 49px;
            display: inline-block;

            h5 {
              font-size: 13px;
              font-weight: 400;

            }

            &.confirm {
              background-color: var(--color-green6);
            }

            &.process {
              background-color: var(--color-orange1);
            }

            &.reject {
              background-color: var(--color-red1);
            }

            &.pending {
              background-color: var(--color-yellow);
            }

            &.waiting {
              background-color: var(--color-yellow);
            }

            &.checking {
              background-color: var(--color-yellow);
            }

          }

          .time {
            font-family: YekanBakh !important;
          }
        }

        td {
          &:nth-child(8) {
            text-align: center;
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
              color: var(--color-blue);
            }
          }
        }

        .name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px
        }

      }
    }
  }
}

.txtforceGateway {
  font-weight: 13px;
  color: var(--color-red1);
  font-weight: 400;
  margin-right: 2px;
}

.showCoinSearch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  gap: 15px;

  img {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: var(--txBody-color);
    text-align: right;
    background: none;
  }

}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 15px;
  direction: ltr;
  width: 100%;
}

// .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
// .css-1k0ku3a-MuiPaper-root-MuiDialog-paper,
// .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
//   background: var(--color-white) !important;
// }

.MuiDialog-paper,
.MuiMenu-paper,
.MuiDrawer-paper {
  background: var(--color-white) !important;

  &:has(.notifPopup) {
    background: var(--color-whiteOld) !important;
  }
}

// .css-ypiqx9-MuiDialogContent-root:has(.otherCoinsView) {
//   background: var(--color-white);
// }

%sizeSpan {
  padding: 2px 8px;
  border-radius: 6px;
  font-size: 12px;
}

#confirm,
#complete,
#done {
  background: var(--color-bgDone) !important;
  color: var(--color-textDone) !important;
  @extend %sizeSpan;
}

#cancel {
  background: var(--color-red1) !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

#reject {
  background: var(--color-white1) !important;
  color: var(--color-orange1) !important;
  @extend %sizeSpan;
}

#pending {
  background-color: var(--color-yellow7) !important;
  // color: #000 !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

#referral {
  background-color: var(--color-gray) !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

#answer {
  background-color: var(--color-blue) !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

#close {
  background-color: var(--color-green) !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

#waiting {
  background: var(--color-boxPending) !important;
  color: var(--color-txt4) !important;
  @extend %sizeSpan;
}

#checking {
  background: var(--color-boxPending) !important;
  color: var(--color-txt4) !important;
  @extend %sizeSpan;
}

#weitPay {
  background: var(--color-orange5) !important;
  color: var(--color-yellow1) !important;
  @extend %sizeSpan;
}

#process,
#checking {
  background: var(--color-orange1) !important;
  color: var(--color-textOnlyWhite) !important;
  @extend %sizeSpan;
}

///Pagination Icon Next Back
.MuiPaginationItem-icon {
  color: var(--color-black5) !important;
}

///////////////verFiyStyle 
.character--inactive,
.character--selected {

  font-size: $fontsize1;
}

.containerVerify {
  width: unset !important;
  height: 66px;
}

.characterVerify {
  // background: var(--color-white5);
  background: #dae3ed;
  border: 2px solid #dae3ed;
  border-radius: 10px;
  padding: 7px;
  @extend .character--selected;
}

.character--filled {

  font-size: $fontsize1;
}

/* ////////// Dialog Confirm */

.textTopConfirm {
  color: var(--color-gray15);
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.dialogConfirm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--color-text2);
    margin-bottom: 18px;
    position: relative;
    padding-right: 5px;
    direction: rtl;
    margin-right: 20px;
    width: 100%;
  }

  h3::after {
    position: absolute;
    top: 7px;
    right: -11px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-blue);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .btns {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .btns button {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--color-NO);
    opacity: 0.7;
  }

  .btns .btn_yes {
    background-color: var(--color-blue);
    color: var(--color-textOnlyWhite);
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;

    &:hover {
      border: 1px solid var(--color-blue12);
      opacity: 1;
    }
  }

  .btns .btn_no {
    background-color: var(--color-orange1);
    color: var(--color-textOnlyWhite);
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;

    &:hover {
      border: 1px solid var(--color-orange2);
      opacity: 1;
    }
  }
}

/////////////////////////UserProfilHeader
.userProfileHeader {
  display: flex;
  flex-wrap: wrap;
  padding: 2px 0;
  align-items: center;
  text-align: right;
  cursor: pointer;
  // margin-right: 32px;
  // margin-left: -10px;
  gap: 15px;

  .userText {
    h6 {
      margin: 0;
      font-weight: 400;
      font-size: $fontsize2;
      display: block;
      max-width: 140px;
      text-wrap: wrap;
      overflow: hidden;
      max-height: 42px;
    }

    .textArrow {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    p {
      color: var(--color-gray11);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: right;
    }

  }

  .imgprofile {
    width: 40px;
    height: 40px;
  }

  &.hover {
    position: relative;

    .menuHover {
      position: absolute;
      display: none;
      z-index: 1080;
    }

    &:hover {
      .menuHover {
        display: unset !important;
        left: -15px;
        top: 45px;

        .menuPopup {
          background: var(--color-white);
          box-shadow: 0px 0px 52px 0px var(--color-blue4);
        }
      }
    }
  }

}

.newMenuProfile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 22px 17px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 18px 0px var(--color-black13);
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  direction: rtl;


  h5 {
    color: var(--color-black5);
    font-size: 14px;
    font-weight: 600;
    line-height: 21.7px;
    text-align: right;
    direction: rtl;

  }

  .levelInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-width: 200px;
    direction: rtl;
  }

  .dividerVer {
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, var(--color-NO) 0%, var(--color-divider3) 45%, var(--color-NO) 100%);
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid var(--color-NO);
    border-bottom: 1px solid var(--color-NO);
    width: 100%;

    &.deActiveBtn {
      border: none;
      background: none;
      opacity: 0.5;

      &:hover {
        border: none;
        background: none;
        opacity: 0.5;
      }
    }

    &.itemTheme {
      align-items: center;
      gap: 0;

      >div>label {
        margin: 0;
      }
    }

    &:hover {
      opacity: 1;
      border-top: 1px solid var(--color-hoverNav);
      border-bottom: 1px solid var(--color-hoverNav);
      background: var(--color-hoverNav);
      background: linear-gradient(90deg, var(--color-bghoverNav) 0%, var(--color-NO) 100%);
    }

    >div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      p {
        color: var(--color-black2) !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 18.6px !important;
        text-align: right !important;
      }
    }
  }
}


////////////////////FCM Message Notif
#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}


//////////////////// filter Table

.filterTable {
  text-align: right;
  font-size: 14px;
  margin-bottom: 16px;
  @extend %flexCenter;
  justify-content: flex-start;
  direction: rtl;
  width: 100%;
  gap: 20px;

  p {
    font-size: 14px;
    font-weight: 400;
  }

  .item {
    color: var(--color-gray4);
    padding: 15px 15px;
    @extend%flexCenter ;
    gap: 2px;
    cursor: pointer;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    border-bottom: 2px solid var(--color-NO);
    order: 1;

    &:hover {
      color: var(--color-blue);
      font-weight: 500;
    }

    span {
      background: var(--color-gray4);
      margin-right: 2px;
      width: 13px;
      height: 13px;
      text-align: center;
      font-weight: bold;
      font-size: 10px;
      display: inline-block;
      border-radius: 50%;
      color: var(--color-white);
      margin-top: -5px
    }
  }


  .addTicket {
    padding: 5px 15px;
    @extend%flexCenter ;
    gap: 2px;
    cursor: pointer;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    border-bottom: 2px solid var(--color-NO);
    background: var(--color-green);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-right: auto;
    order: 1;

    &:hover {
      opacity: 0.7;
    }

    p {
      color: var(--color-textOnlyWhite);
    }
  }

  .active {
    background: transparent !important;
    border-radius: 0;
    border-bottom: 2px solid var(--color-title);
    color: var(--color-title) !important;
    font-weight: 500;

    span {
      background: var(--color-title)
    }
  }
}

.divideFilterT {
  @extend %divider;
  box-sizing: content-box;
  overflow: visible;
  width: 100%;
  margin-bottom: 32px;
  margin-top: -17px;
}






//animation
@keyframes w3-alert-red {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--color-orange6);
    -moz-box-shadow: 0 0 0 0 var(--color-orange6);
    box-shadow: 0 0 0 0 var(--color-orange6)
  }

  100% {
    -webkit-box-shadow: 0 0 0 15px var(--color-NO);
    -moz-box-shadow: 0 0 0 15px var(--color-NO);
    box-shadow: 0 0 0 15px var(--color-NO)
  }
}

@keyframes ring {
  0% {
    transform: rotate(0deg)
  }

  5% {
    transform: rotate(0deg)
  }

  15% {
    transform: rotate(0deg)
  }

  25% {
    transform: rotate(20deg)
  }

  35% {
    transform: rotate(-15deg)
  }

  45% {
    transform: rotate(10deg)
  }

  55% {
    transform: rotate(-5deg)
  }

  60% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(0deg)
  }
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}